import styled from "styled-components";
import { device } from "../../style";

export const StakeContainer = styled.div`
  padding: 78.1px 50px 75.7px 72.5px;
  border-radius: 21px;
  box-shadow: 0 0 59.9px 0 rgba(8, 169, 138, 0.22);
  border: solid 1px rgba(38, 166, 119, 0.51);
  background-color: rgba(0, 0, 4, 0.2);
  display: flex;
  gap: 36px;
  margin-bottom: 19px;
  flex-direction: column;
  align-items: center;

  @media ${device.laptopL} {
    flex-direction: row;
  }
`;

export const StakingForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1 0 auto;
  max-width: fit-content;
`;

export const InfoBox = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 34px;

  h2 {
    font-family: Ingra;
    font-size: 48px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: left;
    font-weight: normal;
    color: #fff;

    .hightlight {
      color: #08a98a;
      font-weight: bold;
    }
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 69px;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        #08a98a 0%,
        rgba(0, 0, 0, 0) 100%
      );

      position: absolute;
      left: 0;
      bottom: -11px;
    }
  }

  p {
    font-family: Ingra;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    width: 60%;
  }
`;

export const AuditedBy = styled.section`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
`;

export const Label = styled.span`
  font-family: Ingra;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
`;

export const ImageWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16.8px;
  padding: 0;
  flex-wrap: wrap;

  img {
    object-fit: contain;
  }
`;

export const HelpText = styled.small`
  opacity: 0.5;
  font-family: Ingra;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: left;
  color: #f7f7f8;
  margin: -20px 0;
`;

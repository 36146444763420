import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../style";

export const OnboardingContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const OnboardingStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  background: linear-gradient(137.87deg, #13093e 1.25%, #47126a 72.34%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  height: 100%;

  &::before {
    content: "";
    background-image: url("/bg-logo.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    width: 50%;
    height: 100%;
    overflow-y: hidden;
  }
`;

export const OnboardingModal = styled.div`
  flex: 1;
  width: 100%;
  background-color: #fff;
  height: 100%;
`;

export const OnboardingStatusContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media ${device.tablet} {
    gap: 60px;
  }
`;

export const OnboardingStatusTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const OnboardingStatusesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

import BigNumber from "bignumber.js";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useAccount } from "wagmi";
import { inputStableIcon } from "../../../../assets";
import {
  Button,
  Checkbox,
  CurrencyInput,
  CustomTooltip,
  MBC,
  Typography,
} from "../../../../components";
import { config } from "../../../../config";
import { useAuth } from "../../../../hooks";
import { useAppSelector } from "../../../../redux";
import { formatNumber } from "../../../../utils";
import { ConnectWalletButton } from "../../../ConnectWalletButton";
import { DepositPoolInfo } from "./DepositPoolInfo";
import { useDepositToPoolHandler } from "./useDepositToPoolHandler";

type DepositModalBodyProps = {
  poolLevel: PoolLevel;
  onDepositSuccess: () => void;
  closeModal: () => void;
};

export const DepositModalBody = (props: DepositModalBodyProps) => {
  const { onDepositSuccess, poolLevel, closeModal } = props;

  const isV2 = poolLevel?.pool?.version === "v2";

  const { control, handleSubmit, isPending, inputAmount, formState } =
    useDepositToPoolHandler(poolLevel, onDepositSuccess, closeModal, isV2);
  const usdcBalance = useAppSelector((store) => store.wallet.balances.usdc);
  const poolFee = useAppSelector(
    (store) => store.pool.poolsContractFeeFormatted
  );
  const poolFeeV2 = useAppSelector(
    (store) => store.pool.poolsContractFeeV2Formatted
  );

  const { login } = useAuth();
  const { isConnected, address } = useAccount();
  const isLogged = useAppSelector((store) => store.user.isLogged);
  const { kyc } = useAppSelector((state) => state.user.userData);

  const thisPoolFee = isV2 ? poolFeeV2 : poolFee;

  const totalInvestment = new BigNumber(inputAmount || 0).minus(
    thisPoolFee || 0
  );
  const totalInvestmentToDisplay = totalInvestment.isPositive()
    ? totalInvestment
    : new BigNumber(0);

  const [isInputError, setIsInputError] = useState(false);
  const isButtonDisabled =
    isInputError || totalInvestment.isLessThanOrEqualTo(0);

  const amountToFillHardCapWei = new BigNumber(poolLevel.pool.hardCap)
    .minus(new BigNumber(poolLevel.poolLevelBalances[1].balance))
    .toString();
  const availableWei = new BigNumber(amountToFillHardCapWei)
    .plus(new BigNumber(poolLevel.pool.fee))
    .toString();
  const available = new BigNumber(usdcBalance).gte(new BigNumber(availableWei))
    ? availableWei
    : usdcBalance;

  return (
    <MBC.Form onSubmit={handleSubmit}>
      <DepositPoolInfo poolLevel={poolLevel} />
      <Controller
        control={control}
        name="amount"
        defaultValue=""
        render={({ field }) => (
          <CurrencyInput
            {...field}
            label="USDC"
            logo={inputStableIcon}
            decimals={config.USDC_TOKEN_DECIMALS}
            available={available}
            onErrorChange={setIsInputError}
          />
        )}
      />
      <MBC.InfoBox>
        <MBC.InfoRow as="li">
          Transaction fee
          <span>
            {thisPoolFee} USDC
            <CustomTooltip
              message="Fee in USDC charged on deposited value"
              messageColor="#fff"
            />
          </span>
        </MBC.InfoRow>
        <MBC.InfoRow as="li">
          The total amount of the loan
          <span>
            {formatNumber(totalInvestmentToDisplay.toString(), 6)} USDC
            <CustomTooltip
              message="The amount of USDC minus the fee that will be credited to your pool balance"
              messageColor="#fff"
            />
          </span>
        </MBC.InfoRow>
      </MBC.InfoBox>
      {isConnected ? (
        <MBC.ModalDisclaimer>
          <MBC.AgreeTermsContainer>
            <Controller
              control={control}
              name="acceptTerms"
              rules={{ required: true }}
              render={({ field: { onChange, ref, name } }) => (
                <Checkbox onClick={onChange} name={name} ref={ref} id={name} />
              )}
            />
            <Typography.BodySmall
              fontWeight={600}
              opacity={0.9}
              style={{ fontSize: "10px" }}
            >
              I agree to enter into loan agreement with Soil Ltd under{" "}
              {kyc?.status === "approved" ? (
                <MBC.Link
                  href={config.GENERAL_TERMS}
                  target="_blank"
                  rel="noreferrer"
                >
                  Loan Agreement General Terms{" "}
                </MBC.Link>
              ) : (
                <CustomTooltip
                  inline
                  message="To access this file, please connect your wallet and complete the KYC process."
                  messageColor="#fff"
                >
                  <MBC.DisabledLink>
                    Loan Agreement General Terms{" "}
                  </MBC.DisabledLink>
                </CustomTooltip>
              )}
              and subject to the specific commercial terms set out above.
            </Typography.BodySmall>
          </MBC.AgreeTermsContainer>
          <Typography.BodySmall
            fontWeight={400}
            opacity={0.7}
            style={{ fontSize: "10px" }}
          >
            By checking “LEND” button and connecting your wallet you are lending
            stablecoins (USDC) to Soil Ltd under to the conditions of the Loan
            Agreement General Terms and conditions of this loan category
            specified above, such as the APR, loan duration (lockup period) and
            total amount of loan.
          </Typography.BodySmall>
        </MBC.ModalDisclaimer>
      ) : null}
      {isConnected && address ? (
        isLogged ? (
          <Button.Primary
            type="submit"
            bigSize
            disabled={isButtonDisabled || isPending || !formState.isValid}
          >
            Lend
          </Button.Primary>
        ) : (
          <Button.Primary type="button" bigSize onClick={() => login()}>
            Authorize
          </Button.Primary>
        )
      ) : (
        <ConnectWalletButton />
      )}
    </MBC.Form>
  );
};

import { walletConnect } from "@wagmi/connectors";
import { motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import {
  Config,
  Connector,
  useAccount,
  useSignMessage,
  useSwitchChain,
} from "wagmi";
import { ConnectMutateAsync } from "wagmi/query";
import {
  logoMetamaskConnect,
  logoWalletConnect,
  rabbyWalletIcon,
  safeIcon,
  walletDisconnectedLightIcon,
} from "../../assets";
import { config } from "../../config";
import { configWagmi } from "../../context/WagmiProvider";
import { useOnboarding } from "../../features/Onboarding/useOnboarding";
import { useMediaQuery } from "../../hooks";
import { device, theme } from "../../style";
import { Spinner } from "../Spinner/Spinner";
import { Typography } from "../Typography/Typography";
import * as S from "./ConnectWallet.styled";

export type ConnectWalletProps = {
  handleModalClose?: () => void;
  connectCallback?: () => void;
  signMessageCallback?: () => void;
  connectAsync: ConnectMutateAsync<Config, unknown>;
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
  connectors: readonly Connector[];
};

export const ConnectWallet = ({
  handleModalClose,
  connectCallback,
  signMessageCallback,
  connectAsync,
  isPending,
  isError,
  isSuccess,
  connectors,
}: ConnectWalletProps) => {
  const [availableConnectors, setAvailableConnectors] = useState<
    (Connector | null)[]
  >([]);
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(
    null
  );
  const [qrCodeUri, setQrCodeUri] = useState<string | null>(null);

  const { isSuccess: isSuccessSignMessage } = useSignMessage();

  const { switchChainAsync } = useSwitchChain();

  const { isConnected } = useAccount();

  const { activeStepIndex } = useOnboarding();

  const isDesktopView = useMediaQuery(device.tablet);

  const fadeInOutVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  const MobileWalletConnectConnector = walletConnect({
    projectId: config.WALLET_CONNECT_PROJECT_ID,
    showQrModal: true,
    relayUrl: "wss://relay.walletconnect.org",
    customStoragePrefix: "wagmi",
  });

  const listenForWalletConnectUri = async (
    walletConnectConnector: Connector
  ) => {
    const provider = await walletConnectConnector.getProvider();

    // @ts-expect-error dsd
    provider.once("display_uri", (uri) => {
      setQrCodeUri(uri);
    });
  };

  const walletConnectConnector = connectors.find(
    ({ id }) => id === "walletConnect"
  );

  const handleConnect = async (connector: Connector) => {
    setSelectedConnectorId(connector.id);
    try {
      if (connector.id === walletConnectConnector?.id) {
        await listenForWalletConnectUri(connector);
      }
      const isMobileAndWalletConnect =
        connector.id === "walletConnect" && !isDesktopView;
      const connect = await connectAsync({
        connector: isMobileAndWalletConnect
          ? MobileWalletConnectConnector
          : connector,
      });

      if (!configWagmi.chains.map((c) => c.id).includes(connect.chainId)) {
        await switchChainAsync({
          chainId: configWagmi.chains[0].id,
        });
      }
    } catch (error) {
      if (handleModalClose) {
        handleModalClose();
      }
    } finally {
      setSelectedConnectorId(null);
    }
  };

  useEffect(() => {
    if (isSuccessSignMessage && !isPending && handleModalClose) {
      handleModalClose();
    }
    if (isSuccess && connectCallback) {
      connectCallback();
    }
    if (isSuccessSignMessage && signMessageCallback) {
      signMessageCallback();
    }
  }, [isSuccessSignMessage, isSuccess, isPending]);

  useEffect(() => {
    if (isError) setQrCodeUri(null);
  });

  const switchImage = (connector: string) => {
    switch (connector) {
      case "MetaMask":
        return logoMetamaskConnect;
      case "WalletConnect":
        return logoWalletConnect;
      case "Rabby Wallet":
        return rabbyWalletIcon;
      case "Safe":
        return safeIcon;
      default:
        return walletDisconnectedLightIcon;
    }
  };

  const switchAlt = (connector: string) => {
    switch (connector) {
      case "MetaMask":
        return "metamask icon";
      case "WalletConnect":
        return "walletConnect icon";
      case "Rabby Wallet":
        return "rabby wallet icon";
      default:
        return "icon";
    }
  };

  useEffect(() => {
    const checkConnectors = async () => {
      const readyConnectors = await Promise.all(
        connectors.map(async (connector) => {
          if (connector.id === "safe") {
            // Sprawdza, czy aplikacja działa w trybie Safe (np. Gnosis Safe App)
            return typeof window !== "undefined" && window?.parent !== window
              ? connector
              : null;
          }
          return connector;
        })
      );
      setAvailableConnectors(readyConnectors.filter(Boolean));
    };

    checkConnectors();
  }, [connectors, isConnected]);

  const isMetamaskOrWalletConnect = (connectorToCheck: Connector) =>
    connectorToCheck.name === "MetaMask" ||
    connectorToCheck.name === "WalletConnect";

  return (
    <S.ConnectWalletWrapper
      key={activeStepIndex}
      initial={activeStepIndex === 0 ? "visible" : "hidden"}
      animate="visible"
      exit={activeStepIndex === 0 ? "visible" : "exit"}
      variants={fadeInOutVariants}
      transition={{ duration: 0.3 }}
    >
      {qrCodeUri ? (
        <>
          <Typography.IngraLabel
            style={{
              color: theme.colors.black,
              lineHeight: "21.76px",
            }}
            fontWeight={300}
          >
            Scan below qr code with your mobile wallet app
          </Typography.IngraLabel>
          {isDesktopView ? (
            <motion.div
              key={qrCodeUri ? "showQr" : "hideQr"}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={fadeInOutVariants}
              transition={{ duration: 0.4 }}
            >
              <QRCodeSVG
                width={320}
                height={320}
                fgColor="rgba(139, 87, 250"
                value={qrCodeUri}
                title="Soil"
              />
            </motion.div>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        availableConnectors &&
        availableConnectors.map((connector) =>
          (connector?.id === "injected" && connector?.name === "MetaMask") ||
          !connector ? null : (
            <motion.div
              style={{ width: "100%" }}
              key={`button-${connector.id}`}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={fadeInOutVariants}
              transition={{ duration: 0.3 }}
            >
              <S.CustomButton
                bigSize
                disabled={!connector?.id || isPending}
                key={connector?.id}
                onClick={() => handleConnect(connector)}
                loading={isPending && connector?.id === selectedConnectorId}
              >
                <S.ButtonContent>
                  <img
                    width={isMetamaskOrWalletConnect(connector) ? 230 : 30}
                    height={isMetamaskOrWalletConnect(connector) ? 37 : 30}
                    src={switchImage(connector?.name) || connector?.icon}
                    alt={switchAlt(connector?.name)}
                  />
                  <span>
                    {!isMetamaskOrWalletConnect(connector) && connector?.name}
                  </span>
                </S.ButtonContent>
              </S.CustomButton>
            </motion.div>
          )
        )
      )}
    </S.ConnectWalletWrapper>
  );
};

import { ethers } from "ethers";
import { config } from "../config";
import { ContractUserRole } from "../constants";
import { ProtocolSettingsContract } from "./contracts";

const checkRole = async (
  user: string,
  protocolSettingsAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const DEFAULT_ADMIN_ROLE = await ProtocolSettingsContract(
    protocolSettingsAddress,
    provider
  ).DEFAULT_ADMIN_ROLE();

  const [isSuperAdmin, isAdmin] = await Promise.all([
    ProtocolSettingsContract(protocolSettingsAddress, provider).hasRole(
      DEFAULT_ADMIN_ROLE,
      user
    ),
    ProtocolSettingsContract(protocolSettingsAddress, provider).isAdmin(user),
  ]);

  if (isSuperAdmin) {
    return ContractUserRole.SUPER_ADMIN;
  }

  if (isAdmin) {
    return ContractUserRole.ADMIN;
  }

  return null;
};

const changeAdminRole = async (
  user: string,
  isAdmin: boolean,
  signer: ethers.providers.JsonRpcSigner,
  protocolSettingsAddress: string
) => {
  const ADMIN_ROLE = await ProtocolSettingsContract(
    protocolSettingsAddress,
    signer
  ).ADMIN_ROLE();

  let changeRolePromise;

  if (isAdmin) {
    changeRolePromise = ProtocolSettingsContract(
      protocolSettingsAddress,
      signer
    )
      .connect(signer)
      .grantRole(ADMIN_ROLE, user);
  } else {
    changeRolePromise = ProtocolSettingsContract(
      protocolSettingsAddress,
      signer
    )
      .connect(signer)
      .revokeRole(ADMIN_ROLE, user);
  }

  const { wait } = await changeRolePromise;

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

export const protocolSettingsService = { checkRole, changeAdminRole };

import React, { useRef, HTMLAttributes, useEffect } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";
import { useDetectOutsideClick, useMediaQuery } from "../../hooks";
import * as S from "./Modal.styled";
import { device, theme } from "../../style";
import { Typography } from "../Typography/Typography";
import { useOnboarding } from "../../features/Onboarding/useOnboarding";
import { useAppSelector } from "../../redux";

type Title = [string, string];

type Step = {
  title: [string, string];
  description: string;
};

export type ModalProps = {
  title: Title;
  description?: string | string[];
  isOpen: boolean;
  withOutsideClick?: boolean;
  onClose: () => void;
  skipHandler?: () => void;
  withoutCloseIcon?: boolean;
  withBackdropBlur?: boolean;
  disableCloseBtn?: boolean;
  icon?: string;
  message?: boolean;
  fullScreen?: boolean;
  titleVisible?: boolean;
  steps?: {
    summaryComponent?: {
      visible: boolean;
      node: React.ReactNode;
    };
    stepsData: {
      activeStepIndex: number;
      stepsArray: Step[];
    };
  };
} & Omit<HTMLAttributes<HTMLDivElement>, "title">;

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal");
document.body.appendChild(modalRoot);

export const Modal = ({
  children,
  isOpen,
  onClose,
  skipHandler,
  title,
  description,
  icon,
  message,
  withOutsideClick,
  titleVisible,
  disableCloseBtn,
  withoutCloseIcon,
  withBackdropBlur,
  fullScreen,
  steps,
  ...rest
}: ModalProps) => {
  const { summaryComponent, stepsData } = { ...steps };
  const { stepsArray } = { ...stepsData };
  const { activeStepIndex } = useOnboarding();
  const modalRef = useRef<HTMLDivElement>(null);
  let el = document.getElementById("modal");
  const isDesktop = useMediaQuery(device.laptop);
  const isKycStarted = useAppSelector(
    (state) => state.user.userData.kyc?.status === "started"
  );

  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "modal");
    document.body.appendChild(el);
  }

  const handleOutsideClick = () => {
    if (isOpen && onClose && withOutsideClick) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.overflowX = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.overflowX = "hidden";
    }
  }, [isOpen]);

  useDetectOutsideClick(modalRef, handleOutsideClick);

  const fadeInOutVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <>
          <S.Background
            key="background"
            withBackdropBlur={withBackdropBlur}
            className="ignoreOutsideClick"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.35, delay: 0.26, ease: "linear" },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.2, delay: 0, ease: "linear" },
            }}
            data-testid="modal-back"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          {summaryComponent?.visible && (
            <S.AdditionalStepsModal
              key="additionalModal"
              initial={!isDesktop ? { y: "100%" } : { x: "100%" }}
              animate={
                !isDesktop
                  ? { y: 0, transition: { duration: 0.25, ease: "linear" } }
                  : { x: 0, transition: { duration: 0.25, ease: "linear" } }
              }
              exit={
                !isDesktop
                  ? {
                      y: "100%",
                      transition: { duration: 0.25, ease: "linear" },
                    }
                  : {
                      x: "200%",
                      transition: { duration: 0.35, ease: "linear" },
                    }
              }
            >
              {summaryComponent.node}
            </S.AdditionalStepsModal>
          )}
          {stepsArray ? (
            <S.Modal
              initial={!isDesktop ? { y: "100%" } : { x: "100%" }}
              animate={
                !isDesktop
                  ? { y: 0, transition: { duration: 0.25, ease: "linear" } }
                  : { x: 0, transition: { duration: 0.25, ease: "linear" } }
              }
              exit={
                !isDesktop
                  ? {
                      y: "100%",
                      transition: {
                        duration: 0.25,
                        delay: 0.1,
                        ease: "linear",
                      },
                    }
                  : {
                      x: "100%",
                      transition: {
                        duration: 0.25,
                        delay: activeStepIndex === 2 ? 0.25 : 0.1,
                        ease: "linear",
                      },
                    }
              }
              ref={modalRef}
              message={message}
              isOnboarding={stepsArray.length > 0}
            >
              {!withoutCloseIcon && (
                <S.CloseBtn
                  disabled={disableCloseBtn}
                  onClick={activeStepIndex === 2 ? skipHandler : onClose}
                />
              )}
              <S.ModalContent>
                <S.ModalHeader fullScreen={fullScreen}>
                  {titleVisible && (
                    <S.ContentWrapper>
                      <S.Title fullScreen={fullScreen}>
                        {icon ? <img src={icon} alt="boost" /> : null}
                        <S.ModalTitleText
                          key={activeStepIndex}
                          initial={activeStepIndex === 0 ? "visible" : "hidden"}
                          animate="visible"
                          exit={activeStepIndex === 0 ? "visible" : "exit"}
                          variants={fadeInOutVariants}
                          transition={{ duration: 0.3 }}
                        >
                          <span>{stepsArray[activeStepIndex]?.title[0]}</span>
                          {stepsArray[activeStepIndex]?.title[1]}
                        </S.ModalTitleText>
                      </S.Title>
                      {description && (
                        <S.DescriptionWrapper
                          key={activeStepIndex}
                          initial={activeStepIndex === 0 ? "visible" : "hidden"}
                          animate="visible"
                          exit={activeStepIndex === 0 ? "visible" : "exit"}
                          variants={fadeInOutVariants}
                          transition={{ duration: 0.3 }}
                        >
                          <Typography.IngraLabel
                            style={{
                              color: theme.colors.black,
                              lineHeight: "21.76px",
                            }}
                            fontWeight={300}
                          >
                            {stepsArray[activeStepIndex].description}
                          </Typography.IngraLabel>
                        </S.DescriptionWrapper>
                      )}
                    </S.ContentWrapper>
                  )}
                  {activeStepIndex === stepsArray.length - 1 &&
                    !isKycStarted &&
                    skipHandler && (
                      <S.SkipButton onClick={skipHandler}>Skip</S.SkipButton>
                    )}
                </S.ModalHeader>
                {!isKycStarted && children}
              </S.ModalContent>
            </S.Modal>
          ) : (
            <S.Modal
              initial={!isDesktop ? { y: "100%" } : { x: "100%" }}
              animate={
                !isDesktop
                  ? { y: 0, transition: { duration: 0.25, ease: "linear" } }
                  : { x: 0, transition: { duration: 0.25, ease: "linear" } }
              }
              exit={
                !isDesktop
                  ? {
                      y: "100%",
                      transition: {
                        duration: 0.25,
                        delay: 0.1,
                        ease: "linear",
                      },
                    }
                  : {
                      x: "100%",
                      transition: {
                        duration: 0.25,
                        delay: 0.1,
                        ease: "linear",
                      },
                    }
              }
              ref={modalRef}
              message={message}
            >
              <S.ModalContent>
                <S.ModalHeader fullScreen={fullScreen}>
                  <S.ContentWrapper>
                    <S.Title fullScreen={fullScreen}>
                      {icon ? <img src={icon} alt="boost" /> : null}
                      <S.ModalTitleText>
                        <span>{title[0]}</span>
                        {title[1]}
                      </S.ModalTitleText>
                    </S.Title>
                    {description && typeof description === "string" ? (
                      <Typography.IngraLabel
                        style={{
                          color: theme.colors.black,
                          lineHeight: "21.76px",
                        }}
                        fontWeight={300}
                      >
                        {description}
                      </Typography.IngraLabel>
                    ) : description && Array.isArray(description) ? (
                      description.map((descItem) => (
                        <Typography.IngraLabel
                          style={{
                            color: theme.colors.black,
                            lineHeight: "21.76px",
                          }}
                          fontWeight={300}
                        >
                          {descItem}
                        </Typography.IngraLabel>
                      ))
                    ) : null}
                  </S.ContentWrapper>
                  {!withoutCloseIcon && <S.CloseBtn onClick={onClose} />}
                </S.ModalHeader>
                {children}
              </S.ModalContent>
            </S.Modal>
          )}
        </>
      )}
    </AnimatePresence>,
    el
  );
};

import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CriticalNotification, Disclaimer, Spinner } from "../../components";
import { useScrollToTop } from "../../hooks";
import { useAppSelector } from "../../redux";
import { StyledToastContainer } from "../../toasts/ToastContainer.styled";
import { routes } from "../../utils/routes";
import * as S from "./Layout.styled";
import { Header, Nav, TermsConditions } from "./components";
import ConnectWalletAssets from "./components/ConnectWalletAssets/ConnectWalletAssets";

type LayoutProps = {
  children: JSX.Element;
};

export const Layout = ({ children }: LayoutProps) => {
  const { pathname, search } = useLocation();
  // const { chainId } = useAccount();
  const chainId = useAppSelector((state) => state.ui.chainId);
  const isPathForDisclaimer =
    pathname === "/dashboard" ||
    pathname === "/grow" ||
    pathname === "/earn" ||
    pathname === "/swap" ||
    pathname.includes("/project-details");

  const isVisibleBgLogo = pathname !== "/earn";

  const isCookiesPage = window.location.href.includes("/enable-cookies");
  useScrollToTop("contentToScroll");

  const isEarnPage = Object.values(routes.userPanel.earn).includes(pathname);
  const isDashboardPage = pathname === routes.userPanel.dashboard;

  const { lockReason, isActive: isUserActive } = useAppSelector(
    (state) => state.user.userData
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    const refCode = params.get("ref");
    if (refCode) localStorage.setItem("refCode", refCode);
  }, [pathname, search]);

  return isCookiesPage ? (
    <S.Wrapper chainId={chainId}>
      <S.RightSide id="contentToScroll">
        <Suspense fallback={<Spinner />}>
          <S.Content>{children}</S.Content>
        </Suspense>
      </S.RightSide>
    </S.Wrapper>
  ) : (
    <S.Wrapper chainId={chainId}>
      <S.NavContainer>
        <Nav />
      </S.NavContainer>
      <S.RightSide
        id="contentToScroll"
        style={{
          overflowY: isEarnPage || isDashboardPage ? "hidden" : "auto",
          overflowX: "hidden",
        }}
        visibleBGLogo={isVisibleBgLogo}
      >
        <Header />
        {!isUserActive && (
          <CriticalNotification
            title="Account suspended"
            description={lockReason}
          />
        )}
        <Suspense fallback={<Spinner />}>
          <S.Content>{children}</S.Content>
        </Suspense>
      </S.RightSide>
      <StyledToastContainer
        className="ignoreOutsideClick"
        draggablePercent={60}
        // newestOnTop
        closeButton={false}
        hideProgressBar
        icon
        position="bottom-right"
        autoClose={7000}
      />
      {/* {!isDesktopView && <MobileNav />} */}
      <ConnectWalletAssets />
      {isPathForDisclaimer ? <Disclaimer /> : null}
      <TermsConditions />
    </S.Wrapper>
  );
};

import React from "react";
import { Modal } from "../../components";
import { ChangeUserRoleForm } from "./ChangeUserRoleForm/ChangeUserRoleForm";

type ChangeUserRoleModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  userWalletAddress: string;
  onSuccess?: () => void;
};

export const ChangeUserRoleModal = (props: ChangeUserRoleModalProps) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal title={["Change", "role"]} isOpen={isOpen} onClose={rest.closeModal}>
      <ChangeUserRoleForm {...rest} />
    </Modal>
  );
};

import * as S from "./StatusLabel.styled";

type StatusLabelProps = {
  success?: boolean;
  pending?: boolean;
  successLabel?: string;
  errorLabel?: string;
  pendingLabel?: string;
  started?: boolean;
  startedLabel?: string;
  custom?: boolean;
  customLabel?: string;
};

export const StatusLabel = (props: StatusLabelProps) => {
  const {
    success,
    pending,
    pendingLabel,
    successLabel,
    errorLabel,
    started,
    startedLabel,
    custom,
    customLabel,
  } = props;

  return (
    <S.StatusBox>
      <S.Status success={Boolean(success) || Boolean(custom)} pending={pending}>
        {custom
          ? customLabel
          : success
          ? successLabel
          : pending
          ? pendingLabel
          : started
          ? startedLabel
          : errorLabel}
      </S.Status>
    </S.StatusBox>
  );
};

import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { Button, Modal, Typography, Checkbox } from "../../../../components";
import { useDebouncedEffect, useModal } from "../../../../hooks";
import { config } from "../../../../config";
import { Container, Text, Link } from "./TermsConditions.styled";

export const TermsConditions = () => {
  const [cookies, setCookie] = useCookies(["termsAndConditions-2024_01_11"]);
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal();
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const now = new Date();

  const onHandleSubmit = () => {
    setCookie("termsAndConditions-2024_01_11", true, {
      path: "/",
      expires: new Date(now.setMonth(now.getMonth() + 12)),
    });
    handleModalClose();
  };

  useDebouncedEffect(
    () => {
      if (!cookies?.["termsAndConditions-2024_01_11"]) handleModalOpen();
    },
    [cookies?.["termsAndConditions-2024_01_11"]],
    1500
  );

  const ComponentsView = useMemo(() => {
    switch (config.NODE_ENV) {
      case "prod":
        return (
          <Container onSubmit={handleSubmit(onHandleSubmit)}>
            <Typography.Body>
              Hi! We are pleased to welcome you to Soil. Before entering, please
              read the documents linked below.
            </Typography.Body>
            <Typography.Body>
              Currently, the application only supports Vesting and Grow
              functionality.
            </Typography.Body>
            <Typography.Body>
              Further functionalities such as Earn and Swap will be enabled
              soon.
            </Typography.Body>
            <div
              className="grid gap-1"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div className="grid grid-flow-col">
                <Controller
                  control={control}
                  name="prodCheckbox1"
                  rules={{ required: true }}
                  render={({ field: { onChange, ref, name } }) => (
                    <Checkbox
                      onClick={onChange}
                      ref={ref}
                      className="mr-2"
                      name={name}
                      id={name}
                    />
                  )}
                />

                <Text>
                  I accept{" "}
                  <Link
                    href={config.TERMS_SERVICE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </Link>
                  , including the Loan Agreement General Terms , and the{" "}
                  <Link
                    href={config.PRIVACY_POLICY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </div>

              <div className="grid grid-flow-col justify-start items-center	">
                <Controller
                  control={control}
                  name="prodCheckbox2"
                  rules={{ required: true }}
                  render={({ field: { onChange, ref, name } }) => (
                    <Checkbox
                      onClick={onChange}
                      ref={ref}
                      className="mr-2"
                      name={name}
                      id={name}
                    />
                  )}
                />
                <Text>I am 18 years old or older.</Text>
              </div>
            </div>

            <Button.Primary type="submit" disabled={!formState.isValid}>
              Save
            </Button.Primary>
          </Container>
        );

      default:
        return (
          <Container onSubmit={handleSubmit(onHandleSubmit)}>
            <Typography.Body>
              Hi! We are proud to welcome you on the Soil website. Our team is
              very busy working on implementation of all planned features of our
              website and the Soil Platform. We decided to make the current beta
              version of the website available to collect your feedback.
            </Typography.Body>
            <Typography.Body>
              PLEASE NOTICE THAT MOST OF THE FEATURES OF THIS WEBSITE AND/OR THE
              SOIL PLATFORM ARE INCOMPLETE AND BY USING THEM YOU MAY NOT HAVE
              ACCESS TO ALL OF THE SERVICES. YOU MAY BE ALSO EXPOSED TO AN
              ELEVATED CYBERSECURITY RISKS.
            </Typography.Body>
            <div className="grid grid-flow-col	">
              <Controller
                control={control}
                name="devCheckbox"
                rules={{ required: true }}
                render={({ field: { onChange, ref, name } }) => (
                  <Checkbox
                    onClick={onChange}
                    ref={ref}
                    className="mr-2"
                    name={name}
                    id={name}
                  />
                )}
              />
              <Text>
                I am 18 years old and I accept the{" "}
                <Link
                  href={config.TERMS_SERVICE}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  href={config.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </Link>{" "}
                and want to use the Soil website and the Soil Platform subject
                to the risks and limitations connected with its incomplete
                status (beta version).
              </Text>
            </div>

            <Button.Primary type="submit" disabled={!formState.isValid}>
              Save
            </Button.Primary>
          </Container>
        );
    }
  }, [config.NODE_ENV, formState, isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      title={
        config.NODE_ENV === "dev"
          ? ["THIS WEBSITE", "IS IN BETA"]
          : ["Welcome", "to Soil"]
      }
      withoutCloseIcon
      withBackdropBlur
    >
      {ComponentsView}
    </Modal>
  );
};

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "../../hooks";
import { device } from "../../style";
import { applyRouteParam } from "../../utils";
import { routes } from "../../utils/routes";
import * as S from "./Pool.styled";
import { PoolActions } from "./PoolActions/PoolActions";
import { PoolData } from "./PoolData";
import { PoolDeposit } from "./PoolDeposit";
import { PoolDetails } from "./PoolDetails/PoolDetails";
import { PoolProgress } from "./PoolProgress";
import { PoolTitle } from "./PoolTitle";

type PoolProps = {
  poolLevel: PoolLevel;
  onActionSuccess?: () => void;
  withBackground?: boolean;
  isDetailsView?: boolean;
  allLevels: PoolLevel[];
};

const SectionStyled = styled.section<{ isDetailsView?: boolean }>`
  position: relative;
  @media ${device.laptop} {
    position: ${({ isDetailsView }) => (isDetailsView ? "sticky" : "relative")};
    top: ${({ isDetailsView }) => (isDetailsView ? "32px" : "unset")};
  }
`;

export const Pool = (props: PoolProps) => {
  const {
    poolLevel,
    onActionSuccess,
    withBackground,
    isDetailsView,
    allLevels,
  } = props;
  const { id } = poolLevel;
  const isModalView = useMediaQuery(device.tablet);

  const navigate = useNavigate();

  const onDetailsClick = () => {
    if (!isDetailsView)
      navigate(applyRouteParam(routes.userPanel.projectDetails, String(id)));
  };

  const userNotInWhitelist =
    poolLevel?.isWhitelisted && !poolLevel?.isUserOnTheWhitelist;

  const isV2 = poolLevel.pool.contract?.version === "v2";
  return (
    <SectionStyled isDetailsView={isDetailsView}>
      <S.Header>
        <PoolTitle poolLevel={poolLevel} />
        {isModalView ? (
          <S.ActionsWrapper>
            <PoolDeposit
              poolLevel={poolLevel}
              onDepositSuccess={onActionSuccess}
            />
            <PoolActions
              allLevels={allLevels}
              poolLevel={poolLevel}
              onActionSuccess={onActionSuccess}
            />
          </S.ActionsWrapper>
        ) : null}
      </S.Header>
      <S.Wrapper
        withBackground={withBackground}
        onClick={isV2 ? undefined : onDetailsClick}
      >
        <S.PoolDetailsContainer>
          {!isV2 && (
            <PoolDetails poolLevel={poolLevel} isDetailsView={isDetailsView} />
          )}
          <PoolData
            poolLevel={poolLevel}
            onActionSuccess={onActionSuccess}
            isDetailsView={isDetailsView}
            allLevels={allLevels}
          />
          <S.PoolProgressWrapper isDetailsView={isDetailsView}>
            <PoolProgress
              endOfDeposits={poolLevel.endOfDeposits}
              // softCap={poolLevel.pool.softCap}
              hardCap={poolLevel.pool.hardCap}
              endTime={poolLevel.pool.end}
              balance={
                poolLevel.poolLevelBalances.find((el) => el.token === "USDC")
                  ?.balance || "0"
              }
              isWhitelistedAndUserNotInWhitelist={userNotInWhitelist}
              // isV2Pool={isV2}
            />
          </S.PoolProgressWrapper>
        </S.PoolDetailsContainer>
      </S.Wrapper>
    </SectionStyled>
  );
};

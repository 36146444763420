import { useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { useAccount, useConnect } from "wagmi";
import { useModal } from "../../../../../../hooks";
import { metamaskIcon } from "../../../../../../assets";
import { Modal, Button } from "../../../../../../components";

const NoExtension = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const { connector } = useAccount();
  const { isPending } = useConnect();
  const metamask = detectEthereumProvider();

  const handleCloseNoMetamaskModal = () => {
    handleModalClose();
  };

  useEffect(() => {
    if (isPending && !metamask && connector?.name === "MetaMask") {
      handleModalOpen();
    }
  }, [isPending, connector]);

  return (
    <Modal
      title={["Metamask", "needed"]}
      description={[
        "It looks like you don't have MetaMask extension in Your browser.",
        "Let's install the extension, then come back to us and refresh the page.",
      ]}
      isOpen={isModalOpen}
      onClose={handleCloseNoMetamaskModal}
    >
      <img
        src={metamaskIcon}
        height={80}
        width={80}
        style={{ paddingBottom: "38px" }}
        alt="metamask icon"
      />
      <a
        style={{ width: "100%" }}
        href="https://metamask.io/download/"
        rel="noreferrer nofollow"
        target="_blank"
      >
        <Button.Secondary bigSize>Get Metamask</Button.Secondary>
      </a>
    </Modal>
  );
};

export default NoExtension;

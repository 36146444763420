/* eslint-disable */

export function formatNumberSuffix(value: number): string {
  const suffixes = ["", "k", "m", "b", "t"];

  // Formatowanie małych liczb
  if (value <= 10) return value.toFixed(6).replace(/\.?0+$/, ""); // 6 miejsc po przecinku dla liczb <= 10
  if (value > 10 && value < 100) return value.toFixed(4).replace(/\.?0+$/, ""); // 4 miejsca po przecinku dla liczb od 10 do 99

  // Funkcja pomocnicza do formatowania dużych liczb
  const formatValue = (val: number, precision: number = 2): string => {
    const absValue = Math.abs(val);

    if (absValue >= 1000) {
      const scaledValue = absValue / 1000;
      return scaledValue.toFixed(precision).replace(/\.?0+$/, "") + "k";
    }

    return val.toFixed(precision).replace(/\.?0+$/, "");
  };

  let absValue = Math.abs(value);
  let suffixIndex = 0;

  while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
    absValue /= 1000;
    suffixIndex++;
  }

  const formattedNumber = formatValue(value, suffixIndex > 0 ? 2 : 0);

  return value < 0 ? `-${formattedNumber}` : formattedNumber;
}

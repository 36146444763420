import styled from "styled-components";
import { motion } from "framer-motion";
import { Typography } from "../Typography/Typography";
import { ReactComponent as CloseButton } from "../../assets/closeButton.svg";
import { device } from "../../style";

export const Background = styled(motion.div)<{ withBackdropBlur?: boolean }>`
  backdrop-filter: ${({ withBackdropBlur }) =>
    withBackdropBlur ? "blur(3px)" : "none"};
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 62px;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modalBg};
  width: 100%;
  height: calc(100% - 62px);
  overflow: hidden;

  @media ${device.tablet} {
    position: fixed;
    height: 100%;
  }

  @media ${device.laptop} {
    width: calc(100% - 220px);
    top: 0;
  }
`;

export const Modal = styled(motion.div)<{
  message?: boolean;
  isOnboarding?: boolean;
}>`
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  max-height: ${({ isOnboarding }) => (isOnboarding ? "52vh" : "70vh")};
  min-height: ${({ isOnboarding }) => (isOnboarding ? "52vh" : "70vh")};
  padding: 16px;
  width: 100vw;
  background: ${({ theme }) => theme.colors.white};
  ::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    will-change: transform;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.notification.text.primary};
    border-radius: 10px;

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.notification.text.secondary};
    }
  }

  @media ${device.tablet} {
    position: absolute;
  }

  @media ${device.laptop} {
    width: calc((100% - 220px) / 2);
    top: 0;
    left: unset;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 720px;
    padding: 32px;
  }

  /* @media ${device.laptopM} {
    width: calc((100% - 160px) / 2);
    padding: 40px 40px;
    top: 0;
    right: 0;
    bottom: unset;
    left: unset;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 720px;
  } */
`;

export const ModalContent = styled.div`
  max-width: 482px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 0;
  width: 100%;

  @media ${device.tablet} {
    margin-top: 0;
    padding: 120px 20px;
    max-height: 100%;
  }

  @media ${device.laptop} {
    padding: 0;
  }
`;

export const ModalHeader = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 0;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Title = styled(Typography.ModalTitle)<{ fullScreen?: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  color: ${({ theme, fullScreen }) =>
    fullScreen ? theme.colors.black : theme.colors.grey};
  font-weight: 700;
  position: ${({ fullScreen }) => (fullScreen ? "absolute" : "unset")};
  top: ${({ fullScreen }) => (fullScreen ? "80px" : "unset")};
  left: ${({ fullScreen }) => (fullScreen ? "50%" : "unset")};
  transform: ${({ fullScreen }) => (fullScreen ? "translateX(-50%)" : "unset")};

  img {
    width: 24px;
    height: 24px;
  }
`;

export const CloseBtn = styled(CloseButton)<{ disabled?: boolean }>`
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  color: ${({ theme }) => theme.colors.black};

  @media ${device.laptop} {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  path {
    stroke: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const ModalTitleText = styled(motion.div)`
  font-family: Ingra;
  font-size: 32px;
  font-weight: 300;
  line-height: 35.52px;
  color: ${({ theme }) => theme.colors.primaryLight};

  span {
    font-weight: 700;
    margin-right: 8px;
  }
`;

export const DescriptionWrapper = styled(motion.div)`
  p {
    font-weight: 300;
  }
`;

export const Description = styled(Typography.IngraLabel)``;

export const AdditionalStepsModal = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  background: linear-gradient(137.87deg, #13093e 1.25%, #47126a 72.34%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  max-height: calc(100vh - 62px);
  min-height: calc(100vh - 62px);
  bottom: unset;
  top: 62px;
  position: fixed;
  left: 0;
  z-index: 1000;
  min-height: calc(35vh - 62px);
  max-height: calc(35vh - 62px);

  &::before {
    content: "";
    background-image: url("/bg-logo.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    @media ${device.laptop} {
      width: 100%;
    }
  }

  @media ${device.laptop} {
    width: calc((100% - 220px) / 2);
    right: calc((100% - 220px) / 2);
    top: 0;
    bottom: unset;
    left: unset;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 720px;
    box-shadow: -8px 0 15px rgba(0, 0, 0, 0.2), -15px 0 20px rgba(0, 0, 0, 0.15),
      -20px 0 40px rgba(0, 0, 0, 0.1);
    transform: translateX(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* @media ${device.laptopM} {
    width: calc((100% - 160px) / 2);
    top: 0;
    right: calc((100% - 160px) / 2);
    bottom: unset;
    left: unset;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 720px;
 
  } */

  @media screen and (min-width: 1600px) {
    right: 720px;
  }
`;

export const SkipButton = styled(motion.button)`
  position: absolute;
  bottom: -40px;
  right: 20px;
  background: transparent;
  border-radius: 16px;
  border: 1px solid #08a98a;
  color: #08a98a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 108px;

  &:hover {
    background: #08a98a;
    color: #fff;
    transition: all 0.3s;
  }

  @media ${device.tablet} {
    right: 40px;
  }

  @media ${device.laptop} {
    right: 60px;
  }

  @media ${device.laptopM} {
    right: 95px;
  }
`;

import axios from "axios";
import { config } from "../config";
import { refreshAccessToken } from "./auth";

const apiConfig = {
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const apiCall = axios.create(apiConfig);
export const apiCallWithAuthToken = axios.create(apiConfig);

apiCallWithAuthToken.interceptors.request.use(
  (conf) => {
    if (!conf.headers) conf.headers = {};
    conf.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return conf;
  },
  (error) => Promise.reject(error)
);

// eslint-disable-next-line
const errorResponseInterceptor = async (error: any) => {
  const originalRequest = error.config;
  const { status } = error.response;

  if (status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      return Promise.reject(error);
    }

    try {
      const { data } = await refreshAccessToken(refreshToken);

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);

      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

      return apiCallWithAuthToken(originalRequest);
      // eslint-disable-next-line
    } catch (e: any) {
      const { status: refreshRequestStatus } = e.response;

      if (refreshRequestStatus === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.reload();
      }
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};

apiCallWithAuthToken.interceptors.response.use(
  (response) => response,
  errorResponseInterceptor
);

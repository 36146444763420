import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount, useSwitchChain } from "wagmi";
import { backButton } from "../../../../assets";
import {
  useAuth,
  useMediaQuery,
  useProtocolSettingsAddress,
} from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { getSupportedChainsData } from "../../../../redux/features/settingsSlice";
import { getUserData } from "../../../../redux/features/userSlice";
import { getBalances } from "../../../../redux/features/walletSlice";
import { device } from "../../../../style";
import { defaultHomeRoute, routes } from "../../../../utils/routes";
import { Logo } from "../Logo/Logo";
import ToolBar from "../ToolBar/ToolBar";
import { AdminNetworkInfo } from "./AdminNetworkInfo/AdminNetworkInfo";
import { AdminRoleAlert } from "./AdminRoleAlert";
import * as S from "./Header.styled";
import { setChainId } from "../../../../redux/features/uiSlice";

export const Header = () => {
  const { isConnected, address, isReconnecting, connector, chainId } =
    useAccount();
  const [windowIsHidden, setWindowIsHidden] = useState(document.hidden);

  const { login, initLoginIsPending } = useAuth();
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );
  const { isLogged, tfaAuthenticated, tfaEnabled, authenticatedChainId } =
    useAppSelector((state) => state.user);
  const supportedChains = useAppSelector(
    (state) => state.settings.supportedChains
  );
  const { isSuccess } = useSwitchChain();
  const isDesktopView = useMediaQuery(device.laptopM);
  const isLaptopView = useMediaQuery(device.laptop);
  const isTabletView = useMediaQuery(device.tablet);
  const { pathname } = useLocation();
  // const { isPending } = useSignMessage();
  const isProjectDetailPathname = pathname.includes("/project-details");
  const protocolSettingsAddress = useProtocolSettingsAddress();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const { loading } = useAppSelector((state) => state.user);

  const checkUserNeedSignMessage = async () => {
    if (!chainId || !connector) return false;
    const getUserDataPayload = {
      chainId,
      protocolSettingsAddress,
    };
    try {
      const userData = await dispatch(getUserData(getUserDataPayload)).unwrap();
      if (userData.walletAddress !== address) {
        return true;
      }
      return false;
    } catch (error: unknown) {
      if (isConnected && connector) {
        login();
      }
      return true;
    }
  };

  const verifyAndLogin = async () => {
    const needsSignMessage = await checkUserNeedSignMessage();

    if (
      (isConnected &&
        address &&
        address?.toLowerCase() !== walletAddress.toLowerCase() &&
        ((!tfaEnabled && !tfaAuthenticated) ||
          (tfaEnabled && tfaAuthenticated && !loading)) &&
        walletAddress &&
        isLogged &&
        !loading &&
        needsSignMessage) ||
      (isConnected &&
        address &&
        isLogged &&
        !loading &&
        !isReconnecting &&
        isSuccess &&
        Number(authenticatedChainId) !== chainId)
    ) {
      login();
    }
  };

  useEffect(() => {
    if (
      isConnected &&
      address &&
      ((!tfaEnabled && !tfaAuthenticated) ||
        (tfaEnabled && tfaAuthenticated && !loading)) &&
      (!isLogged ||
        (isLogged && address.toLowerCase() !== walletAddress.toLowerCase())) &&
      !loading &&
      !initLoginIsPending &&
      !windowIsHidden
    ) {
      verifyAndLogin();
    }
  }, [
    isConnected,
    address,
    connector,
    isLogged,
    chainId,
    initLoginIsPending,
    walletAddress,
    windowIsHidden,
  ]);

  useEffect(() => {
    if (
      address &&
      isLogged &&
      !loading &&
      !initLoginIsPending &&
      address.toLowerCase() !== walletAddress.toLowerCase()
    ) {
      verifyAndLogin();
    }
  }, [address, isLogged, loading, initLoginIsPending, walletAddress]);

  useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        setWindowIsHidden(true);
      } else {
        setWindowIsHidden(false);
      }
    };

    document.addEventListener("visibilitychange", listener);

    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, []);

  useEffect(() => {
    if (address && chainId) {
      dispatch(getBalances({ address, chainId }));
    }
  }, [
    isConnected,
    address,
    supportedChains.find((c) => c.chainId === chainId?.toString())?.tokens
      .length,
  ]);

  useEffect(() => {
    dispatch(getSupportedChainsData());
  }, []);

  useEffect(() => {
    if (chainId) {
      dispatch(setChainId(chainId));
    }
  }, [chainId]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "login_timestamp") {
        // Można ustawić logikę, aby wymusić odświeżenie stanu logowania
        if (!event.newValue) {
          // login_timestamp usunięty, logowanie zakończone - można spróbować ponownie
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <S.Wrapper
      connectVariant={
        pathname === defaultHomeRoute && !isConnected && !isLogged
      }
      isLogged={isLogged}
      style={{
        display:
          pathname === routes.userPanel.dashboard && !isLogged && isLaptopView
            ? "none"
            : "flex",
      }}
    >
      {/* {!isDesktopView ? (
        <S.MobileWrapper>
          <S.LogoWrapper>
            <img src={smallLogo} alt="logo soil" />
          </S.LogoWrapper>
          <Typography.HeaderSmall>
            {capitalizeFirstLetter(pathToDisplay)}
          </Typography.HeaderSmall>
          <AdminRoleAlert />
          {pathname === routes.userPanel.wallet && isConnected ? (
            <WalletStatus isConnected={isConnected} />
          ) : null}
        </S.MobileWrapper>
      ) 
      :
       ( */}
      {isDesktopView ? null : isTabletView ? (
        <S.LogoMobileWrapper>
          <Logo />
        </S.LogoMobileWrapper>
      ) : (
        <S.LogoMobileWrapper>
          <Logo small />
        </S.LogoMobileWrapper>
      )}
      <S.DesktopWrapper isProjectDetailPathname={isProjectDetailPathname}>
        {/* {isEarnPathnameButNotProjectDetails && isLogged && isDesktopView ? (
          <Button.Secondary
            style={{ width: "220px", marginRight: "auto" }}
            disabled={isButtonDisabled}
            onClick={handleRefreshPoolsData}
          >
            Refresh pools data{" "}
            {isButtonDisabled && timeoutStart && (
              <Countdown
                date={timeoutStart + disableButtonTimeout * 1000}
                renderer={({ seconds }) => <span>{` (${seconds})`}</span>}
              />
            )}
          </Button.Secondary>
        ) : null} */}
        {isProjectDetailPathname ? (
          <S.BackWrapper onClick={handleBack}>
            <img src={backButton} alt="back" />
          </S.BackWrapper>
        ) : null}
        <AdminRoleAlert />
        <AdminNetworkInfo />
        <ToolBar />
      </S.DesktopWrapper>
      {/* )} */}
    </S.Wrapper>
  );
};

import { motion } from "framer-motion";
import styled from "styled-components";

export type ButtonProps = {
  isLoading?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
  bigSize?: boolean;
  moreRadius?: boolean;
};

const BasicButton = styled(motion.button).withConfig({
  shouldForwardProp: (prop) => !["bigSize"].includes(prop),
})<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ bigSize }) => (bigSize ? "50px" : "32px")};
  padding: ${({ bigSize }) => (bigSize ? "14px 24pxpx" : "14px")};
  white-space: nowrap;
  font-family: "Source Code Pro";
  transition: none;
  animation: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.71px;
  letter-spacing: 0.04em;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : null)};
  color: ${({ theme, isLoading }) =>
    isLoading ? "transparent" : theme.colors.text.primary};
  border-radius: ${({ moreRadius }) => (moreRadius ? "27px" : "10px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  transition: 0.35s;
  position: relative;
  cursor: ${({ disabled, isLoading }) =>
    disabled || isLoading ? "auto" : "pointer"};
  pointer-events: ${({ disabled, isLoading }) =>
    disabled || isLoading ? "none" : "auto"};

  & > span,
  & > div,
  & > p,
  & > strong,
  & > em,
  & > b {
    visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }

  &::after {
    content: "";
    display: ${({ isLoading }) => (isLoading ? "inline-block" : "none")};
    position: absolute;
    width: ${({ bigSize }) => (bigSize ? "24px" : "20px")};
    height: ${({ bigSize }) => (bigSize ? "24px" : "20px")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: ${({ isLoading }) => (isLoading ? "3px solid" : "none")};
    border-radius: 50%;
    border-top-color: transparent;
    animation: button-loading-spinner 1s ease infinite;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const Primary = styled(BasicButton)`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.button.primary.border};
  background: ${({ theme }) => theme.colors.button.primary.main};

  :hover {
    background: ${({ theme }) => theme.colors.button.primary.hover};
  }

  :active {
    background: ${({ theme }) =>
      `${theme.colors.button.primary.hover} padding-box, ${theme.colors.button.primary.border} border-box `};
  }

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.button.primary.border};
  }

  :disabled {
    border: 1px solid #4e4e4e;
    background: #4e4e4e;
  }
`;

const Secondary = styled(BasicButton)`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.button.secondary.border};
  background: ${({ theme }) =>
    `${theme.colors.button.secondary.main} padding-box, ${theme.colors.button.secondary.border} border-box `};

  :hover {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.hover} padding-box, ${theme.colors.button.secondary.border} border-box `};
  }

  :active {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.clicked} padding-box, ${theme.colors.button.secondary.border} border-box `};
  }

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.button.secondary.border};
  }
`;

const Tertiary = styled(BasicButton)`
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.button.teritary.border};
  background: transparent;

  :hover {
    background: ${({ theme }) => theme.colors.button.teritary.border};
  }

  :active {
    background: ${({ theme }) =>
      `${theme.colors.button.teritary.border} padding-box, ${theme.colors.button.teritary.border} border-box `};
  }

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.button.teritary.border};
  }
`;

export const Button = {
  Primary,
  Secondary,
  Tertiary,
};

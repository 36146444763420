import { authTfa } from "../api/tfa";
import { useAppDispatch, useAppSelector } from "../redux";
import {
  authenticateShortTermClaim,
  authenticateShortTermDeposit,
  authenticateShortTermRedeposit,
  authenticateShortTermWithdraw,
  authenticateTfaClaim,
  authenticateTfaCompound,
  authenticateTfaDeposit,
  authenticateTfaHarvest,
  authenticateTfaStake,
  authenticateTfaUnStake,
  setTfaActionInProgress,
  resetTfaActionInProgress,
  resetTfaAuthorizationForAction,
} from "../redux/features/userSlice";
import { refreshAccessToken } from "../api/auth";
import { toast } from "../toasts";

export const useTfa = () => {
  const dispatch = useAppDispatch();
  const { currentAction } = useAppSelector((state) => state.user);

  const authenticateWithTfa = async (code: string) => {
    dispatch(setTfaActionInProgress());
    try {
      const { data } = await authTfa(code);
      const { refreshToken, accessToken } = data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      switch (currentAction) {
        case "stake": {
          return dispatch(authenticateTfaStake());
        }
        case "unstake": {
          return dispatch(authenticateTfaUnStake());
        }
        case "compound": {
          return dispatch(authenticateTfaCompound());
        }
        case "harvest": {
          return dispatch(authenticateTfaHarvest());
        }
        case "deposit": {
          return dispatch(authenticateTfaDeposit());
        }
        case "claim": {
          return dispatch(authenticateTfaClaim());
        }
        case "shortDeposit": {
          return dispatch(authenticateShortTermDeposit());
        }
        case "shortClaim": {
          return dispatch(authenticateShortTermClaim());
        }
        case "shortRewardsUnlock": {
          return dispatch(authenticateShortTermClaim());
        }
        case "shortWithdraw": {
          return dispatch(authenticateShortTermWithdraw());
        }
        case "shortRedeposit": {
          return dispatch(authenticateShortTermRedeposit());
        }
        default:
      }
    } catch {
      toast.error("Tfa authentication failed", "Try again with correct code.");
    }
  };

  const resetAfterTfa = async () => {
    const currentRefreshToken = localStorage.getItem("refreshToken");
    if (currentRefreshToken) {
      const {
        data: { refreshToken, accessToken },
      } = await refreshAccessToken(currentRefreshToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      dispatch(resetTfaActionInProgress());
      dispatch(resetTfaAuthorizationForAction());
    }
  };

  return {
    authenticateWithTfa,
    resetAfterTfa,
  };
};

// import dayjs from "dayjs";
import styled from "styled-components";
import { PoolIcon } from "../PoolIcon";
import * as S from "./PoolTitle.styled";

type PoolTitleProps = {
  poolLevel: PoolLevel;
};

export const PoolTitle = (props: PoolTitleProps) => {
  const { poolLevel } = props;
  const { pool } = poolLevel;

  const isV2 = pool.contract?.version === "v2";
  // const isRecentlyAdded = dayjs(createdAt).isAfter(dayjs().subtract(7, "days"));

  return (
    <S.TitleWrapper role="link" aria-label="Link to project details">
      <S.IconWrapper>
        {isV2 ? (
          <TextWrapper>
            <Title>{poolLevel.name}</Title>
            <Subtitle>{poolLevel.description}</Subtitle>
          </TextWrapper>
        ) : (
          <PoolIcon poolIcon={pool.icon} />
        )}
      </S.IconWrapper>
    </S.TitleWrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 10px;
`;

const Title = styled.p`
  font-family: Ingra;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
`;

const Subtitle = styled.p`
  font-family: Ingra;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #afbdd7;
  max-width: 70%;
`;

import { BigNumber, BigNumberish, ethers } from "ethers";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import { PoolsContract, PoolsContractV2 } from "./contracts";
import { erc20abi, poolsContractAbi, soilTokenAbi } from "./contracts/abi";
import { tokenService } from "./token.service";

const getFeeBalance = async () => PoolsContract.feeBalance();

const getFeeBalanceV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  token: string
) => PoolsContractV2(poolsContractAddress, provider).feeBalanceInToken(token);

const getPoolContractFees = async () => PoolsContract.poolsContractFee();

const getPoolContractFeesV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  token: string
) =>
  PoolsContractV2(poolsContractAddress, provider).poolsContractFeeInToken(
    token
  );

const changePoolsContractFee = async (
  fee: BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).changePoolsContractFee(
    fee
  );
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const changePoolsContractFeeV2 = async (
  poolsContractAddress: string,
  fee: BigNumber,
  signer: ethers.providers.JsonRpcSigner,
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .changePoolsContractFeeForToken(fee, token);
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdrawFees = async (
  address: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdrawFees(address);
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdrawFeesV2 = async (
  poolsContractAddress: string,
  address: string,
  signer: ethers.providers.JsonRpcSigner,
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .withdrawFees(address, token);
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const deposit = async (
  USDC_TOKEN_ADDRESS: string,
  poolId: Pool["id"],
  level: PoolLevel["level"],
  amount: BigNumber,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  await tokenService.approveIfNeeded(
    USDC_TOKEN_ADDRESS,
    PoolsContract.address,
    amount,
    signer
  );

  const { wait } = await PoolsContract.connect(signer).deposit(
    poolId,
    level,
    amount,
    deadline,
    signature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const depositV2 = async (
  USDC_TOKEN_ADDRESS: string,
  poolId: Pool["id"],
  level: PoolLevel["level"],
  amount: BigNumber,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string
) => {
  await tokenService.approveIfNeeded(
    USDC_TOKEN_ADDRESS,
    poolsContractAddress,
    amount,
    signer
  );

  const params = {
    userAddress,
    poolId,
    level,
    amount,
    deadline,
    signature,
  };

  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .deposit(params);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdraw = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  amount: BigNumber | string,
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdraw(
    poolId,
    level,
    amount,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdrawV2 = async (
  poolId: string,
  level: string,
  amount: BigNumber | string,
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  withdrawSignature: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string
) => {
  const params = {
    userAddress,
    poolId,
    level,
    amount,
    rewards,
    soilRewards,
    deadline,
    withdrawSignature,
  };

  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .withdraw(params);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdrawUnlockedTokensV2 = async (
  poolId: string,
  level: string,
  amount: BigNumber | string,
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddres: string,
  userAddress: string,
  positionId: string
) => {
  const params = {
    userAddress,
    poolId,
    level,
    amount,
    rewards,
    soilRewards,
    deadline,
    withdrawSignature: signature,
  };
  const { wait } = await PoolsContractV2(poolsContractAddres, signer)
    .connect(signer)
    .withdrawUnlockedTokens(params, positionId);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const claimRewards = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).claimRewards(
    poolId,
    level,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const claimRewardsV2 = async (
  poolId: string,
  level: string,
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .claimRewards(
      userAddress,
      poolId,
      level,
      rewards,
      soilRewards,
      deadline,
      claimSignature
    );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const upgradePool = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  upgradeToLevel: PoolLevel["level"],
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(
    signer
  ).upgradeToPoolWithHigherYield(
    poolId,
    level,
    upgradeToLevel,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const upgradePoolV2 = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  upgradeToLevel: PoolLevel["level"],
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .upgradeToPoolWithHigherYield(
      userAddress,
      poolId,
      level,
      upgradeToLevel,
      rewards,
      soilRewards,
      deadline,
      claimSignature
    );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const createPool = async (
  signer: ethers.providers.JsonRpcSigner,
  name: string,
  startTime: BigNumberish,
  endTime: BigNumberish,
  softCap: BigNumberish,
  hardCap: BigNumberish,
  periods: BigNumberish[],
  fixedAPRs: BigNumberish[]
) => {
  const { wait } = await PoolsContract.connect(signer).createPool(
    name,
    startTime,
    endTime,
    softCap,
    hardCap,
    periods,
    fixedAPRs
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const createPoolV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  name: string,
  startTime: BigNumberish,
  endTime: BigNumberish,
  softCap: BigNumberish,
  hardCap: BigNumberish,
  isShort: boolean,
  fixedAPRs: BigNumberish[],
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .createPool(
      name,
      token,
      startTime,
      endTime,
      softCap,
      hardCap,
      fixedAPRs,
      isShort
    );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const updatePool = async (
  signer: ethers.providers.JsonRpcSigner,
  poolId: BigNumberish,
  startTime: BigNumberish,
  endTime: BigNumberish
) => {
  const { wait } = await PoolsContract.connect(signer).updatePool(
    poolId,
    startTime,
    endTime
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const updatePoolV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  poolId: BigNumberish,
  startTime: BigNumberish,
  endTime: BigNumberish,
  hardCap: BigNumberish
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .updatePool(poolId, startTime, endTime, hardCap);

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const getUserInfo = (user: string, poolId: BigNumberish, level: BigNumberish) =>
  PoolsContract.userInfo(user, poolId, level);

const getUserInfoV2 = (
  poolsContractAddress: string,
  user: string,
  poolId: BigNumberish,
  level: BigNumberish,
  provider: ethers.providers.JsonRpcProvider
) =>
  PoolsContractV2(poolsContractAddress, provider).userInfo(user, poolId, level);

const withdrawFunds = async (
  target: string,
  amount: ethers.BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdrawFunds(
    target,
    amount
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const withdrawFundsV2 = async (
  poolsContractAddress: string,
  target: string,
  amount: ethers.BigNumber,
  signer: ethers.providers.JsonRpcSigner,
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .withdrawFunds(target, amount, token);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const changeSoilWithdrawLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).changeSoilRewardsLimit(
    newLimit
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeSoilWithdrawLimitV2 = async (
  poolsContractAddress: string,
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .changeSoilRewardsLimit(newLimit);

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeUsdcWithdrawLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).changeTokenRewardsLimit(
    newLimit
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeUsdcWithdrawLimitV2 = async (
  poolsContractAddress: string,
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber,
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .changeTokenRewardsLimit(token, newLimit);

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const initPoolsContract = () =>
  new ethers.Contract(
    config.POOLS_CONTRACT_ADDRESS_POLYGON_V1,
    poolsContractAbi,
    AppRpcProvider
  );

const limitOfSoilRewards = async () => {
  const poolsContract = initPoolsContract();
  const soilLimit = await poolsContract.limitOfSoilRewards();
  return soilLimit;
};

const limitOfSoilRewardsV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const soilLimit = await PoolsContractV2(poolsContractAddress, provider)
    .connect(provider)
    .limitOfSoilRewards();
  return soilLimit;
};

const limitOfUsdcRewards = async () => {
  const poolsContract = initPoolsContract();
  const usdcLimit = await poolsContract.limitOfTokenRewards();
  return usdcLimit;
};

const limitOfUsdcRewardsV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  token: string
) => {
  const usdcLimit = await PoolsContractV2(poolsContractAddress, provider)
    .connect(provider)
    .limitOfTokenRewards(token);
  return usdcLimit;
};

const getSoilRewards = async () => {
  const poolsContract = initPoolsContract();
  const soilRewards = await poolsContract.amountOfSoilRewards();
  return soilRewards;
};

const getSoilRewardsV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const soilRewards = await PoolsContractV2(poolsContractAddress, provider)
    .connect(provider)
    .amountOfSoilRewards();
  return soilRewards;
};

const getUsdcRewards = async () => {
  const poolsContract = initPoolsContract();
  const usdcRewards = await poolsContract.amountOfTokenRewards();
  return usdcRewards;
};

const getUsdcRewardsV2 = async (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  token: string
) => {
  const usdcRewards = await PoolsContractV2(poolsContractAddress, provider)
    .connect(provider)
    .amountOfTokenRewards(token);
  return usdcRewards;
};

const changeAgreementToBeRewardsHolder = async (
  USDC_TOKEN_ADDRESS: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const SoilTokenContract = new ethers.Contract(
    config.SOIL_TOKEN_ADDRESS,
    soilTokenAbi,
    signer
  );

  const UsdcTokenContract = new ethers.Contract(
    USDC_TOKEN_ADDRESS,
    erc20abi,
    signer
  );

  await SoilTokenContract.approve(
    config.POOLS_CONTRACT_ADDRESS_POLYGON_V1,
    ethers.constants.MaxUint256
  );
  await UsdcTokenContract.approve(
    config.POOLS_CONTRACT_ADDRESS_POLYGON_V1,
    ethers.constants.MaxUint256
  );

  const { wait } = await PoolsContract.connect(
    signer
  ).changeAgreementToBeRewardsHolder();

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeAgreementToBeRewardsHolderV2 = async (
  tokenAddress: string,
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  isPolygon: boolean
) => {
  if (isPolygon) {
    const SoilTokenContract = new ethers.Contract(
      config.SOIL_TOKEN_ADDRESS,
      soilTokenAbi,
      signer
    );

    await SoilTokenContract.approve(
      poolsContractAddress,
      ethers.constants.MaxUint256
    );
  }

  const tokenContract = new ethers.Contract(tokenAddress, erc20abi, signer);

  await tokenContract.approve(
    poolsContractAddress,
    ethers.constants.MaxUint256
  );

  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .changeAgreementToBeRewardsHolder();

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const sendUsdcRewardsToContract = async (
  signer: ethers.providers.JsonRpcSigner,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).sendTokenRewards(
    from,
    amount
  );

  const tx = await wait(1);
  return tx;
};

const sendTokenRewardsToContractV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  from: string,
  amount: BigNumber,
  token: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .sendTokenRewards(from, amount, token);

  const tx = await wait(1);
  return tx;
};

const sendSoilRewardsToContract = async (
  signer: ethers.providers.JsonRpcSigner,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).sendSoilRewards(
    from,
    amount
  );

  const tx = await wait(1);
  return tx;
};

const sendSoilRewardsToContractV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .sendSoilRewards(from, amount);

  const tx = await wait(1);
  return tx;
};

const redepositV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string,
  poolId: BigNumberish,
  level: BigNumberish,
  rewards: BigNumberish,
  deadline: string,
  signature: string
) => {
  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .redeposit(userAddress, poolId, level, rewards, deadline, signature);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const unlockTokensV2 = async (
  signer: ethers.providers.JsonRpcSigner,
  poolsContractAddress: string,
  userAddress: string,
  poolId: BigNumberish,
  level: BigNumberish,
  amount: BigNumberish,
  deadline: string,
  signature: string
) => {
  const unlockParams = {
    userAddress,
    poolId,
    level,
    amount,
    deadline,
    unlockSignature: signature,
  };

  const { wait } = await PoolsContractV2(poolsContractAddress, signer)
    .connect(signer)
    .unlockTokens(unlockParams);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

export const poolsContractService = {
  getFeeBalance,
  getFeeBalanceV2,
  getPoolContractFees,
  getPoolContractFeesV2,
  changePoolsContractFee,
  changePoolsContractFeeV2,
  withdrawFees,
  withdrawFeesV2,
  deposit,
  depositV2,
  withdraw,
  withdrawV2,
  claimRewards,
  claimRewardsV2,
  upgradePool,
  upgradePoolV2,
  createPool,
  createPoolV2,
  getUserInfo,
  getUserInfoV2,
  updatePool,
  updatePoolV2,
  withdrawFunds,
  withdrawFundsV2,
  changeSoilWithdrawLimit,
  changeSoilWithdrawLimitV2,
  changeUsdcWithdrawLimit,
  changeUsdcWithdrawLimitV2,
  limitOfSoilRewards,
  limitOfSoilRewardsV2,
  limitOfUsdcRewards,
  limitOfUsdcRewardsV2,
  getSoilRewards,
  getSoilRewardsV2,
  getUsdcRewards,
  getUsdcRewardsV2,
  changeAgreementToBeRewardsHolder,
  changeAgreementToBeRewardsHolderV2,
  sendSoilRewardsToContract,
  sendSoilRewardsToContractV2,
  sendUsdcRewardsToContract,
  sendTokenRewardsToContractV2,
  redepositV2,
  unlockTokensV2,
  withdrawUnlockedTokensV2,
};

import { apiCallWithAuthToken } from "./config";

export const updateSettings = (setting: string, value: string) =>
  apiCallWithAuthToken.patch(`settings/${setting}`, {
    value,
  });

type GetSettingsResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  value: string;
};

export const getSettings = () =>
  apiCallWithAuthToken.get<GetSettingsResponse[]>(`settings`);

interface PoolRewards {
  token: string;
  balance: string;
  poolVersion: "v1" | "v2";
  chainId: number;
}
type GetUnclaimedRewardsResponse = {
  stakingRewards: string;
  poolsRewards: PoolRewards[];
};

export const getUnclaimedRewards = () =>
  apiCallWithAuthToken.get<GetUnclaimedRewardsResponse>(
    "stats/total-unclaimed-rewards"
  );

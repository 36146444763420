import { ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CurrencyValue,
  InfoBox,
  Modal,
  PenIcon,
  Typography,
} from "../../components";
import { config } from "../../config";
import { useModal } from "../../hooks";
import { stakingService } from "../../services";
import { device } from "../../style";
import { toast } from "../../toasts";
import { EditRestakeLimitForm } from "./components/EditRestakeLimitForm";
import { EditRewardsLimitForm } from "./components/EditRewardsLimitForm";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 24px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const StakingWithdrawLimits = () => {
  const [restakeLimit, setRestakeLimit] = useState<string>();
  const [rewardsLimit, setRewardsLimit] = useState<string>();

  const handleGetRestakeLimit = async () => {
    try {
      const currentLimit = await stakingService.limitOfRestakingRewards();
      setRestakeLimit(
        ethers.utils.formatUnits(currentLimit, config.SOIL_TOKEN_DECIMALS)
      );
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch staking restake limit");
    }
  };

  const handleGetRewardsLimit = async () => {
    try {
      const currentLimit = await stakingService.limitOfSoilRewards();
      setRewardsLimit(
        ethers.utils.formatUnits(currentLimit, config.SOIL_TOKEN_DECIMALS)
      );
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch staking rewards limit");
    }
  };

  useEffect(() => {
    handleGetRestakeLimit();
    handleGetRewardsLimit();
  }, []);

  const {
    isModalOpen: isRewardsLimitModalOpen,
    handleModalClose: rewardsModalClose,
    handleModalOpen: rewardsModalOpen,
  } = useModal();
  const {
    isModalOpen: isRestakeLimitModalOpen,
    handleModalClose: restakeModalClose,
    handleModalOpen: restakeModalOpen,
  } = useModal();

  return (
    <>
      <Modal
        title={["Edit SOIL", "rewards withdraw limit"]}
        isOpen={isRewardsLimitModalOpen}
        onClose={rewardsModalClose}
        withOutsideClick
      >
        <EditRewardsLimitForm
          closeModal={rewardsModalClose}
          value={rewardsLimit || "0"}
          update={handleGetRewardsLimit}
        />
      </Modal>
      <Modal
        title={["Edit SOIL", "rewards restake limit"]}
        isOpen={isRestakeLimitModalOpen}
        onClose={restakeModalClose}
        withOutsideClick
      >
        <EditRestakeLimitForm
          closeModal={restakeModalClose}
          value={restakeLimit || "0"}
          update={handleGetRestakeLimit}
        />
      </Modal>
      <ItemsWrapper>
        <Typography.Header>Staking limits</Typography.Header>
        <BoxesWrapper>
          <InfoBox.Admin title="SOIL rewards withdraw limit:">
            <Row>
              <CurrencyValue
                value={rewardsLimit || null}
                currency="$SOIL"
                decimals={2}
                isBig
              />
              <PenIcon noWrap click={rewardsModalOpen} />
            </Row>
          </InfoBox.Admin>
          <InfoBox.Admin title="SOIL rewards restake limit:">
            <Row>
              <CurrencyValue
                value={restakeLimit || null}
                currency="$SOIL"
                decimals={2}
                isBig
              />
              <PenIcon noWrap click={restakeModalOpen} />
            </Row>
          </InfoBox.Admin>
        </BoxesWrapper>
      </ItemsWrapper>
    </>
  );
};

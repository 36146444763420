import styled from "styled-components";
import { device } from "../../style";

export const Text = styled.p`
  font-family: "Source Code Pro";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -0.64px;
  text-align: left;
  color: #fff;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  width: 28px;
  height: 28px;
  flex-shrink: 0;

  @media ${device.tablet} {
    width: 32px;
    height: 32px;
  }
`;

import { NavIconProps, NavIconVariant } from ".";

export const EarnIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_637)">
            <path
              d="M20.0977 5.8501C20.0977 5.15974 19.538 4.6001 18.8477 4.6001C18.1573 4.6001 17.5977 5.15974 17.5977 5.8501C17.5977 6.54046 18.1573 7.1001 18.8477 7.1001C19.538 7.1001 20.0977 6.54046 20.0977 5.8501Z"
              fill="#08A98A"
            />
            <path
              d="M15.9297 1.68335C15.9297 0.992991 15.37 0.43335 14.6797 0.43335C13.9894 0.43335 13.4297 0.992991 13.4297 1.68335C13.4297 2.37371 13.9894 2.93335 14.6797 2.93335C15.37 2.93335 15.9297 2.37371 15.9297 1.68335Z"
              fill="#08A98A"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.0276 1.6834L14.6824 7.02857L13.5039 5.85006L18.8491 0.504883L20.0276 1.6834Z"
              fill="#08A98A"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.67969 16.3763V12.9333H11.3464V16.3763C11.3464 16.4119 11.4069 16.6953 12.0901 17.0188C12.7097 17.3123 13.6243 17.5167 14.6797 17.5167C15.7351 17.5167 16.6497 17.3123 17.2693 17.0188C17.9524 16.6953 18.013 16.4119 18.013 16.3763V12.9333H19.6797V16.3763C19.6797 17.4308 18.8077 18.1343 17.9828 18.5251C17.0944 18.9459 15.9256 19.1833 14.6797 19.1833C13.4338 19.1833 12.265 18.9459 11.3766 18.5251C10.5517 18.1343 9.67969 17.4308 9.67969 16.3763Z"
              fill="#08A98A"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.34766 16.32V9.6001H3.01432V16.32C3.01432 16.3836 3.0927 16.6782 3.7663 17.0064C4.38376 17.3073 5.29531 17.5168 6.34766 17.5168C7.4 17.5168 8.31156 17.3073 8.92899 17.0064C9.60257 16.6782 9.68099 16.3836 9.68099 16.32V9.6001H11.3477V16.32C11.3477 17.3777 10.4934 18.0982 9.65899 18.5047C8.76848 18.9386 7.59671 19.1834 6.34766 19.1834C5.09861 19.1834 3.92683 18.9386 3.03631 18.5047C2.20191 18.0982 1.34766 17.3777 1.34766 16.32Z"
              fill="#08A98A"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.01432 12.1001V12.9334C3.01432 13.023 3.10862 13.3285 3.77406 13.6612C4.38964 13.969 5.29838 14.1834 6.34766 14.1834C7.39693 14.1834 8.30567 13.969 8.92124 13.6612C9.58666 13.3285 9.68099 13.023 9.68099 12.9334V12.1001H11.3477V12.9334C11.3477 13.9944 10.5092 14.7306 9.66657 15.1519C8.77416 15.5982 7.59957 15.8501 6.34766 15.8501C5.09574 15.8501 3.92115 15.5982 3.0287 15.1519C2.1861 14.7306 1.34766 13.9944 1.34766 12.9334V12.1001H3.01432Z"
              fill="#08A98A"
            />
            <path
              d="M14.6797 10.0166C13.4278 10.0166 12.2532 10.2685 11.3608 10.7148C10.5181 11.1361 9.67969 11.8723 9.67969 12.9333C9.67969 13.9943 10.5181 14.7304 11.3608 15.1518C12.2532 15.598 13.4278 15.8499 14.6797 15.8499C15.9316 15.8499 17.1062 15.598 17.9986 15.1518C18.8413 14.7304 19.6797 13.9943 19.6797 12.9333C19.6797 11.8723 18.8413 11.1361 17.9986 10.7148C17.1062 10.2685 15.9316 10.0166 14.6797 10.0166Z"
              fill="#08A98A"
            />
            <path
              d="M6.34766 6.68335C5.09574 6.68335 3.92115 6.9353 3.0287 7.38152C2.1861 7.80282 1.34766 8.53902 1.34766 9.60002C1.34766 10.661 2.1861 11.3972 3.0287 11.8185C3.92115 12.2648 5.09574 12.5167 6.34766 12.5167C7.59957 12.5167 8.77416 12.2648 9.66657 11.8185C10.5092 11.3972 11.3477 10.661 11.3477 9.60002C11.3477 8.53902 10.5092 7.80282 9.66657 7.38152C8.77416 6.9353 7.59957 6.68335 6.34766 6.68335Z"
              fill="#08A98A"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_637">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.515625 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_659)">
            <path
              d="M18.8477 5.1001C19.2619 5.1001 19.5977 5.43589 19.5977 5.8501C19.5977 6.26431 19.2619 6.6001 18.8477 6.6001C18.4335 6.6001 18.0977 6.26431 18.0977 5.8501C18.0977 5.43589 18.4335 5.1001 18.8477 5.1001Z"
              stroke="#959595"
            />
            <path
              d="M14.6797 0.93335C15.0939 0.93335 15.4297 1.26914 15.4297 1.68335C15.4297 2.09756 15.0939 2.43335 14.6797 2.43335C14.2655 2.43335 13.9297 2.09756 13.9297 1.68335C13.9297 1.26914 14.2655 0.93335 14.6797 0.93335Z"
              stroke="#959595"
            />
            <path
              d="M19.3205 1.68339L14.6824 6.32146L14.211 5.85006L18.8491 1.21199L19.3205 1.68339Z"
              stroke="#959595"
            />
            <path
              d="M10.1797 16.3763V13.4333H10.8464V16.3763C10.8464 16.5553 10.9365 16.7471 11.0846 16.9175C11.2425 17.0993 11.4912 17.2884 11.8761 17.4707C12.5763 17.8024 13.5671 18.0167 14.6797 18.0167C15.7923 18.0167 16.7831 17.8024 17.4833 17.4707C17.8682 17.2884 18.1168 17.0993 18.2748 16.9175C18.4228 16.7471 18.513 16.5553 18.513 16.3763V13.4333H19.1797V16.3763C19.1797 17.1227 18.555 17.7008 17.7687 18.0732L17.9828 18.5251L17.7687 18.0732C16.961 18.4559 15.8685 18.6833 14.6797 18.6833C13.4909 18.6833 12.3984 18.4559 11.5906 18.0732L11.3766 18.5251L11.5906 18.0732C10.8044 17.7008 10.1797 17.1227 10.1797 16.3763Z"
              stroke="#959595"
            />
            <path
              d="M1.84766 16.32V10.1001H2.51432V16.32C2.51432 16.6745 2.81467 17.0989 3.54727 17.4559L3.5473 17.4559C4.24666 17.7967 5.23628 18.0168 6.34766 18.0168C7.45903 18.0168 8.44865 17.7967 9.14799 17.4559L9.14803 17.4559C9.88057 17.0989 10.181 16.6745 10.181 16.32V10.1001H10.8477V16.32C10.8477 17.0771 10.2298 17.6704 9.44001 18.0552L9.43997 18.0552C8.63142 18.4492 7.53773 18.6834 6.34766 18.6834C5.15758 18.6834 4.06389 18.4492 3.25532 18.0552L3.25529 18.0552C2.46554 17.6704 1.84766 17.0771 1.84766 16.32Z"
              stroke="#959595"
            />
            <path
              d="M10.181 12.6001V12.9334C10.181 13.3077 9.87315 13.7443 9.14482 14.1084C8.44621 14.4578 7.45773 14.6834 6.34766 14.6834C5.23758 14.6834 4.2491 14.4578 3.55048 14.1084L10.181 12.6001ZM10.181 12.6001H10.8477V12.9334C10.8477 13.7007 10.2359 14.3083 9.44297 14.7047L9.44295 14.7047C8.63364 15.1094 7.53882 15.3501 6.34766 15.3501C5.15649 15.3501 4.06167 15.1094 3.25231 14.7047M10.181 12.6001L3.55043 14.1084C2.82209 13.7443 2.51432 13.3076 2.51432 12.9334V12.6001H1.84766V12.9334C1.84766 13.7007 2.45945 14.3083 3.25231 14.7047M3.25231 14.7047L3.0287 15.1519M3.25231 14.7047L3.0287 15.1519M3.0287 15.1519C2.1861 14.7306 1.34766 13.9944 1.34766 12.9334V12.6001L6.34766 15.8501C5.09574 15.8501 3.92115 15.5982 3.0287 15.1519ZM1.84766 12.1001H2.51432H1.84766Z"
              stroke="#959595"
            />
            <path
              d="M11.5844 11.162L11.5844 11.162C12.3937 10.7573 13.4885 10.5166 14.6797 10.5166C15.8709 10.5166 16.9657 10.7573 17.775 11.162L17.775 11.162C18.5679 11.5584 19.1797 12.166 19.1797 12.9333C19.1797 13.7005 18.5679 14.3081 17.775 14.7046L17.775 14.7046C16.9657 15.1093 15.8709 15.3499 14.6797 15.3499C13.4885 15.3499 12.3937 15.1093 11.5844 14.7046L11.5844 14.7046C10.7915 14.3081 10.1797 13.7005 10.1797 12.9333C10.1797 12.166 10.7915 11.5584 11.5844 11.162Z"
              stroke="#959595"
            />
            <path
              d="M9.44297 11.3713L9.44295 11.3713C8.63364 11.776 7.53882 12.0167 6.34766 12.0167C5.15649 12.0167 4.06167 11.776 3.25232 11.3713C2.45945 10.9748 1.84766 10.3673 1.84766 9.60002C1.84766 8.83273 2.45946 8.22516 3.2523 7.82874C4.06167 7.42405 5.1565 7.18335 6.34766 7.18335C7.53881 7.18335 8.63364 7.42405 9.44296 7.82873L9.44298 7.82874C10.2359 8.22516 10.8477 8.83273 10.8477 9.60002C10.8477 10.3673 10.2359 10.9748 9.44297 11.3713Z"
              stroke="#959595"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_659">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.515625 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

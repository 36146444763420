import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { device } from "../style";

// here you can style toast

export const StyledToastContainer = styled(ToastContainer)`
  width: 100%;
  left: 0;
  bottom: 16px;
  right: 12px;
  padding: 4px 0;
  margin-top: 57px;

  @media ${device.mobileL} {
    width: 400px;
    left: auto;
  }

  @media ${device.tablet} {
    width: 577px;
    padding: 0;
  }

  &&& {
    .Toastify__toast {
      box-sizing: border-box;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 12px;
      box-shadow: ${({ theme }) => theme.colors.toast.boxShadow};
      backdrop-filter: blur(240px);
      margin-right: 16px;
      margin-left: 16px;
      margin-bottom: 1rem;

      @media ${device.tablet} {
        margin-right: 0;
      }
    }

    .Toastify__toast-body {
      padding: 15px 17px;
      align-items: flex-start;
      gap: 17px;
    }

    .Toastify__toast-icon {
      margin-top: 3px;
      margin-inline-end: 0;
    }
    .Toastify__toast--success {
      .Toastify__toast-icon {
        svg {
          fill: ${({ theme }) => theme.colors.toast.successIcon};
        }
      }
    }
    .Toastify__toast--error {
      .Toastify__toast-icon {
        svg {
          fill: ${({ theme }) => theme.colors.toast.errorIcon};
        }
      }
    }

    .Toastify__toast-container--top-right {
      top: 1em;
      right: 1em;
    }

    .Toastify__toast-container--bottom-right {
      top: 1em;
      right: 1em;
    }
  }
`;

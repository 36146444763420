import { motion } from "framer-motion";
import { useMemo, useState, useEffect } from "react";
import { useLocation, matchPath, NavLink } from "react-router-dom";
import * as S from "./NavLinks.styled";
import { routes } from "../../../../../utils/routes";

export const DropdownLinks: React.FC<{
  id: string;
  label: React.ReactNode;
  labelActive?: React.ReactNode;
  forceOpen?: boolean;
  links: Array<{
    id: string;
    to: string;
    label: React.ReactNode;
    onClick?: () => void;
  }>;
}> = ({ id, label, links, labelActive, forceOpen }) => {
  const location = useLocation();
  const isMatched = useMemo(
    () => links.some((link) => matchPath(window.location.pathname, link.to)),
    [location.pathname, links]
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleIsOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isMatched) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  }, [location.pathname, links]);

  return (
    <>
      <a
        href={`#${id}`}
        onClick={forceOpen ? undefined : handleIsOpen}
        className={
          location.pathname === routes.userPanel.earn.fixedPools ||
          location.pathname === routes.userPanel.earn.liquidStake
            ? "active"
            : ""
        }
      >
        {!isOpen ? label : labelActive || label}
      </a>
      {isOpen ||
        (forceOpen && (
          <motion.ul
            id={id}
            className="submenu"
            style={{ marginTop: 2 }}
            animate={{ x: 0, opacity: 1, height: "auto" }}
            initial={{ x: 10, opacity: 0, height: 0 }}
          >
            {links.map((link) => (
              <S.NavItem
                as="li"
                key={link.id}
                className={link.to === location.pathname ? "active" : ""}
              >
                <NavLink to={link.to} onClick={link.onClick}>
                  {link.label}
                </NavLink>
              </S.NavItem>
            ))}
          </motion.ul>
        ))}
    </>
  );
};

import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { Chain } from "wagmi/chains";
import {
  cursor,
  metamaskIcon,
  walletconnectIcon,
} from "../../../../../../assets";
import { Modal } from "../../../../../../components";
import { configWagmi } from "../../../../../../context/WagmiProvider";
import { useAuth, useModal } from "../../../../../../hooks";
import { useAppSelector } from "../../../../../../redux/hooks";
import * as S from "../SwitchNetwork/SwitchNetwork.styled";

const SwitchAccountAuth = () => {
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const userData = useAppSelector((state) => state.user.userData);
  const { isOpenSignMessageModal } = useAppSelector((state) => state.ui);
  const { tfaAuthenticated, authenticatedChainId } = useAppSelector(
    (state) => state.user
  );

  const { walletAddress } = userData;
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();
  const { logout } = useAuth();

  const { address, connector, chainId } = useAccount();
  const { isError } = useSignMessage();

  useEffect(() => {
    if (
      (configWagmi.chains?.some((data: Chain) => data.id === chainId) &&
        isLogged &&
        isOpenSignMessageModal &&
        address?.toLowerCase() !== walletAddress.toLowerCase()) ||
      (configWagmi.chains?.some((data: Chain) => data.id === chainId) &&
        isLogged &&
        isOpenSignMessageModal &&
        Number(chainId) !== Number(authenticatedChainId))
    ) {
      handleModalOpen();
    }
    if (!isOpenSignMessageModal) {
      handleModalClose();
    }

    if (!isOpenSignMessageModal && isError) {
      handleModalClose();
      logout();
    }
  }, [
    address,
    isOpenSignMessageModal,
    isError,
    tfaAuthenticated,
    chainId,
    authenticatedChainId,
  ]);

  return (
    <Modal
      title={["Sign", "message"]}
      onClose={handleModalClose}
      description={[
        "Sign signature request has been sent to your wallet app. Youmay approve signing for login to other account or reject to logout.",
        "Otherwise, if you want to stay logged in to your current account, change your connected wallet to the one that is logged in.",
        `${
          connector?.name === "MetaMask"
            ? "Check the metamask extension in Your browser..."
            : "Check your wallet app, that You use with the walletConnect..."
        }`,
      ]}
      isOpen={isModalOpen}
      withoutCloseIcon
    >
      <AnimatePresence>
        <S.ClickAnimationWrapper>
          <motion.img
            width={70}
            height={70}
            src={
              connector?.name === "MetaMask" ? metamaskIcon : walletconnectIcon
            }
            alt="metamask icon"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
          />
          <motion.img
            src={cursor}
            alt="cursor"
            width={20}
            height={20}
            initial={{ x: "-80px" }}
            animate={{ x: 0 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1.6,
            }}
          />
        </S.ClickAnimationWrapper>
      </AnimatePresence>
    </Modal>
  );
};

export default SwitchAccountAuth;

/* eslint-disable */
import * as S from "./DurationPicker.styled";

type DurationPickerProps = {
  value: Date[] | Date;
  error?: string;
  title?: string;
  onChange: any;
  selectsRange?: boolean;
};

export const DurationPicker = ({
  error,
  title,
  value,
  onChange,
  selectsRange,
}: DurationPickerProps) => (
  <S.Container>
    {title && <S.Title>{title}</S.Title>}
    <S.DatePickerWrapper
      onChange={onChange}
      error={error}
      selectsRange={selectsRange}
      selected={!Array.isArray(value) ? value : null}
      startDate={
        selectsRange && Array.isArray(value) && value ? value[0] : null
      }
      endDate={selectsRange && Array.isArray(value) && value ? value[1] : null}
      dateFormat="dd/MM/yyyy"
      placeholderText={
        selectsRange
          ? "Click to select a duration"
          : "Click to select a starting day"
      }
      minDate={new Date()}
      withPortal
    />
    <S.InfoWrapper>
      {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
    </S.InfoWrapper>
  </S.Container>
);

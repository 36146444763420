import { apiCall, apiCallWithAuthToken } from "./config";

export const initLogin = (walletAddress: string) =>
  apiCall.get<InitLoginResponse>(`auth/login/${walletAddress}`);

export const loginWithSignature = (
  walletAddress: string,
  signature: string,
  chainId: string
) => {
  const refCode = localStorage.getItem("refCode");
  return apiCall.post<LoginResponse>(
    `auth/login${refCode ? `?refcode=${refCode}` : ""}`,
    { walletAddress, signature, chainId }
  );
};

export const refreshAccessToken = (refreshToken: string) =>
  apiCall.post<JWTTokens>("auth/refresh", undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

export const getUser = () => apiCallWithAuthToken.get<UserResponse>("user/me");

export const logout = () => apiCallWithAuthToken.delete("auth/logout");

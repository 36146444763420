import styled from "styled-components";
import React from "react";
import { device } from "../../style";

interface SectionTitleWithLineProps {
  title: string | React.ReactNode;
}

export const SectionTitleWithLine = ({ title }: SectionTitleWithLineProps) => (
  <SectionTitle>
    {title}
    <Line />
  </SectionTitle>
);

const SectionTitle = styled.h2`
  color: #fff;

  font-family: Ingra;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%;
  position: relative;
  margin-bottom: 20px;

  span {
    color: #08a98a;
    font-weight: 700;
  }

  @media ${device.tablet} {
    font-size: 3rem;
  }
`;

const Line = styled.div`
  position: absolute;
  background: linear-gradient(
    270deg,
    #462373 0%,
    #1b4b57 44.79%,
    rgba(0, 207, 161, 0.68) 85.42%
  );
  bottom: -17px;
  height: 1px;
  width: 70px;
`;

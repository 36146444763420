import * as S from "./Logo.styled";
import { logo, adminLogo, smallLogo } from "../../../../assets";

interface LogoProps {
  isBigVariant?: boolean;
  isHugeVariant?: boolean;
  small?: boolean;
}

export const Logo = ({ isBigVariant, isHugeVariant, small }: LogoProps) =>
  small ? (
    <img src={smallLogo} alt="logo" style={{ maxHeight: "30px" }} />
  ) : (
    <S.Wrapper isBigVarian={isBigVariant} isHugeVariant={isHugeVariant}>
      <img src={logo} alt="logo" />
    </S.Wrapper>
  );

export const AdminLogo = () => (
  <S.Wrapper style={{ height: 50 }}>
    <img
      src={adminLogo}
      style={{ position: "relative", height: "auto" }}
      alt="logo"
    />
  </S.Wrapper>
);

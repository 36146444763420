import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import { Chain } from "wagmi/chains";
import {
  cursor,
  metamaskIcon,
  walletconnectIcon,
} from "../../../../../../assets";
import { Button, Modal } from "../../../../../../components";
import { configWagmi } from "../../../../../../context/WagmiProvider";
import {
  useMediaQuery,
  useModal,
  useUsdcTokenAddress,
} from "../../../../../../hooks";
import { actions, useAppDispatch } from "../../../../../../redux";
import { toast } from "../../../../../../toasts";
import * as S from "./SwitchNetwork.styled";

const SwitchNetwork = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();
  const { switchChain, isPending, isSuccess, isError, variables } =
    useSwitchChain();
  const { isConnected, address, connector, chainId } = useAccount();
  const dispatch = useAppDispatch();
  const usdcTokenAddress = useUsdcTokenAddress();

  useEffect(() => {
    if (isSuccess && chainId) {
      if (usdcTokenAddress && address)
        dispatch(actions.wallet.getBalances({ address, chainId }));
      toast.success(
        "Successful switch",
        "The network has been successfully switched"
      );
    }
    if (isError) {
      const networkName = configWagmi.chains?.find(
        (data: Chain) => data.id === variables?.chainId
      )?.name;
      toast.error(
        "Switch network unsuccessful",
        `Network switch ${networkName ? `to ${networkName}` : ""} failed`
      );
    }
  }, [isError, isSuccess]);

  const isMobileView = useMediaQuery("(max-width: 424px)");

  useEffect(() => {
    if (
      isConnected &&
      !configWagmi.chains?.some((data: Chain) => data.id === chainId) &&
      !isMobileView
    ) {
      handleModalOpen();
    } else if (
      isConnected &&
      configWagmi.chains?.some((data: Chain) => data.id === chainId)
    ) {
      handleModalClose();
    }
  }, [address, chainId]);

  const description = [
    "For better interaction with our app, we require the wallet to be connected to one of the supported networks, such as Polygon or Ethereum.",
    ...(isPending
      ? [
          connector?.name === "MetaMask"
            ? "Check the metamask extension in Your browser..."
            : "Check your wallet app, that You use with the walletConnect...",
        ]
      : []),
  ];

  return (
    <Modal
      title={["Invalid", "network detected"]}
      description={description}
      onClose={handleModalClose}
      isOpen={isModalOpen}
      withoutCloseIcon
    >
      <AnimatePresence>
        {isPending && (
          <S.ClickAnimationWrapper>
            <motion.img
              width={70}
              height={70}
              src={
                connector?.name === "MetaMask"
                  ? metamaskIcon
                  : walletconnectIcon
              }
              alt="metamask icon"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
            />
            <motion.img
              src={cursor}
              alt="cursor"
              width={20}
              height={20}
              initial={{ x: "-80px" }}
              animate={{ x: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 1.6,
              }}
            />
          </S.ClickAnimationWrapper>
        )}
      </AnimatePresence>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
        }}
      >
        {configWagmi.chains?.map((data: Chain, index: number) => (
          <Button.Secondary
            key={data.id}
            disabled={isPending}
            bigSize
            onClick={() => switchChain?.({ chainId: data.id })}
            style={{ marginTop: index === 0 ? "0px" : "12px" }}
          >
            Switch to {data.name}
          </Button.Secondary>
        ))}
      </div>
    </Modal>
  );
};

export default SwitchNetwork;

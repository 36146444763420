import qs from "qs";
import { apiCallWithAuthToken } from "./config";

export const disableUserTfa = (id: number) =>
  apiCallWithAuthToken.put(`user/admin/disable/tfaStatus/${id}`);

export const changeUserRole = (id: number, role: string) =>
  apiCallWithAuthToken.put(`user/admin/changeRole/${id}`, { role });

export const updateActive = (
  id: number,
  active: boolean,
  lockReason?: string
) =>
  apiCallWithAuthToken.put(`user/admin/update/active/${id}`, {
    active,
    lockReason,
  });

type KycItem = {
  createdAt: string;
  id: number;
  kycUserId: string;
  stateParam: string;
  status: string;
  updatedAt: string;
  email: string;
  phoneNumber: string;
  fullName: string;
};

type RoleType = {
  roleID: number;
  id: number;
  userID: number;
  chainID: number;
  role: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type GetUsersData = {
  id: number;
  email: string | null;
  walletAddress: string;
  userToRoles: Array<RoleType>;
  kyc: null | KycItem;
  isActive: boolean;
  tfaEnabled: boolean;
  tfaRecoveryCode: string | null;
  tfaSecret: string | null;
  createdAt: string;
  updatedAt: string;
  stakingNonce: string;
  poolsNonce: string;
  lockReason: string | null;
};

type FilteredKeys =
  | "id"
  | "email"
  | "walletAddress"
  | "userToRoles.role.name"
  | "userToRoles.chainId"
  | "tfaEnabled"
  | "isActive"
  | "createdAt"
  | "updatedAt";

type GetUsersResponse = {
  data: GetUsersData[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type PoolActionType = {
  action: string;
  amount: string;
  balanceAfter: string;
  fee: string;
  createdAt: string;
  end: string;
  id: number;
  start: string;
  txBlock: string;
  txHash: string;
  txIndex: string;
  updatedAt: string;
};

export type GetLoanAgreementsData = {
  createdAt: string;
  filename: string;
  id: number;
  updatedAt: string;
  poolAction: PoolActionType;
  user: GetUsersData;
};

type GetUserLoanAgreementsResponse = {
  data: GetLoanAgreementsData[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUsers = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUsersResponse>(`user?${queryString}`);
};

export const getUsersCSV = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<Blob>(`user/export-csv?${queryString}`);
};

export const getUserLoanAgreements = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserLoanAgreementsResponse>(
    `user/loan-agreements?${queryString}`
  );
};

export const downloadLoanAgreement = (id: number) =>
  apiCallWithAuthToken.get(`user/loan-agreements/${id}`, {
    responseType: "blob",
  });

export const getAllLoanAgreements = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserLoanAgreementsResponse>(
    `loan-agreement?${queryString}`
  );
};

export const downloadLoanAgreementAdmin = (id: number) =>
  apiCallWithAuthToken.get(`loan-agreement/${id}`, { responseType: "blob" });

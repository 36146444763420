import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { config } from "../../../../config";
import { useAppSelector } from "../../../../redux";
import { isAdminRole } from "../../../../utils";
import { routes } from "../../../../utils/routes";
import * as S from "./ToolBar.styled";
import AdminToolBar from "./components/AdminToolBar/AdminToolBar";
import UserToolBar from "./components/UserToolBar/UserToolBar";

const ToolBar = () => {
  const roles = useAppSelector((state) => state?.user?.userData?.userToRoles);
  const { chainId } = useAccount();
  const isAdmin = isAdminRole(roles, chainId || config.NETWORK_ID);
  const navigate = useNavigate();
  const isNewNotification = false;

  const handleNotificationClick = () => {
    navigate(routes.userPanel.notifications);
  };

  return (
    <S.ToolBarWrapper>
      {isAdmin ? (
        <AdminToolBar
          isNewNotification={isNewNotification}
          handleNotificationClick={handleNotificationClick}
        />
      ) : (
        <UserToolBar
          isNewNotification={isNewNotification}
          handleNotificationClick={handleNotificationClick}
        />
      )}
    </S.ToolBarWrapper>
  );
};

export default ToolBar;

import styled from "styled-components";
import { Typography } from "../Typography/Typography";

interface StatusParagraphProps {
  isDone: boolean;
  text: string;
}

export const StatusParagraph = ({ isDone, text }: StatusParagraphProps) => (
  <OnboardingStatus>
    <StatusCircle isDone={isDone} />
    <Typography.IngraTitle
      isWhite
      fontWeight={300}
      opacity={isDone ? 1 : 0.3}
      style={{ color: "#FFF" }}
    >
      {text}
    </Typography.IngraTitle>
  </OnboardingStatus>
);

const StatusCircle = styled.div<{ isDone?: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${({ isDone }) => (isDone ? "#08A98A" : "#FFF")};
  position: relative;
  opacity: ${({ isDone }) => (isDone ? 1 : 0.3)};

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10.83px;
    height: 10.83px;
    border-radius: 50%;
    background-color: ${({ isDone }) => (isDone ? "#08A98A" : "transparent")};
    display: block;
    margin: auto;
  }
`;

const OnboardingStatus = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
`;

import { BigNumber } from "ethers";
import { secureIcon } from "../../../assets";
import { config } from "../../../config";
import { useAppSelector } from "../../../redux";
import { bpsToPercent, formatBigNumber } from "../../../utils";
import * as S from "./StakeDetails.styled";

type StakeDetailsProps = {
  amountToStake: BigNumber;
};

export const StakeDetails = ({ amountToStake }: StakeDetailsProps) => {
  const { feeUSDC, feeSOIL, apr } = useAppSelector((store) => store.staking);

  return (
    <S.List>
      <S.ListItem>
        <img src={secureIcon} alt="Secure" />
        <S.ListContent>
          Current <strong>APR</strong> is: <strong>{bpsToPercent(apr)}%</strong>
        </S.ListContent>
      </S.ListItem>
      <S.ListItem>
        <img src={secureIcon} alt="Secure" />
        <S.ListContent>
          Transaction fee:{" "}
          {formatBigNumber(feeSOIL, config.SOIL_TOKEN_DECIMALS, 2)} $SOIL (
          {formatBigNumber(feeUSDC, config.USDC_TOKEN_DECIMALS, 2)} USDC)
        </S.ListContent>
      </S.ListItem>
      <S.ListItem>
        <img src={secureIcon} alt="Secure" />
        <S.ListContent>
          Grew amount:{" "}
          {Number(amountToStake) > 0
            ? formatBigNumber(amountToStake, config.SOIL_TOKEN_DECIMALS, 6)
            : "0.00"}
        </S.ListContent>
      </S.ListItem>
    </S.List>
  );
};

import { NavIconProps, NavIconVariant } from ".";

export const GrowIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.68371 11.1833H4.69873L4.71371 11.1824C4.7543 11.18 4.79495 11.1862 4.83296 11.2006C4.87098 11.215 4.9055 11.2373 4.93426 11.2661L5.28515 10.9152L4.93426 11.2661C4.96301 11.2948 4.98533 11.3294 4.99976 11.3674L5.4641 11.1912L4.99976 11.3674C5.01419 11.4054 5.02038 11.446 5.01794 11.4866L5.01704 11.5016V11.5166V16.5166V16.5317L5.01794 16.5466C5.02038 16.5872 5.01419 16.6279 4.99976 16.6659L5.46724 16.8433L4.99976 16.6659C4.98533 16.7039 4.96301 16.7384 4.93426 16.7672C4.90551 16.7959 4.87098 16.8183 4.83296 16.8327C4.79494 16.8471 4.7543 16.8533 4.71371 16.8509L4.69873 16.85H4.68371H1.35038H1.33536L1.32038 16.8509C1.27979 16.8533 1.23915 16.8471 1.20113 16.8327C1.16311 16.8183 1.12858 16.7959 1.09983 16.7672C1.07108 16.7384 1.04875 16.7039 1.03433 16.6659C1.0199 16.6279 1.0137 16.5872 1.01614 16.5466L1.01704 16.5317V16.5166V11.5166V11.5016L1.01614 11.4866C1.0137 11.446 1.0199 11.4054 1.03433 11.3674C1.04875 11.3294 1.07108 11.2948 1.09983 11.2661C1.12859 11.2373 1.16311 11.215 1.20113 11.2006C1.23914 11.1862 1.27979 11.18 1.32038 11.1824L1.33536 11.1833H1.35038H4.68371Z"
            stroke="#08A98A"
          />
          <path
            d="M12.1837 6.1833H12.1987L12.2137 6.1824C12.2543 6.17996 12.2949 6.18616 12.333 6.20059C12.371 6.21501 12.4055 6.23734 12.4343 6.26609L12.7852 5.9152L12.4343 6.26609C12.463 6.29485 12.4853 6.32937 12.4998 6.36739C12.5142 6.4054 12.5204 6.44605 12.5179 6.48663L12.517 6.50162V6.51664V16.5166V16.5317L12.5179 16.5466C12.5204 16.5872 12.5142 16.6279 12.4998 16.6659C12.4853 16.7039 12.463 16.7384 12.4343 16.7672L12.7878 17.1207L12.4343 16.7672C12.4055 16.7959 12.371 16.8183 12.333 16.8327C12.2949 16.8471 12.2543 16.8533 12.2137 16.8509L12.1987 16.85H12.1837H8.85038H8.83536L8.82038 16.8509C8.77979 16.8533 8.73914 16.8471 8.70113 16.8327C8.66311 16.8183 8.62858 16.7959 8.59983 16.7672C8.57108 16.7384 8.54875 16.7039 8.53433 16.6659C8.5199 16.6279 8.5137 16.5872 8.51614 16.5466L8.51704 16.5317V16.5166V6.51664V6.50162L8.51614 6.48664C8.5137 6.44605 8.5199 6.4054 8.53433 6.36739C8.54875 6.32937 8.57108 6.29485 8.59983 6.26609C8.62859 6.23734 8.66311 6.21501 8.70113 6.20059C8.73914 6.18616 8.77979 6.17996 8.82038 6.1824L8.83536 6.1833H8.85038H12.1837Z"
            stroke="#08A98A"
          />
          <path
            d="M19.6837 1.1833H19.6987L19.7137 1.1824C19.7543 1.17996 19.7949 1.18616 19.833 1.20059C19.871 1.21501 19.9055 1.23734 19.9343 1.26609L20.2865 0.913833L19.9343 1.26609C19.963 1.29484 19.9853 1.32937 19.9998 1.36739C20.0142 1.4054 20.0204 1.44605 20.0179 1.48663L20.017 1.50162V1.51664V16.5166V16.5317L20.0179 16.5466C20.0204 16.5872 20.0142 16.6279 19.9998 16.6659L20.4672 16.8433L19.9998 16.6659C19.9853 16.7039 19.963 16.7384 19.9343 16.7672C19.9055 16.7959 19.871 16.8183 19.833 16.8327C19.7949 16.8471 19.7543 16.8533 19.7137 16.8509L19.6987 16.85H19.6837H16.3504H16.3354L16.3204 16.8509C16.2798 16.8533 16.2391 16.8471 16.2011 16.8327C16.1631 16.8183 16.1286 16.7959 16.0998 16.7672C16.0711 16.7384 16.0488 16.7039 16.0343 16.6659L15.6264 16.8207L16.0343 16.6659C16.0199 16.6279 16.0137 16.5872 16.0161 16.5466L16.017 16.5317V16.5166V1.51664V1.50162L16.0161 1.48664C16.0137 1.44605 16.0199 1.4054 16.0343 1.36739C16.0488 1.32937 16.0711 1.29484 16.0998 1.26609C16.1286 1.23734 16.1631 1.21501 16.2011 1.20059C16.2391 1.18616 16.2798 1.17996 16.3204 1.1824L16.3354 1.1833H16.3504H19.6837Z"
            stroke="#08A98A"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.68371 11.1833H4.69873L4.71371 11.1824C4.7543 11.18 4.79495 11.1862 4.83296 11.2006C4.87098 11.215 4.9055 11.2373 4.93426 11.2661L5.28515 10.9152L4.93426 11.2661C4.96301 11.2948 4.98533 11.3294 4.99976 11.3674L5.4641 11.1912L4.99976 11.3674C5.01419 11.4054 5.02038 11.446 5.01794 11.4866L5.01704 11.5016V11.5166V16.5166V16.5317L5.01794 16.5466C5.02038 16.5872 5.01419 16.6279 4.99976 16.6659L5.46724 16.8433L4.99976 16.6659C4.98533 16.7039 4.96301 16.7384 4.93426 16.7672C4.90551 16.7959 4.87098 16.8183 4.83296 16.8327C4.79494 16.8471 4.7543 16.8533 4.71371 16.8509L4.69873 16.85H4.68371H1.35038H1.33536L1.32038 16.8509C1.27979 16.8533 1.23915 16.8471 1.20113 16.8327C1.16311 16.8183 1.12858 16.7959 1.09983 16.7672C1.07108 16.7384 1.04875 16.7039 1.03433 16.6659C1.0199 16.6279 1.0137 16.5872 1.01614 16.5466L1.01704 16.5317V16.5166V11.5166V11.5016L1.01614 11.4866C1.0137 11.446 1.0199 11.4054 1.03433 11.3674C1.04875 11.3294 1.07108 11.2948 1.09983 11.2661C1.12859 11.2373 1.16311 11.215 1.20113 11.2006C1.23914 11.1862 1.27979 11.18 1.32038 11.1824L1.33536 11.1833H1.35038H4.68371Z"
            stroke="#959595"
          />
          <path
            d="M12.1837 6.1833H12.1987L12.2137 6.1824C12.2543 6.17996 12.2949 6.18616 12.333 6.20059C12.371 6.21501 12.4055 6.23734 12.4343 6.26609L12.7852 5.9152L12.4343 6.26609C12.463 6.29485 12.4853 6.32937 12.4998 6.36739C12.5142 6.4054 12.5204 6.44605 12.5179 6.48663L12.517 6.50162V6.51664V16.5166V16.5317L12.5179 16.5466C12.5204 16.5872 12.5142 16.6279 12.4998 16.6659C12.4853 16.7039 12.463 16.7384 12.4343 16.7672L12.7878 17.1207L12.4343 16.7672C12.4055 16.7959 12.371 16.8183 12.333 16.8327C12.2949 16.8471 12.2543 16.8533 12.2137 16.8509L12.1987 16.85H12.1837H8.85038H8.83536L8.82038 16.8509C8.77979 16.8533 8.73914 16.8471 8.70113 16.8327C8.66311 16.8183 8.62858 16.7959 8.59983 16.7672C8.57108 16.7384 8.54875 16.7039 8.53433 16.6659C8.5199 16.6279 8.5137 16.5872 8.51614 16.5466L8.51704 16.5317V16.5166V6.51664V6.50162L8.51614 6.48664C8.5137 6.44605 8.5199 6.4054 8.53433 6.36739C8.54875 6.32937 8.57108 6.29485 8.59983 6.26609C8.62859 6.23734 8.66311 6.21501 8.70113 6.20059C8.73914 6.18616 8.77979 6.17996 8.82038 6.1824L8.83536 6.1833H8.85038H12.1837Z"
            stroke="#959595"
          />
          <path
            d="M19.6837 1.1833H19.6987L19.7137 1.1824C19.7543 1.17996 19.7949 1.18616 19.833 1.20059C19.871 1.21501 19.9055 1.23734 19.9343 1.26609L20.2865 0.913833L19.9343 1.26609C19.963 1.29484 19.9853 1.32937 19.9998 1.36739C20.0142 1.4054 20.0204 1.44605 20.0179 1.48663L20.017 1.50162V1.51664V16.5166V16.5317L20.0179 16.5466C20.0204 16.5872 20.0142 16.6279 19.9998 16.6659L20.4672 16.8433L19.9998 16.6659C19.9853 16.7039 19.963 16.7384 19.9343 16.7672C19.9055 16.7959 19.871 16.8183 19.833 16.8327C19.7949 16.8471 19.7543 16.8533 19.7137 16.8509L19.6987 16.85H19.6837H16.3504H16.3354L16.3204 16.8509C16.2798 16.8533 16.2391 16.8471 16.2011 16.8327C16.1631 16.8183 16.1286 16.7959 16.0998 16.7672C16.0711 16.7384 16.0488 16.7039 16.0343 16.6659L15.6264 16.8207L16.0343 16.6659C16.0199 16.6279 16.0137 16.5872 16.0161 16.5466L16.017 16.5317V16.5166V1.51664V1.50162L16.0161 1.48664C16.0137 1.44605 16.0199 1.4054 16.0343 1.36739C16.0488 1.32937 16.0711 1.29484 16.0998 1.26609C16.1286 1.23734 16.1631 1.21501 16.2011 1.20059C16.2391 1.18616 16.2798 1.17996 16.3204 1.1824L16.3354 1.1833H16.3504H19.6837Z"
            stroke="#959595"
          />
        </svg>
      );
  }
};

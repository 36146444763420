import { AnimatePresence } from "framer-motion";
import { useAccount } from "wagmi";
import { TermsOfService } from "../../../../components";
import { config } from "../../../../config";
import { useMediaQuery } from "../../../../hooks";
import { useAppSelector } from "../../../../redux/hooks";
import { device } from "../../../../style";
import { isAdminRole } from "../../../../utils";
import { AdminLogo, Logo } from "../Logo/Logo";
import { AdminLinks, UserLinks } from "./components";
import * as S from "./Nav.styled";

export type NavProps = {
  toggleMobileNav?: () => void;
};

export const Nav = ({ toggleMobileNav }: NavProps) => {
  const roles = useAppSelector((state) => state?.user?.userData?.userToRoles);
  const { chainId } = useAccount();
  const isAdmin = isAdminRole(roles, chainId || config.NETWORK_ID);
  const isDesktopView = useMediaQuery(device.laptop);

  return (
    <AnimatePresence>
      <S.Wrapper>
        {isAdmin ? <AdminLogo /> : isDesktopView ? <Logo /> : null}
        <S.NavWrapper isAdmin={isAdmin}>
          {isAdmin ? (
            <AdminLinks toggleMobileNav={toggleMobileNav} />
          ) : (
            <UserLinks toggleMobileNav={toggleMobileNav} />
          )}
        </S.NavWrapper>
        {!isAdmin && <TermsOfService />}
      </S.Wrapper>
    </AnimatePresence>
  );
};

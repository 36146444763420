import styled from "styled-components";
import { ethereumLogo, polygonLogo } from "../../assets";

export const SmallChainsIcons = () => (
  <IconsWrapper>
    <img src={polygonLogo} alt="Polygon" />
    <img src={ethereumLogo} alt="Ethereum" />
  </IconsWrapper>
);

const IconsWrapper = styled.div`
  display: flex;
  gap: 0;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28.74px;
    height: 28.74px;
  }

  img:nth-child(2) {
    margin-left: -7px;
  }
`;

import { NavIconProps, NavIconVariant } from ".";

export const SwapIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4063 5.01641L1.40642 5.01636L1.40625 7.01636L2.74623 7.01648L13.4063 7.01641V5.01641Z"
            fill="#08A98A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.40625 15.0166H19.4062V17.0166H7.40625V15.0166Z"
            fill="#08A98A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.82063 2.0165L2.8206 6.01657L6.82063 10.0166L5.4064 11.4308L-0.0078125 6.01657L5.40641 0.602295L6.82063 2.0165Z"
            fill="#08A98A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4064 10.6023L20.8206 16.0165L15.4065 21.4307L13.9922 20.0165L17.9922 16.0165L13.9922 12.0164L15.4064 10.6023Z"
            fill="#08A98A"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9298 5.01641L1.92986 5.01636L1.92969 7.01636L3.26967 7.01648L13.9298 7.01641V5.01641Z"
            fill="#959595"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.92969 15.0166H19.9297V17.0166H7.92969V15.0166Z"
            fill="#959595"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.34406 2.0165L3.34403 6.01657L7.34406 10.0166L5.92984 11.4308L0.515625 6.01657L5.92984 0.602295L7.34406 2.0165Z"
            fill="#959595"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9298 10.6023L21.344 16.0165L15.9299 21.4307L14.5156 20.0165L18.5156 16.0165L14.5156 12.0164L15.9298 10.6023Z"
            fill="#959595"
          />
        </svg>
      );
  }
};

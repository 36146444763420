import { ethers } from "ethers";
import { useState } from "react";
import styled from "styled-components";
import { inputSoilIconBlack } from "../../../assets";
import { Button, CurrencyInput, MBC, SmallSpinner } from "../../../components";
import { config } from "../../../config";
import { stakingService } from "../../../services";
import { toast } from "../../../toasts";
import { getErrorMessage, useEthersSigner } from "../../../utils";

export const SubmitButton = styled(Button.Primary)`
  width: 100%;
  margin-top: 10px;
`;

type EditRestakeLimitFormProps = {
  closeModal: () => void;
  value: string;
  update: () => Promise<void>;
};

export const EditRestakeLimitForm = ({
  closeModal,
  value,
  update,
}: EditRestakeLimitFormProps) => {
  const signer = useEthersSigner();
  const [newLimit, setNewLimit] = useState(value.toString());
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (signer) {
        await stakingService.changeRestakingRewardsLimit(
          signer,
          ethers.utils
            .parseUnits(newLimit, config.SOIL_TOKEN_DECIMALS)
            .add(ethers.utils.parseUnits("1", "wei"))
        );
        toast.success("Updated", `Staking - restake limit has been updated`);
      } else {
        toast.error("Error", "User doesn't have a wallet connected");
      }
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
    await update();
    closeModal();
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLimit(event.target.value);
  };

  return isLoading ? (
    <SmallSpinner />
  ) : (
    <MBC.Form onSubmit={handleSubmit}>
      <CurrencyInput
        label="SOIL"
        name="SOIL"
        decimals={config.SOIL_TOKEN_DECIMALS}
        logo={inputSoilIconBlack}
        onErrorChange={setError}
        value={newLimit}
        onChange={handleValueChange}
      />
      <SubmitButton disabled={error} type="submit">
        Submit
      </SubmitButton>
    </MBC.Form>
  );
};

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { chartsUp } from "../../../../../../assets";
import { KycNewStatus, SmallSpinner } from "../../../../../../components";
import { ConnectWalletButton } from "../../../../../../features";
import { useMediaQuery } from "../../../../../../hooks";
import { useAppSelector } from "../../../../../../redux";
import { device } from "../../../../../../style";
import { defaultHomeRoute, routes } from "../../../../../../utils/routes";
import HamburgerIcon from "../../../Icons/HamburgerIcon";
import NotificationsIcon from "../../../Icons/NotificationsIcon";
import {
  MobileNavBack,
  MobileNavWrapper,
} from "../../../MobileNav/MobileNav.styled";
import { Nav } from "../../../Nav/Nav";
import * as S from "../../ToolBar.styled";
import { NetworkSwitcher } from "../NetworkSwitcher/NetworkSwitcher";
import UserTool from "../UserTool/UserTool";
import WalletTool from "../WalletTool/WalletTool";

const UserToolBar = ({
  isNewNotification,
  handleNotificationClick,
}: S.ToolBarProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const kycStatus = useAppSelector((state) => state.user.userData.kyc?.status);
  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const [isAvailableConnectWalletButton, setIsAvailableConnectWalletButton] =
    useState(!isConnected);
  const isDesktopView = useMediaQuery(device.laptop);
  const isTabletView = useMediaQuery(device.tablet);
  const { pathname } = useLocation();

  const { userData } = useAppSelector((state) => state.user);
  const authLoading = useAppSelector((state) => state.user.loading);

  const [isWalletDropdownVisible, setIsWalletDropdownVisible] = useState(false);
  const [isNetworkSwitcherVisible, setIsNetworkSwitcherVisible] =
    useState(false);
  const [isUserDropdownVisible, setIsUserDropdownVisible] = useState(false);

  const isKycInProgress = kycStatus === "started" || kycStatus === "pending";

  const toggleWalletDropdown = () => {
    setIsUserDropdownVisible(false);
    setIsNetworkSwitcherVisible(false);
    setIsWalletDropdownVisible(!isWalletDropdownVisible);
  };

  const toggleUserDropdown = () => {
    setIsWalletDropdownVisible(false);
    setIsNetworkSwitcherVisible(false);
    setIsUserDropdownVisible(!isUserDropdownVisible);
  };

  const toggleNetworkSwitcher = () => {
    setIsWalletDropdownVisible(false);
    setIsUserDropdownVisible(false);
    setIsNetworkSwitcherVisible(!isNetworkSwitcherVisible);
  };

  const handleNavigateToKyc = () => navigate(routes.userPanel.kyc);
  const handleClickHamburger = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleClickMenuElement = () => {
    setIsMenuVisible(false);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? "hidden" : "auto";
  }, [isMenuVisible]);

  useEffect(() => {
    if (isConnected) {
      setIsAvailableConnectWalletButton(false);
    } else {
      setIsAvailableConnectWalletButton(true);
    }
  }, [isConnected]);

  return (
    <>
      <NetworkSwitcher
        isOpen={isNetworkSwitcherVisible}
        toggleVisible={toggleNetworkSwitcher}
      />
      {isAvailableConnectWalletButton &&
        (pathname === defaultHomeRoute ? (
          <ConnectWrapper>
            <TextIconWrapper>
              <ConnectIcon src={chartsUp} alt="connect" />
              <ConnectText>
                Connect Your Wallet <span>to Start Earning</span>
              </ConnectText>
            </TextIconWrapper>
            <ConnectWalletButton small moreRadius width="191px" height="40px" />
          </ConnectWrapper>
        ) : (
          <ConnectWalletButton small moreRadius width="191px" height="40px" />
        ))}
      {isConnected ? (
        <>
          {isKycInProgress && isTabletView && (
            <KycNewStatus onClick={handleNavigateToKyc} />
          )}
          {/* <NetworkSwitcher
            isOpen={isNetworkSwitcherVisible}
            toggleVisible={toggleNetworkSwitcher}
          /> */}
          <WalletTool
            toggleVisible={toggleWalletDropdown}
            isOpen={isWalletDropdownVisible}
            isLogged={isLogged}
          />
        </>
      ) : null}
      {authLoading && !userData ? (
        <SmallSpinner />
      ) : isLogged ? (
        <>
          <NotificationsIcon
            isNewNotification={isNewNotification}
            isToolbar
            click={handleNotificationClick}
          />
          <UserTool
            toggleVisible={toggleUserDropdown}
            isOpen={isUserDropdownVisible}
          />
          {!isDesktopView ? (
            <MobileNavWrapper isConnected={isConnected}>
              <HamburgerIcon
                click={handleClickHamburger}
                isOpen={isMenuVisible}
              />
              <AnimatePresence>
                {isMenuVisible && (
                  <MobileNavBack
                    onClick={() => setIsMenuVisible(false)}
                    as={motion.div}
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <motion.div
                      key="nav"
                      style={{ height: "100%" }}
                      animate={{ x: 0 }}
                      initial={{ x: "100%" }}
                      exit={{ x: "100%" }}
                      transition={{ duration: 0.2 }}
                    >
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div onClick={handleMenuClick}>
                        <Nav toggleMobileNav={handleClickMenuElement} />
                      </div>
                    </motion.div>
                  </MobileNavBack>
                )}
              </AnimatePresence>
            </MobileNavWrapper>
          ) : null}
        </>
      ) : !isDesktopView ? (
        <MobileNavWrapper>
          <HamburgerIcon click={handleClickHamburger} isOpen={isMenuVisible} />
          <AnimatePresence>
            {isMenuVisible && (
              <MobileNavBack
                onClick={() => setIsMenuVisible(false)}
                as={motion.div}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <motion.div
                  key="nav"
                  style={{ height: "100%" }}
                  animate={{ x: 0 }}
                  initial={{ x: "100%" }}
                  exit={{ x: "100%" }}
                  transition={{ duration: 0.2 }}
                >
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div onClick={handleMenuClick}>
                    <Nav toggleMobileNav={handleClickMenuElement} />
                  </div>
                </motion.div>
              </MobileNavBack>
            )}
          </AnimatePresence>
        </MobileNavWrapper>
      ) : null}
    </>
  );
};

export default UserToolBar;

const ConnectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
`;

const ConnectText = styled.p`
  font-family: Ingra;
  font-size: 12px;
  font-weight: 700;
  line-height: 11.22px;
  text-align: left;
  color: #fff;
  white-space: nowrap;

  span {
    font-weight: 500;
  }
`;

const TextIconWrapper = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
`;

const ConnectIcon = styled.img`
  height: 22px;
  width: 22px;
`;

import React from "react";
import * as S from "./SectionTitle.styled";

interface SectionTitleProps {
  titleBgText: string;
  title: React.ReactNode;
}

export const SectionTitle = ({ title, titleBgText }: SectionTitleProps) => (
  <S.SectionTitleWrapper>
    <S.Module>
      <h2>{titleBgText.toUpperCase()}</h2>
    </S.Module>
    <S.InsideText>{title}</S.InsideText>
  </S.SectionTitleWrapper>
);

import { ComponentPropsWithoutRef, forwardRef } from "react";
import * as S from "./Select.styled";

type SelectProps = {
  options: Array<{
    label: string;
    value: string | number;
    disabled?: boolean;
  }>;
  title: string;
} & ComponentPropsWithoutRef<"select">;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const { options, title } = props;

    return (
      <S.SelectWrapper>
        <S.SelectTitle>{title}</S.SelectTitle>
        <S.Select ref={ref} {...props}>
          {options.map((option) => (
            <option
              key={option.value}
              disabled={option.disabled}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </S.Select>
      </S.SelectWrapper>
    );
  }
);

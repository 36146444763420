import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";

dayjs.extend(Duration);

export const humanizePoolDuration = (
  timestamp: string | number,
  unit: Duration.DurationUnitType = "seconds"
) => {
  const duration = dayjs.duration(Math.abs(Number(timestamp)), unit);

  const months = Math.floor(duration.asMonths());
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let output = "";

  if (months > 0) {
    output += `${months} ${months === 1 ? "month" : "months"} `;
  }

  if (days > 0) {
    output += `${days} ${days === 1 ? "day" : "days"} `;
  }

  if (hours > 0) {
    output += `${hours} ${hours === 1 ? "hour" : "hours"} `;
  }

  if (minutes > 0) {
    output += `${minutes} ${minutes === 1 ? "minute" : "minutes"} `;
  }

  if (seconds > 0) {
    output += `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  }

  return output.trim();
};

import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Checkmark, Input, Label } from "./Checkbox.styled";

type BaseInputProps = ComponentPropsWithoutRef<"input">;

interface CheckboxProps extends BaseInputProps {
  error?: boolean;
  withBorder?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props) => {
  const { className, error, withBorder, ...restProps } = props;
  return (
    <Label className={className}>
      <Input type="checkbox" {...restProps} />
      <Checkmark error={error} withBorder={withBorder} />
    </Label>
  );
});

import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { cursor, metamaskIcon, walletconnectIcon } from "../../assets";
import { useOnboarding } from "../../features/Onboarding/useOnboarding";

export const SignMessageBody = () => {
  const { connector } = useAccount();
  const { activeStepIndex } = useOnboarding();

  const fadeInOutVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  return (
    <SignMessageBodyWrapper
      key={activeStepIndex}
      initial={activeStepIndex === 0 ? "visible" : "hidden"}
      animate="visible"
      exit={activeStepIndex === 0 ? "visible" : "exit"}
      variants={fadeInOutVariants}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence>
        <ClickAnimationWrapper key="noAuthAnimation">
          <motion.img
            width={70}
            height={70}
            src={
              connector?.name === "MetaMask" ? metamaskIcon : walletconnectIcon
            }
            alt="metamask icon"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
          />
          <motion.img
            src={cursor}
            alt="cursor"
            width={20}
            height={20}
            initial={{ x: "-80px" }}
            animate={{ x: 0 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1.6,
            }}
          />
        </ClickAnimationWrapper>
      </AnimatePresence>
    </SignMessageBodyWrapper>
  );
};

const SignMessageBodyWrapper = styled(motion.div)``;

const ClickAnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;
`;

import styled, { keyframes } from "styled-components";
import { useAppSelector } from "../../redux";
import { Typography } from "../Typography/Typography";
import { deviceMax } from "../../style/media";
import { useMediaQuery } from "../../hooks";

interface KycNewStatusProps {
  isDetailedVariant?: boolean;
  onClick?: () => void;
}

export const KycNewStatus = ({
  isDetailedVariant,
  onClick,
}: KycNewStatusProps) => {
  const kycStatus = useAppSelector((state) => state.user.userData?.kyc?.status);
  const isMobileView = useMediaQuery(deviceMax.laptop);

  const isInProgressKyc = kycStatus === "started" || kycStatus === "pending";

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const kycStatusText = () => {
    switch (kycStatus) {
      case "approved":
        return "KYC Approved";
      case "rejected":
        return "KYC Rejected";
      case "started":
        return "KYC In progress";
      case "pending":
        return "KYC In progress";
      default:
        return "Not started";
    }
  };

  const kycDetailedStatusText = () => {
    switch (kycStatus) {
      case "approved":
        return "KYC verification approved";
      case "rejected":
        return "KYC verification rejected";
      case "started":
        return "KYC verification in progress";
      case "pending":
        return "KYC verification in progress";
      default:
        return "KYC verification not started";
    }
  };

  return (
    <StatusWrapper onClick={handleOnClick}>
      {isInProgressKyc && <SmallSpinner />}
      <Typography.IngraLabel
        style={{ color: "#08A98A", fontSize: isMobileView ? "16px" : "14px" }}
        isWhite
        fontWeight={300}
        opacity={1}
      >
        {isDetailedVariant ? kycDetailedStatusText() : kycStatusText()}
      </Typography.IngraLabel>
    </StatusWrapper>
  );
};

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #08a98a33;
  padding: 6px 13px;
  border-radius: 16px;
  gap: 14px;
  width: fit-content;
  cursor: pointer;
`;

const SmallSpinner = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #08a98a33;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 1px solid #08a98a;
    animation: ${spin} 1s linear infinite;
  }
`;

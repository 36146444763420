import styled from "styled-components";
import { selectArrow } from "../../../assets";
import { Button } from "../../../components";

export const SubmitButton = styled(Button.Secondary)`
  width: 100%;
  margin-top: 10px;
`;

export const FilterSelect = styled.select<{ paddingLeft?: string }>`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 8px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "10px")};
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #000;
  border-radius: 8px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontStyles.headerSmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.headerSmall.lineHeight};
  letter-spacing: 0.04em;
  color: #000;
  appearance: none;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.5em;
  cursor: pointer;
  option {
    color: black;
    background: transparent;
    display: flex;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const SelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  createRefcode,
  getUserRefcode,
  getUserReferralStats,
} from "../../api/referrals";

type ReferralDataType = {
  id: number | null;
  code: string | null;
  isLoading: boolean;
  referredTotal: number;
  totalEarnings: string;
  unpaidEarnings: string;
  paidEarnings: string;
};

export const createReflink = createAsyncThunk(
  "referrals/createReflinks",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await createRefcode();
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data.message);
      }
    }
  }
);

export const getReflink = createAsyncThunk(
  "referrals/getReflinks",
  async () => {
    const { data } = await getUserRefcode();
    return data;
  }
);

export const getReferralStats = createAsyncThunk(
  "referrals/getReferralStats",
  async () => {
    const { data } = await getUserReferralStats();
    return data;
  }
);

const initialState: ReferralDataType = {
  id: null,
  code: null,
  isLoading: false,
  referredTotal: 0,
  totalEarnings: "0",
  unpaidEarnings: "0",
  paidEarnings: "0",
};

export const referralsSlice = createSlice({
  name: "referrals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createReflink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createReflink.fulfilled, (state, { payload }) => {
      if (payload?.id && payload?.code) {
        state.id = payload.id;
        state.code = payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(createReflink.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getReflink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReflink.fulfilled, (state, { payload }) => {
      state.id = payload.id;
      state.code = payload.code;
      state.isLoading = false;
    });
    builder.addCase(getReflink.rejected, (state) => {
      state.isLoading = false;
      state.code = null;
    });
    builder.addCase(getReferralStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReferralStats.fulfilled, (state, { payload }) => {
      state.referredTotal = payload.referredTotal;
      state.totalEarnings = payload.totalEarnings;
      state.unpaidEarnings = payload.unpaidEarnings;
      state.paidEarnings = payload.paidEarnings;
      state.isLoading = false;
    });
    builder.addCase(getReferralStats.rejected, (state) => {
      state.isLoading = false;
      state.referredTotal = 0;
      state.totalEarnings = "0";
      state.unpaidEarnings = "0";
      state.paidEarnings = "0";
    });
  },
});

export default referralsSlice.reducer;

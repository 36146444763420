import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

   // CSS Reset to reduce browsers inconsistencies
   html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smooth: never;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    font-family: "ingra";
    /* overflow: hidden !important; */

    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }

  wcm-modal {
    z-index: 1000;
  }

  .date_picker {
    z-index: 99999;
  }

  .react-datepicker__month-container {
  height: 420px; /* Stała wysokość */
  overflow: hidden; /* Ukryj nadmiar treści */
}

  input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

  .fullWidth {
    width: 100%;
  }

  .two-factor-input {
    background-color: rgba(139, 87, 250, 0.32);
    will-change: auto;
    position: relative;
  }

  .module {
    container-type: inline-size;
  }

  @container sidebar (min-width: 700px) {
    .module h2 {
      font-size: 2em;
    }
  }
`;

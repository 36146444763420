import styled from "styled-components";
import { Typography } from "../../../../components";

export const Text = styled(Typography.Body)`
  font-weight: bold;
`;

export const Container = styled.form`
  display: grid;
  gap: 16px;

  p {
    color: #000;
  }
`;

export const Link = styled.a`
  font-size: ${({ theme }) => theme.fontStyles.body.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.body.letterSpacing};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Chain, getSupportedChains } from "../../api/chains";
import { getSettings } from "../../api/settings";

type settingsArrayData = {
  id: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  value: string;
};

interface settingsState {
  settingsArray: settingsArrayData[];
  loading: boolean;
  supportedChains: Chain[] | [];
  chainsLoading: boolean;
}

export const getSettingsData = createAsyncThunk(
  "settings/getSettings",
  async () => {
    const response = await getSettings();
    return response.data;
  }
);

export const getSupportedChainsData = createAsyncThunk(
  "settings/getSupportedChains",
  async () => {
    const response = await getSupportedChains();
    return response.data;
  }
);

export const refreshSettingsData = createAsyncThunk(
  "settings/refreshSettings",
  async () => {
    const response = await getSettings();
    return response.data;
  }
);

const initialState: settingsState = {
  settingsArray: [{ id: 0, createdAt: "", updatedAt: "", key: "", value: "" }],
  loading: false,
  supportedChains: [],
  chainsLoading: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettingsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSettingsData.fulfilled, (state, { payload }) => {
      state.settingsArray = payload;
      state.loading = false;
    });
    builder.addCase(getSettingsData.rejected, (state) => {
      state.loading = false;
      state.settingsArray = initialState.settingsArray;
    });
    builder.addCase(refreshSettingsData.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(refreshSettingsData.rejected, (state) => {
      state.settingsArray = initialState.settingsArray;
    });
    builder.addCase(refreshSettingsData.fulfilled, (state, { payload }) => {
      state.settingsArray = payload;
    });
    builder.addCase(getSupportedChainsData.pending, (state) => {
      state.chainsLoading = true;
    });
    builder.addCase(getSupportedChainsData.fulfilled, (state, { payload }) => {
      state.supportedChains = payload;
      state.chainsLoading = false;
    });
    builder.addCase(getSupportedChainsData.rejected, (state) => {
      state.chainsLoading = false;
      state.supportedChains = initialState.supportedChains;
    });
  },
});

export default settingsSlice.reducer;

import { ethers } from "ethers";
import { soilTokenAbi } from "./contracts/abi/soilToken.abi";
import { usdcTokenAbi } from "./contracts/abi/usdcToken.abi";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import multicall from "../utils/multicall/multicall";
import { MulticallContract } from "../utils/multicall/contract";

const initUSDCContract = (
  usdcTokenAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => new ethers.Contract(usdcTokenAddress, usdcTokenAbi, provider);

export const getUSDCBalance = async (
  usdcTokenAddress: string,
  walletAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const usdcContract = initUSDCContract(usdcTokenAddress, provider);
  const usdcBalance = await usdcContract.balanceOf(walletAddress);
  return usdcBalance;
};

export const getBalanceOfTokens = async (
  walletAddress: string,
  tokens: { address: string; symbol: string }[],
  provider: ethers.providers.JsonRpcProvider
): Promise<{ [key: string]: string }> => {
  const balances = await multicall(
    provider,
    tokens.map((token) =>
      new MulticallContract(token.address, usdcTokenAbi).balanceOf(
        walletAddress
      )
    )
  );
  return Object.fromEntries(
    balances.map((balance, index) => [tokens[index].symbol, balance.toString()])
  );
};

const initSOILContract = () =>
  new ethers.Contract(config.SOIL_TOKEN_ADDRESS, soilTokenAbi, AppRpcProvider);

export const getSOILBalance = async (walletAddress: string) => {
  const soilContract = initSOILContract();
  const soilBalance = await soilContract.balanceOf(walletAddress);
  return soilBalance;
};

export const transferUsdcTokens = async (
  usdcTokenAddress: string,
  to: string,
  amount: ethers.BigNumber,
  signer: ethers.providers.JsonRpcSigner,
  provider: ethers.providers.JsonRpcProvider
) => {
  const usdcContract = initUSDCContract(usdcTokenAddress, provider);
  const { wait } = await usdcContract.connect(signer).transfer(to, amount);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

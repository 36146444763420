import styled from "styled-components";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

export const ListContent = styled.p`
  font-family: Ingra;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  flex: 1 0 auto;

  strong {
    font-weight: bold;
  }
`;

import styled from "styled-components";
import { motion } from "framer-motion";

type SwitchProps = {
  isSwitchedOn?: boolean;
  isLevel?: boolean;
  saturate?: boolean;
};

export const SwitchWrapper = styled(motion.div)<SwitchProps>`
  width: ${({ isLevel }) => (isLevel ? "28px" : "48px")};
  min-width: ${({ isLevel }) => (isLevel ? "28px" : "48px")};
  height: ${({ isLevel }) => (isLevel ? "14px" : "24px")};
  box-shadow: ${({ theme }) => theme.colors.switch.boxShadow};
  border-radius: 12px;
  display: flex;
  justify-content: ${({ isSwitchedOn }) =>
    isSwitchedOn ? "flex-end" : "flex-start"};
  align-items: center;
  cursor: pointer;
`;

export const SwitchDot = styled(motion.div)<SwitchProps>`
  height: ${({ isLevel }) => (isLevel ? "12px" : "20px")};
  width: ${({ isLevel }) => (isLevel ? "12px" : "20px")};
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  background: ${({ theme, saturate }) =>
    saturate ? theme.colors.black : theme.colors.switch.backgroundDot};
`;

import { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../../Icons/UserIcon";
import {
  useDetectIframeClick,
  useDetectOutsideClick,
  useMediaQuery,
} from "../../../../../../hooks";
import Dropdown from "../Dropdown/Dropdown";
import * as S from "./UserTool.styled";
import { Typography } from "../../../../../../components";
import MyProfileNav from "../../../MyProfileNav/MyProfileNav";
import { device } from "../../../../../../style";
import { routes } from "../../../../../../utils/routes";

export type UserDropdownProps = {
  isOpen: boolean;
  toggleVisible: () => void;
};

const UserTool = ({ isOpen, toggleVisible }: UserDropdownProps) => {
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const iconBtn = document.getElementsByClassName("ignoreOutsideClick");
  const isDesktopView = useMediaQuery(device.laptop);

  const handleMobileClickUserIcon = () => {
    navigate(routes.userPanel.myProfile);
  };

  useDetectIframeClick(toggleVisible, isOpen);
  useDetectOutsideClick(dropdownRef, toggleVisible, iconBtn);
  return (
    <>
      <UserIcon
        isToolbar
        isOpen={isOpen}
        click={isDesktopView ? toggleVisible : handleMobileClickUserIcon}
      />
      <AnimatePresence>
        {isOpen && (
          <Dropdown innerRef={dropdownRef} targetIcon="user">
            <S.UserToolWrapper>
              <Typography.Body fontWeight={300} opacity={0.92}>
                My profile
              </Typography.Body>
              <MyProfileNav />
            </S.UserToolWrapper>
          </Dropdown>
        )}
      </AnimatePresence>
    </>
  );
};

export default UserTool;

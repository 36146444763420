import styled, { css } from "styled-components";
import { Typography } from "../../../../../components";

export const NavItem = styled(Typography.HeaderSmall)<{ disabled?: boolean }>`
  a {
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.72px;
    text-align: left;
    color: #fff;
    border-right: solid 2px transparent;
    padding: 15px 40px;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: rgba(38, 166, 119, 0.2);
    }
  }

  a.active {
    background-color: rgba(38, 166, 119, 0.3);
    border-right-color: #08a98a;
    color: #08a98a;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      a {
        opacity: 0.3;
        pointer-events: none;
      }
    `}

  .submenu {
    .active {
      background-color: rgba(38, 166, 119, 0.3);
    }

    a {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.93;
      letter-spacing: -0.56px;
      text-align: left;
      padding: 12px 40px;
    }

    a.active {
      background-color: inherit;
      border-right-color: transparent;
    }
  }
`;

import { yupResolver } from "@hookform/resolvers/yup";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Button,
  FormInput,
  Modal,
  Typography,
} from "../../../../../../components";
import { useTfa } from "../../../../../../hooks";
import {
  closeTfaModal,
  openTfaModal,
} from "../../../../../../redux/features/uiSlice";
import { recoveryUserTfa } from "../../../../../../redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { toast } from "../../../../../../toasts";
import * as S from "./TfaForActionModal.styled";

type FormValues = {
  recoveryCode: string;
};

const schema = yup.object().shape({
  recoveryCode: yup
    .string()
    .max(16, "The recovery code must be exactly 16 characters long")
    .min(16, "The recovery code must be exactly 16 characters long")
    .required("The recovery code is required"),
});

const TfaForActionModal = () => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });
  const { authenticateWithTfa } = useTfa();
  const { isOpenTfaModal } = useAppSelector((store) => store.ui);
  const { currentAction } = useAppSelector((store) => store.user);
  const { tfaEnabled } = useAppSelector((state) => state.user);
  const [useRecoveryCode, setUseRecoveryCode] = useState(false);

  const handleModalCustomClose = () => {
    dispatch(closeTfaModal());
    setUseRecoveryCode(false);
  };

  const handleOnChange = (code: string) => {
    if (code.length === 6) {
      authenticateWithTfa(code);
      handleModalCustomClose();
    }
  };

  const handleRecoveryAccount = async (code: string) => {
    try {
      await dispatch(recoveryUserTfa(code)).unwrap();
      toast.success(
        "Recovery account successful",
        "Two-Factor Authenticate has been disabled on your account"
      );
    } catch {
      toast.error(
        "Recovery account failed",
        "The entered recovery code is incorrect"
      );
    }
  };

  const onSubmit = (formData: FormValues) => {
    handleRecoveryAccount(formData.recoveryCode);
    reset();
    handleModalCustomClose();
  };

  const toggleUseRecoveryCode = () => {
    setUseRecoveryCode(!useRecoveryCode);
  };

  useEffect(() => {
    if (tfaEnabled && currentAction !== "") {
      dispatch(openTfaModal());
    } else {
      dispatch(closeTfaModal());
    }
  }, [tfaEnabled, currentAction]);

  return (
    <Modal
      title={["Two-Factor", "Authentication"]}
      isOpen={isOpenTfaModal}
      onClose={() => dispatch(closeTfaModal())}
      description={
        useRecoveryCode
          ? ["Enter your recovery code below or"]
          : ["Enter your code below"]
      }
    >
      <div>
        {useRecoveryCode ? (
          <>
            <Typography.Body style={{ marginBottom: "10px", color: "#000" }}>
              <S.BackToCodeBtn as="span" onClick={toggleUseRecoveryCode}>
                back to Two-Factor Authentication
              </S.BackToCodeBtn>
            </Typography.Body>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <FormInput
                {...register("recoveryCode")}
                label=""
                placeholder="Your code"
                error={errors?.recoveryCode?.message}
                anyCharacters
              />
              <Button.Secondary
                style={{ marginTop: "20px" }}
                type="submit"
                bigSize
              >
                Disable TFA
              </Button.Secondary>
            </form>
          </>
        ) : (
          <>
            <S.TwoFaContainer>
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, display: "none" }}
                  animate={{ opacity: 1, display: "block" }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, delay: 0.05 }}
                  style={{ willChange: "transform opacity" }}
                >
                  <AuthCode
                    key={
                      isOpenTfaModal ? "disable-tfa-open" : "enable-tfa-open"
                    }
                    allowedCharacters="numeric"
                    onChange={handleOnChange}
                    inputClassName="two-factor-input"
                  />
                </motion.div>
              </AnimatePresence>
            </S.TwoFaContainer>
            <S.DontRememberText style={{ color: "#000" }}>
              You don&lsquo;t have access to your code?{" "}
              <S.BackToCodeBtn onClick={toggleUseRecoveryCode}>
                Use recovery code.
              </S.BackToCodeBtn>
            </S.DontRememberText>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TfaForActionModal;

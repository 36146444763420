import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { api } from "../../../api";
import {
  CreateNotification,
  NotificationUpdateData,
} from "../../../api/notification";
import { FormInput, MBC, Select } from "../../../components";
import { toast } from "../../../toasts";
import { getErrorMessage } from "../../../utils";
import { CustomFilter } from "../CustomFilter";
import { MessageInput } from "./components/MessageInput";
import * as S from "./NewMessageBody.styled";

type MessageData = {
  id: number;
  title: string;
  message: string;
};

type NewMessageBodyProps = {
  data?: MessageData;
  closeModal: () => void;
  userId?: number;
  refreshNotificationPlanned?: () => Promise<void>;
};

export type FormValues = {
  title: string;
  message: string;
  recipientType: "ALL" | "ALL_STAKE_HOLDERS" | "ALL_POOL_HOLDERS" | "USERS";
  types: string[];
  inputType: string;
  revealTime: Date;
  users?: number[];
};

const schema = yup.object().shape({
  title: yup.string().required("The title is required"),
  message: yup.string().required("The message is required"),
  types: yup.array().min(1, "The types is required"),
});

const optionRecipents = [
  {
    label: " All",
    value: "ALL",
  },
  {
    label: "All stake holders",
    value: "ALL_STAKE_HOLDERS",
  },
  {
    label: "All pool holders",
    value: "ALL_POOL_HOLDERS",
  },
  {
    label: "Users",
    value: "USERS",
  },
];

export const NewMessageBody = ({
  data,
  closeModal,
  userId,
  refreshNotificationPlanned,
}: NewMessageBodyProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<FormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (data) {
      methods.setValue("message", data.message);
      methods.setValue("title", data.title);
      methods.setValue("types", ["users"]);
      methods.setValue("revealTime", new Date());
    }
  }, []);

  const onHandleUpdateNotificationData = async (
    id: number,
    obj: NotificationUpdateData
  ) => {
    setIsLoading(true);
    try {
      await api.notification.updateNotification(id, obj);
      if (refreshNotificationPlanned) {
        await refreshNotificationPlanned();
      }
      toast.success(
        "Message Successfully Changed",
        `The message has been successfully changed`
      );
      setIsLoading(false);
      closeModal();
    } catch (error) {
      toast.error("Error", getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const onHandleAddNotification = async (obj: CreateNotification) => {
    try {
      setIsLoading(true);
      await api.notification.addNotification(obj);
      if (refreshNotificationPlanned) {
        await refreshNotificationPlanned();
      }
      toast.success(
        "Message Successfully Created",
        `The message has been successfully created`
      );
      setIsLoading(false);
      closeModal();
    } catch (error) {
      toast.error("Error", getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const onHandleSubmit = (obj: FormValues) => {
    obj.revealTime = obj.revealTime || new Date();
    if (data) {
      const variables = {
        title: obj.title,
        message: obj.message,
      };
      onHandleUpdateNotificationData(data.id, variables);
    } else if (userId) {
      const messageData: FormValues = {
        title: obj.title,
        message: obj.message,
        recipientType: "USERS",
        types: obj.types,
        inputType: obj.inputType,
        revealTime: obj.revealTime,
        users: [userId],
      };
      onHandleAddNotification(messageData);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { inputType, ...restData } = obj;
      onHandleAddNotification(restData);
    }
  };

  return (
    <FormProvider {...methods}>
      <MBC.Form
        style={{ color: "black" }}
        message
        onSubmit={methods.handleSubmit(onHandleSubmit)}
      >
        <Wrapper>
          {!data && (
            <S.SelectRow>
              {!userId && (
                <Select
                  title="Recipients: "
                  {...methods.register("recipientType")}
                  options={optionRecipents}
                  style={{ marginBottom: "20px" }}
                />
              )}
              <Controller
                control={methods.control}
                name="revealTime"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <S.Container>
                    <S.DatePickerButton
                      selected={value}
                      onChange={onChange}
                      placeholderText="Choose date"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy hh:mm"
                      error={error?.message}
                      showTimeSelect
                      ref={ref}
                    />
                    {error?.message && (
                      <S.ErrorMessage>{error?.message}</S.ErrorMessage>
                    )}
                  </S.Container>
                )}
              />
              <CustomFilter />
            </S.SelectRow>
          )}

          <FormInput
            title="Title"
            isReversed
            anyCharacters
            placeholder="Title..."
            {...methods.register("title")}
            error={methods.formState.errors?.title?.message}
          />

          <MessageInput
            title="Message"
            {...methods.register("message")}
            error={methods.formState.errors?.message?.message}
            placeholder="Message..."
          />
          <S.ButtonWrapper>
            <S.SendButton disabled={isLoading} type="submit">
              Send
            </S.SendButton>
          </S.ButtonWrapper>
        </Wrapper>
      </MBC.Form>
    </FormProvider>
  );
};

const Wrapper = styled.div`
  * {
    color: black;
  }
`;

import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { api } from "../../api";
import { CurrencyValue, InfoBox } from "../../components";
import { config } from "../../config";
import { toast } from "../../toasts";
import { formatBigNumber, getErrorMessage } from "../../utils";

export const PoolsValue = () => {
  const [poolsValue, setPoolsValue] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { chainId } = useAccount();

  const getPoolsValue = async () => {
    if (!chainId) return;
    setIsLoading(true);
    try {
      const {
        data: {
          totalDeposited: { all },
        },
      } = await api.pools.getGlobalPoolStats(chainId);
      setPoolsValue(formatBigNumber(all, config.USDC_TOKEN_DECIMALS));
      setIsLoading(false);
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPoolsValue();
  }, [chainId]);

  return (
    <InfoBox.Admin title="All pools value:">
      <CurrencyValue
        value={isLoading ? null : poolsValue}
        currency="USDC"
        decimals={2}
        isBig
      />
    </InfoBox.Admin>
  );
};

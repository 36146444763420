import styled from "styled-components";
import { device } from "../../../../style";

export const Wrapper = styled.nav`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.nav};
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 30px 0;

  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    position: relative;
    min-width: 220px;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const NavWrapper = styled.ul<{ isAdmin?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isAdmin }) => (isAdmin ? "2px" : "12px")};
`;

export const HamburgerBtn = styled.div`
  width: 19px;
  height: 19px;
  left: 30px;
  top: 25px;
  z-index: ${({ theme }) => theme.zIndex.hamburger};
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;

  @media ${device.mobileL} {
    right: 31px;
    top: 25px;
  }

  div {
    background: white;
    border-radius: 30px;
    width: 30px;
    height: 3px;
  }
`;

import styled from "styled-components";
import { device } from "../../style";

export const SectionTitleWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
`;

export const TitleBg = styled.h2`
  font-size: 1em;
`;

export const Module = styled.div`
  font-family: Ingra;
  font-size: 7.5vw;
  line-height: 111.18px;
  letter-spacing: 0.06em;
  text-align: center;
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;
  opacity: 0.12;
  font-weight: 700;

  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const InsideText = styled.div`
  position: relative;

  @media ${device.tablet} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

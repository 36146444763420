import styled from "styled-components";
import { device } from "../../style";

const chainIdToBackground: { [key: number]: string } = {
  1: "linear-gradient(to bottom,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(132deg, #13093e 1%, #1f358a 99%)",
  11155111:
    "linear-gradient(to bottom,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(132deg, #13093e 1%, #1f358a 99%)",
  137: "linear-gradient(to bottom,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(132deg, #13093e 1%, #47126a 99%)",
  80002:
    "linear-gradient(to bottom,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),linear-gradient(132deg, #13093e 1%, #47126a 99%)",
};

export const Wrapper = styled.div<{ chainId?: number }>`
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background-color: rgb(23, 11, 49);
  display: flex;
  flex-direction: column;

  background-image: ${({ chainId }) =>
    chainId
      ? chainIdToBackground[chainId] ?? chainIdToBackground[137]
      : chainIdToBackground[137]};
  transition: background-image 0.5s ease-in-out;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const RightSide = styled.div<{ visibleBGLogo?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
`;

export const Content = styled.div`
  flex: 1;
  margin-bottom: 0;
  position: relative;
  overflow: auto;
  overflow-x: hidden;

  @media ${device.tablet} {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.background};
      width: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme.colors.notification.text.primary};
      border-radius: 10px;

      &:hover {
        background-color: ${({ theme }) =>
          theme.colors.notification.text.secondary};
      }
    }
  }
`;

export const NavContainer = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useAppDispatch, useAppSelector } from "../redux";
import { getUserData, logoutUser } from "../redux/features/userSlice";
import { useProtocolSettingsAddress } from "./useProtocolSettingsAddress";

export const useAuthCheck = () => {
  let accessToken: string | null = null;
  if (navigator.cookieEnabled) {
    accessToken = localStorage.getItem("accessToken");
  }
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const preLogged = useAppSelector((state) => state.user.preLogged);
  const dispatch = useAppDispatch();
  const { chainId } = useAccount();
  const protocolSettingsAddress = useProtocolSettingsAddress();
  // accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!chainId) return;
    if ((isLogged && !preLogged) || (accessToken && !preLogged)) {
      const getUserDataPayload = {
        chainId,
        protocolSettingsAddress,
      };
      dispatch(getUserData(getUserDataPayload));
      // dispatch(getReflink());
      // dispatch(getReferralStats());
    }
  }, [accessToken, chainId]);

  useEffect(() => {
    if (isLogged && !accessToken) {
      dispatch(logoutUser());
    }
  }, [accessToken]);
};

import { useEffect, useMemo } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  closeConnectWalletModal,
  hideDisclaimer,
} from "../../redux/features/uiSlice";

type OnboardStep = {
  title: [string, string];
  description: string;
  isDone: boolean;
  id: number;
};

type OnboardSteps = OnboardStep[];

export const useOnboarding = () => {
  const { isConnected } = useAccount();
  const { isLogged, userData } = useAppSelector((state) => state.user);
  const { isPending } = useSignMessage();
  const { kyc, userToRoles } = { ...userData };
  const { status: kycStatus } = { ...kyc };
  const isAdmin = userToRoles?.some((utr) =>
    ["admin", "super_admin"].includes(utr.role.name)
  );
  const dispatch = useAppDispatch();

  const onboardSteps = useMemo<OnboardSteps>(
    () => [
      {
        title: ["Connect", "Your Wallet"],
        description:
          "To begin, please connect your crypto wallet. You can choose from popular options like MetaMask or WalletConnect. Your wallet will be securely linked to manage your investments.",
        isDone: isConnected,
        id: 1,
      },
      {
        title: ["Sign Message", "to Login"],
        description:
          "To continue, please sign the message in your wallet app. This signature confirms your identity without sharing sensitive information. It's a secure way to verify your login and ensure safe access to your account and investments.",
        isDone: isLogged && !isPending,
        id: 2,
      },
      {
        title: ["Verify", "your identity (KYC)"],
        description:
          "For security purposes, we need to verify your identity. Please provide the necessary documents to complete the KYC (Know Your Customer) process. This ensures that your account is secure and compliant.",
        isDone: kycStatus === "approved",
        id: 3,
      },
    ],
    [isConnected, isLogged, kycStatus]
  );

  const activeStepIndex = useMemo(() => {
    if (!isConnected) return 0;
    if (kycStatus === "approved") {
      return 2;
    }
    if (isLogged) {
      return 2;
    }
    if (isConnected) {
      return 1;
    }
    return 0;
  }, [isConnected, isLogged, kycStatus]);

  useEffect(() => {
    if (activeStepIndex === 2) {
      dispatch(hideDisclaimer());
    }
  }, [activeStepIndex]);

  useEffect(() => {
    if (
      isAdmin ||
      (activeStepIndex === 2 && kycStatus === "approved") ||
      kycStatus === "started" ||
      kycStatus === "pending"
    ) {
      dispatch(closeConnectWalletModal());
    }
  }, [activeStepIndex, kycStatus, isAdmin]);

  return {
    onboardSteps,
    activeStepIndex,
  };
};

import { UserOptions, cookie3Analytics } from "@cookie3/analytics";
import { AnimatePresence } from "framer-motion";
import { useEffect, useLayoutEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useAccount } from "wagmi";
import { AnimatedRoutes } from "./components";
import { config as appConfig } from "./config";
import { Cookie3Provider } from "./context/Cookie3Provider";
import { useAuthCheck, useReduxActionInterval } from "./hooks";
import { actions, useAppSelector } from "./redux";
import { GlobalStyle, theme } from "./style";
import { Layout } from "./template";
import { isAdminRole } from "./utils";
import { routes } from "./utils/routes";

function App() {
  useAuthCheck();
  useReduxActionInterval(actions.token.getTokenPrice, 30_000);
  const { chainId } = useAccount();

  const config: UserOptions = {
    siteId: Number(appConfig.COOKIE3_CLIENT_ID),
    linkTracking: true,
  };

  const navigate = useNavigate();

  const roles = useAppSelector((state) => state?.user?.userData?.userToRoles);

  useLayoutEffect(() => {
    if (!chainId) return;
    const isAdmin = isAdminRole(roles, chainId);

    if (isAdmin) navigate(routes.adminPanel.dashboard);
  }, [roles]);

  const analytics = cookie3Analytics(config);

  useEffect(() => {
    document.addEventListener("wheel", (event) => {
      if (
        document.activeElement &&
        (document.activeElement as HTMLInputElement).type === "number"
      ) {
        (document.activeElement as HTMLInputElement).blur();
      }
    });

    return () => {
      document.removeEventListener("wheel", (event) => {
        if (
          document.activeElement &&
          (document.activeElement as HTMLInputElement).type === "number"
        ) {
          (document.activeElement as HTMLInputElement).blur();
        }
      });
    };
  }, []);

  return (
    <AnimatePresence>
      <Cookie3Provider value={analytics}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Layout>
              <HelmetProvider>
                <AnimatedRoutes />
              </HelmetProvider>
            </Layout>
          </ThemeProvider>
        </CookiesProvider>
      </Cookie3Provider>
    </AnimatePresence>
  );
}

export default App;

import styled from "styled-components";
import { device } from "../../style";
import { Typography } from "../Typography/Typography";

const Form = styled.form<{ message?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.message ? "10px" : "20px")};

  @media ${device.mobileL} {
    gap: ${(props) => (props.message ? "10px" : "40px")};
  }

  * {
    color: black;
  }
`;

const HeaderWithLogoWrapper = styled.div<{
  center?: boolean;
  smallGap?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ smallGap }) => (smallGap ? "12px" : "20px")};

  @media ${device.mobileL} {
    flex-direction: row;
    gap: 47px;
    align-items: ${({ center }) => (center ? "center" : "flex-start")};
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 45px;
  min-width: 45px;
  min-height: 45px;

  img {
    width: auto;
  }
`;

const InfoBox = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfoRow = styled(Typography.Body)`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  gap: 8px;
  font-weight: 400;
  opacity: 0.9;

  span {
    display: flex;
    gap: 11.33px;
    font-weight: 800;
    align-items: center;
  }

  @media ${device.mobileXL} {
    flex-direction: row;
    gap: 10px;
  }
`;

const Text = styled(Typography.Body)`
  font-weight: 400;

  span {
    font-weight: 800;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.mobileL} {
    flex-direction: row;
    gap: 15px;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PendingInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ModalDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.white033};
  border-radius: 4px;
  padding: 8px;
`;

const AgreeTermsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const Link = styled.a`
  font-size: 10px;
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.body.letterSpacing};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const DisabledLink = styled.p`
  font-size: 10px;
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.body.letterSpacing};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  display: inline;
`;

export const MBC = {
  Form,
  HeaderWithLogoWrapper,
  LogoWrapper,
  InfoBox,
  InfoRow,
  Text,
  ButtonsWrapper,
  SwitchWrapper,
  InputsWrapper,
  PendingInfoWrapper,
  ModalDisclaimer,
  AgreeTermsContainer,
  Link,
  DisabledLink,
};

import styled, { css } from "styled-components";

type WrapperProps = {
  big?: boolean;
  inline?: boolean;
  isForChart?: boolean;
};

export const Wrapper = styled.span<WrapperProps>`
  display: ${({ inline }) => (inline ? "inline" : "block")};
  width: ${({ big, isForChart }) =>
    isForChart ? "100%" : big ? "20px" : "13.33px"};
  height: ${({ big }) => (big ? "20px" : "13.33px")};
  cursor: pointer;

  img {
    width: 100%;
  }

  div.customTheme {
    color: ${({ theme }) => theme.colors.grey} !important;
    background-color: ${({ theme }) => theme.colors.background} !important;
    font-size: 12px !important;
    line-height: 15px !important;
    letter-spacing: 0.05em !important;
    font-weight: 400 !important;
  }
  div.customTheme.place-top:after {
    border-top-color: ${({ theme }) => theme.colors.background} !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }
`;

export const TooltipWrapper = styled.div<{ isForChart?: boolean }>`
  max-width: 200px;
  min-width: ${({ isForChart }) => (isForChart ? "max-content" : "initial")};
  color: ${({ theme }) => theme.colors.grey};
  background: black;
  opacity: 1 !important;
  padding: ${({ isForChart }) => (isForChart ? "8px" : "10px")};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-bottom: 5px;
  border-radius: 4px;
  z-index: 1000;
  position: ${({ isForChart }) => (isForChart ? "absolute" : "initial")};
`;

export const ArrowMark = styled.img<{ placement: "top" | "bottom" }>(
  ({ theme, placement }) => css`
    position: absolute;
    pointer-events: none;

    ${placement === "top" &&
    css`
      bottom: -7px;
    `}

    ${placement === "bottom" &&
    css`
      top: -7px;
      transform: rotate(180deg) !important;
    `}

    width: 15px;
    height: 15px;
    fill: ${theme.colors.toolbar.background};
  `
);

export const LegendColorBox = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  background: ${({ color }) => color && color};
`;

export const MessageWrapper = styled.div<{ messageColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  white-space: pre-wrap;
  width: 180px;
  color: ${({ messageColor }) => messageColor || "#fff"} !important;
`;

import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { api } from "../../../../api";
import { usePoolsV2ContractAddress } from "../../../../hooks/usePoolsV2ContractAddress";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { openTfaModal } from "../../../../redux/features/uiSlice";
import { setCurrentTfaAction } from "../../../../redux/features/userSlice";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { useEthersSigner } from "../../../../utils";

export const usePoolUpgradeHandler = (
  onSuccess: () => void,
  level: PoolLevel,
  newLevel?: PoolLevel
) => {
  const [isUpgradeInProgress, setIsUpgradeInProgress] = useState(false);

  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isUpgradePoolAuthenticated } = tfaAuthenticatedForAction;

  const signer = useEthersSigner();
  const dispatch = useAppDispatch();
  const { chainId } = useAccount();
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const { address } = useAccount();

  const isV2Contract = level.pool.version === "v2";

  const handleUpgrade = async () => {
    try {
      if (!newLevel) {
        throw new Error("Level to upgrade not selected");
      }

      if (!chainId) return;

      if (!signer) {
        throw new Error("Wallet not connected");
      }

      setIsUpgradeInProgress(true);

      const rewards = level.usdcReward || "0";
      const soilRewards = level.soilReward || "0";

      const {
        data: { signature, deadline },
      } = await api.pools.getClaimRewardsSignature({
        poolId: level.pool.id,
        level: level.level,
        rewards,
        soilRewards,
        withWithdraw: false,
        unlockId: 0,
      });

      let upgradeTx;

      if (isV2Contract) {
        upgradeTx = poolsContractService.upgradePoolV2(
          level.pool.poolId,
          level.level,
          newLevel.level,
          rewards,
          soilRewards,
          deadline,
          signature,
          signer,
          poolsV2ContractAddress,
          address as string
        );
      } else {
        upgradeTx = poolsContractService.upgradePool(
          level.pool.poolId,
          level.level,
          newLevel.level,
          rewards,
          soilRewards,
          deadline,
          signature,
          signer
        );
      }

      const title = `Upgrading funds to ${newLevel.name}...`;

      await handleBlockchainPromiseInToast(
        upgradeTx,
        {
          pending: {
            title,
            message: "Your request is in progress...",
          },
          error: {
            title,
            message:
              "Your request has been finished unsuccessful. Try again later",
          },
          success: {
            title,
            message:
              "The transaction has been finished successful . You can check details on the block explorer by click the button below.",
          },
        },
        chainId
      );

      onSuccess();
    } catch (e: unknown) {
      setIsUpgradeInProgress(false);
      toast.errorHandler(e, "Failed to upgrade pool");
    }
  };

  const submitUpgrade = () => {
    if (tfaEnabled) {
      dispatch(setCurrentTfaAction("upgradePool"));
      dispatch(openTfaModal());
    } else {
      handleUpgrade();
    }
  };

  useEffect(() => {
    if (isUpgradePoolAuthenticated) {
      handleUpgrade();
    }
  }, [isUpgradePoolAuthenticated]);

  return {
    isUpgradeInProgress,
    submitUpgrade,
  };
};

export const routes = {
  cookiesPage: "/enable-cookies",
  userPanel: {
    dashboard: "/dashboard",
    earn: {
      liquidStake: "/earn/liquid-stake",
      fixedPools: "/earn/fixed-pools",
    },
    grow: "/grow",
    swap: "/swap",
    vesting: "/vesting",
    notifications: "/notifications",
    wallet: "/wallet",
    myProfile: "/my-profile",
    kyc: "/kyc",
    settings: "/settings",
    projectDetails: "/project-details/:id",
    kycAuth: "/kyc/auth",
    loanAgreement: "/SOIL_General_Loan_Terms",
    referrals: "/referrals",
    kycWelcome: "/kyc/welcome",
  },
  adminPanel: {
    dashboard: "/admin/dashboard",
    pools: "/admin/pools",
    users: "/admin/users",
    admins: "/admin/admins",
    messages: "/admin/messages",
    reports: "/admin/reports",
    reportsRequiredBalance: "/admin/reports/required-balance",
    staking: "/admin/staking",
    vesting: "/admin/vesting",
    newPool: "/admin/pools/new-pool",
    editPool: "/admin/pools/editPool/:id",
    userDetails: "/admin/users/userDetails/:id",
    feesWithdrawal: "/admin/fees-withdrawal",
    fundsWithdrawal: "/admin/funds-withdrawal",
    loanAgreements: "/admin/loan-agreements",
    whiteList: "/admin/whitelist",
    referrals: "/admin/referrals",
  },
};

export const defaultHomeRoute = routes.userPanel.earn.liquidStake;

import { useAccount } from "wagmi";
import { boostIcon } from "../../../../assets";
import { CustomTooltip } from "../../../../components";
import { config } from "../../../../config";
import * as S from "./BoostInfo.styled";

type BoostInfoProps = {
  poolLevel: PoolLevel;
};

export const BoostInfo = ({ poolLevel }: BoostInfoProps) => {
  const { chainId } = useAccount();
  const isBaseNetwork = chainId === config.NETWORK_ID;

  if (!poolLevel.boostActive) {
    return null;
  }

  if (!isBaseNetwork && !poolLevel.pool.isShort) {
    return null;
  }

  return (
    <CustomTooltip
      message={`Your staking amount qualified you for an additional ${
        isBaseNetwork ? "SOIL" : poolLevel.pool.token
      } token reward in this pool`}
    >
      <S.Boost>
        Boosted <img src={boostIcon} alt="boost" />
      </S.Boost>
    </CustomTooltip>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, FunctionFragment, JsonFragment } from "@ethersproject/abi";

function toFragment(abi: JsonFragment[] | string[] | Fragment[]): Fragment[] {
  return abi.map((item: JsonFragment | string | Fragment) =>
    Fragment.from(item)
  );
}

function makeCallFunction(contract: MulticallContract, name: string) {
  return (...params: any[]) => {
    const { address } = contract;
    let fragment = contract.functions.find((f) => f.name === name);
    if (!fragment) {
      // Try to find function by name and inputs
      const nameWithoutParams = name.split("(")[0];
      const paramsLength = name.split("(")[1].split(")")[0].split(",").length;
      fragment = contract.functions.find(
        (f) => f.name === nameWithoutParams && f.inputs.length === paramsLength
      );
      if (!fragment)
        throw new Error(`Function ${name} not found in contract ${address}`);
    }
    const { inputs, outputs } = fragment;
    return {
      contract: {
        address,
      },
      name,
      inputs,
      outputs,
      params,
    };
  };
}

function defineReadOnly(object: object, name: string, value: unknown) {
  Object.defineProperty(object, name, {
    enumerable: true,
    value,
    writable: false,
  });
}

export class MulticallContract {
  public address: string;

  public abi: Fragment[];

  public functions: FunctionFragment[];

  constructor(address: string, abi: JsonFragment[] | string[] | Fragment[]) {
    this.address = address;

    this.abi = toFragment(abi);

    this.functions = this.abi
      .filter((x) => x.type === "function")
      .map((x) => FunctionFragment.from(x));
    const callFunctions = this.functions.filter(
      (x) => x.stateMutability === "pure" || x.stateMutability === "view"
    );

    for (const callFunction of callFunctions) {
      const { name, inputs } = callFunction;
      const getCall = makeCallFunction(this, name);
      if (!this[name]) {
        defineReadOnly(this, name, getCall);
      } else {
        const getCall2 = makeCallFunction(
          this,
          `${name}(${inputs.map((i) => i.type).join(",")})`
        );
        defineReadOnly(
          this,
          `${name}(${inputs.map((i) => i.type).join(",")})`,
          getCall2
        );
      }
    }
  }

  [method: string]: any;
}

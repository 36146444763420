import { createAction, createSlice } from "@reduxjs/toolkit";
import { configWagmi } from "../../context/WagmiProvider";

type UserState = {
  isKycPreventModalOpen: boolean;
  isOpenTfaModal: boolean;
  tfaIsAuthenticated: boolean;
  isOpenSignMessageModal: boolean;
  isOpenSignMessageModalWhenAuth: boolean;
  isOpenExchangeModal: boolean;
  isOpenMissingKycModal: boolean;
  isValidatePoolData: boolean;
  isOpenOnboarding: boolean;
  isOpenConnectWalletModal: boolean;
  isSignMessageError: boolean;
  isDisclaimerHiddenByOnboarding: boolean;
  chainId: number;
};

const initialState: UserState = {
  isKycPreventModalOpen: false,
  isOpenTfaModal: false,
  tfaIsAuthenticated: false,
  isOpenSignMessageModal: false,
  isOpenSignMessageModalWhenAuth: false,
  isOpenExchangeModal: false,
  isOpenMissingKycModal: false,
  isValidatePoolData: false,
  isOpenOnboarding: false,
  isOpenConnectWalletModal: false,
  isSignMessageError: false,
  isDisclaimerHiddenByOnboarding: false,
  chainId: configWagmi.chains[0].id,
};

export const openKycPreventModal = createAction("ui/OpenKycPreventModal");

export const closeKycPreventModal = createAction("ui/CloseKycPreventModal");

export const openTfaModal = createAction("ui/OpenTfaModal");

export const closeTfaModal = createAction("ui/CloseTfaModal");

export const confirmTfa = createAction("ui/ConfirmTfa");

export const openSignMessageModal = createAction("ui/OpenSignMessageModal");

export const closeSignMessageModal = createAction("ui/closeSignMessageModal");

export const openMissingKycModal = createAction("ui/openMissingKycModal");

export const closeMissingKycModal = createAction("ui/closeMissingKycModal");

export const openSignMessageModalWhenAuth = createAction(
  "ui/OpenSignMessageModalWhenAuth"
);

export const closeSignMessageModalWhenAuth = createAction(
  "ui/CloseSignMessageModalWhenAuth"
);

export const openExchangeModal = createAction("ui/openExchangeModal");

export const closeExchangeModal = createAction("ui/CloseExchangeModal");

export const validatePoolData = createAction("ui/ValidatePoolData");

export const invalidatePoolData = createAction("ui/InvalidatePoolData");

export const openOnboarding = createAction("ui/OpenOnboarding");

export const closeOnboarding = createAction("ui/CloseOnboarding");

export const openConnectWalletModal = createAction("ui/OpenConnectWalletModal");

export const closeConnectWalletModal = createAction(
  "ui/CloseConnectWalletModal"
);

export const setSignMessageError = createAction("ui/SetSignMessageError");

export const resetSignMessageError = createAction("ui/ResetSignMessageError");

export const showDisclaimer = createAction("ui/ShowDisclaimer");

export const hideDisclaimer = createAction("ui/HideDisclaimer");

export const setChainId = createAction<number>("ui/SetChainId");

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(openKycPreventModal, (state) => {
      state.isKycPreventModalOpen = true;
    });
    builder.addCase(closeKycPreventModal, (state) => {
      state.isKycPreventModalOpen = false;
    });
    builder.addCase(openTfaModal, (state) => {
      state.isOpenTfaModal = true;
      state.tfaIsAuthenticated = false;
    });
    builder.addCase(closeTfaModal, (state) => {
      state.isOpenTfaModal = false;
    });
    builder.addCase(confirmTfa, (state) => {
      state.tfaIsAuthenticated = true;
      state.isOpenTfaModal = false;
    });
    builder.addCase(openSignMessageModal, (state) => {
      state.isOpenSignMessageModal = true;
    });
    builder.addCase(closeSignMessageModal, (state) => {
      state.isOpenSignMessageModal = false;
    });
    builder.addCase(openSignMessageModalWhenAuth, (state) => {
      state.isOpenSignMessageModalWhenAuth = true;
    });
    builder.addCase(closeSignMessageModalWhenAuth, (state) => {
      state.isOpenSignMessageModalWhenAuth = false;
    });
    builder.addCase(openExchangeModal, (state) => {
      state.isOpenExchangeModal = true;
    });
    builder.addCase(closeExchangeModal, (state) => {
      state.isOpenExchangeModal = false;
    });
    builder.addCase(openMissingKycModal, (state) => {
      state.isOpenMissingKycModal = true;
    });
    builder.addCase(closeMissingKycModal, (state) => {
      state.isOpenMissingKycModal = false;
    });
    builder.addCase(validatePoolData, (state) => {
      state.isValidatePoolData = true;
    });
    builder.addCase(invalidatePoolData, (state) => {
      state.isValidatePoolData = false;
    });
    builder.addCase(openOnboarding, (state) => {
      state.isOpenOnboarding = true;
    });
    builder.addCase(closeOnboarding, (state) => {
      state.isOpenOnboarding = false;
    });
    builder.addCase(openConnectWalletModal, (state) => {
      state.isOpenConnectWalletModal = true;
      state.isSignMessageError = false;
      state.isDisclaimerHiddenByOnboarding = true;
    });
    builder.addCase(closeConnectWalletModal, (state) => {
      state.isOpenConnectWalletModal = false;
      state.isSignMessageError = false;
    });
    builder.addCase(setSignMessageError, (state) => {
      state.isSignMessageError = true;
    });
    builder.addCase(showDisclaimer, (state) => {
      state.isDisclaimerHiddenByOnboarding = false;
    });
    builder.addCase(hideDisclaimer, (state) => {
      state.isDisclaimerHiddenByOnboarding = true;
    });
    builder.addCase(setChainId, (state, action) => {
      state.chainId = action.payload;
    });
  },
});

export default uiSlice.reducer;

import { BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { ConnectWalletButton } from "..";
import { hackenIcon } from "../../assets";
import { Button } from "../../components";
import { FilledInput } from "../../components/FormInput/FilledInput";
import { config } from "../../config";
import { actions } from "../../redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StakeDetails } from "./StakeDetails";
import * as S from "./StakeForm.styled";
import { useStakeHandler } from "./useStakeHandler";

type StakeFormProps = {
  onStakeSuccess?: () => void;
};

export const StakeForm = ({ onStakeSuccess }: StakeFormProps) => {
  const [isErrorInStakeInput] = useState(false);
  const { handleSubmit, control, isStakeInProgress, inputValue } =
    useStakeHandler(onStakeSuccess);
  const { isConnected, chainId } = useAccount();
  const location = useLocation();
  const balances = useAppSelector((store) => store.wallet.balances);
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );

  const feeSOIL = useAppSelector((store) => store.staking.feeSOIL);

  let stakedAmount = parseUnits(
    inputValue || "0",
    config.SOIL_TOKEN_DECIMALS
  ).sub(BigNumber.from(feeSOIL));
  stakedAmount = stakedAmount.isNegative() ? BigNumber.from(0) : stakedAmount;

  const isButtonDisabled =
    isStakeInProgress || isErrorInStakeInput || stakedAmount.isZero();

  useEffect(() => {
    if (!chainId) return;
    dispatch(
      actions.wallet.getBalances({
        address: walletAddress,
        chainId,
      })
    );
  }, [walletAddress]);

  return (
    <S.StakeContainer>
      <S.InfoBox style={{ color: "#000" }}>
        <h2>
          <span className="hightlight">GROW</span> $SOIL
        </h2>
        <p>Grow tokens to earn $SOIL and boost your yield.</p>
        <StakeDetails amountToStake={stakedAmount} />
        <S.AuditedBy>
          <S.Label style={{ color: "#000" }}>Audited by:</S.Label>
          <S.ImageWrapper>
            <img src={hackenIcon} alt="Hacken" style={{ height: 21.7 }} />
          </S.ImageWrapper>
        </S.AuditedBy>
      </S.InfoBox>
      <S.StakingForm onSubmit={handleSubmit}>
        <Controller
          name="amountToStake"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FilledInput
              {...field}
              variant="soil"
              placeholder="0"
              available={formatUnits(balances.soil, config.SOIL_TOKEN_DECIMALS)}
              withRange
            />
          )}
        />
        <S.HelpText style={{ color: "#000" }}>
          {" "}
          Slide or enter the amount to see your est. rewards
        </S.HelpText>
        {isConnected ? (
          <Button.Primary disabled={isButtonDisabled} type="submit" bigSize>
            {location.state?.amount ? "Grow your unlocked founds" : "Grow"}
          </Button.Primary>
        ) : (
          <ConnectWalletButton />
        )}
      </S.StakingForm>
    </S.StakeContainer>
  );
};

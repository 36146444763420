import { useAccount } from "wagmi";

interface IUsdcAddresses {
  [key: number]: string | undefined;
}
const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};
const USDC_ADDRESSES: IUsdcAddresses = {
  // Mainnets
  137: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
    "USDC_TOKEN_ADDRESS_Polygon"
  ), // Polygon
  1: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ETHEREUM,
    "USDC_TOKEN_ADDRESS_ETH"
  ), // Ethereum
  // 42161: envVariableValidator(
  //   process.env.REACT_APP_USDC_TOKEN_ADDRESS_ARBITRUM,
  //   "USDC_TOKEN_ADDRESS_ARBITRUM"
  // ), // Arbitrum

  // Testnets
  11155111: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_ETHEREUM,
    "USDC_TOKEN_ADDRESS_ETH_TESTNET"
  ), // Ethereum Sepolia
  // 421614: envVariableValidator(
  //   process.env.REACT_APP_USDC_TOKEN_ADDRESS_ARBITRUM,
  //   "USDC_TOKEN_ADDRESS_ARBITRUM_TESTNET"
  // ), // Arbitrum Goerli
  80002: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
    "USDC_TOKEN_ADDRESS_POLYGON_AMOY_TESTNET"
  ), // Polygon Amoy Testnet
};

export const useUsdcTokenAddress = () => {
  const { chainId } = useAccount();

  if (!chainId || !USDC_ADDRESSES[chainId]) {
    return envVariableValidator(
      process.env.REACT_APP_USDC_TOKEN_ADDRESS_POLYGON,
      "USDC_TOKEN_ADDRESS_Polygon"
    );
  }

  return String(USDC_ADDRESSES[chainId]);
};

import { configureStore } from "@reduxjs/toolkit";
import userReducer, { getUserData } from "./features/userSlice";
import vestingReducer from "./features/vestingSlice";
import walletReducer from "./features/walletSlice";
import stakingReducer from "./features/stakingSlice";
import settingsReducer from "./features/settingsSlice";
import { tokenSlice } from "./features/tokenSlice";
import poolReducer from "./features/poolSlice";
import uiReducer from "./features/uiSlice";
import referralsReducer from "./features/referralsSlice";

const reducer = {
  user: userReducer,
  vesting: vestingReducer,
  staking: stakingReducer,
  wallet: walletReducer,
  token: tokenSlice.reducer,
  settings: settingsReducer,
  pool: poolReducer,
  ui: uiReducer,
  referrals: referralsReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: getUserData,
      },
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { useState } from "react";
import styled from "styled-components";
import { Button, Modal, Spinner } from "../../../../../../components";
import { useKYC } from "../../../../../../pages/userPanel/KYC/useKYC";
import { useAppDispatch, useAppSelector } from "../../../../../../redux";
import { closeMissingKycModal } from "../../../../../../redux/features/uiSlice";

const ModalBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
`;

export const MissingKyc = () => {
  const { isOpenMissingKycModal } = useAppSelector((state) => state.ui);
  const [isOpenKycIframe, setIsKycIframeOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleCloseAndResetState = () => {
    setIsKycIframeOpen(false);
    dispatch(closeMissingKycModal());
  };

  const { iframeSrc, handleInitKyc } = useKYC({
    handleModalClose: handleCloseAndResetState,
  });

  const handleOpenKycIframe = () => {
    setIsKycIframeOpen(true);
    handleInitKyc();
  };

  return (
    <Modal
      isOpen={isOpenMissingKycModal}
      title={
        iframeSrc ? ["Waiting", "for KYC provider.."] : ["Missing", "KYC data"]
      }
      description={
        !iframeSrc
          ? [
              "Unfortunately, your data is incomplete.",
              "During the KYC procedure, the Fractal operator provided us with incomplete information about you. All you need to do now is:",
              " Click the button below to approve your KYC data again.",
            ]
          : [""]
      }
      onClose={handleCloseAndResetState}
      withOutsideClick
    >
      <ModalBodyWrapper>
        {isOpenKycIframe ? (
          !iframeSrc ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                minHeight: "80%",
                minWidth: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FFF",
                zIndex: 99999,
              }}
            >
              <Spinner />
            </div>
          ) : (
            <iframe
              width="100%"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                height: "100%",
                border: "none",
                overflow: "hidden",
                zIndex: 1001,
              }}
              src={iframeSrc}
              scrolling="no"
              title="kyc-iframe"
              allow="camera *; fullscreen *"
              sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            />
          )
        ) : (
          <Button.Primary onClick={handleOpenKycIframe}>
            Approve your KYC data
          </Button.Primary>
        )}
      </ModalBodyWrapper>
    </Modal>
  );
};

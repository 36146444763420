import { useAccount } from "wagmi";

interface IPoolsV2Addresses {
  [key: number]: string | undefined;
}
const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};
const POOLS_V2_ADDRESS: IPoolsV2Addresses = {
  // Mainnets
  137: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
  ), // Polygon
  1: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM"
  ), // Ethereum

  // Testnets
  11155111: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_ETHEREUM"
  ), // Ethereum Sepolia
  80002: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
    "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
  ), // Polygon Amoy Testnet
};

export const usePoolsV2ContractAddress = () => {
  const { chainId } = useAccount();

  if (!chainId || !POOLS_V2_ADDRESS[chainId]) {
    return envVariableValidator(
      process.env.REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON_V2,
      "REACT_APP_POOLS_CONTRACT_ADDRESS_POLYGON"
    );
  }

  return String(POOLS_V2_ADDRESS[chainId]);
};

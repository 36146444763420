import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoFractal } from "../../assets";
import { useOnboarding } from "../../features/Onboarding/useOnboarding";
import { useKYC } from "../../pages/userPanel/KYC/useKYC";
import { Spinner } from "../../pages/userPanel/Wallet/UserInvestmentsInfo/UserRewards/ClaimAllRewards/ClaimAllRewardsBody/RewardInfo/RewardInfo.styled";
import { useAppSelector } from "../../redux";
import * as S from "./KycModalBody.styled";

type KycModalBodyProps = {
  handleModalClose: () => void;
};

export const KycModalBody = ({ handleModalClose }: KycModalBodyProps) => {
  const { activeStepIndex } = useOnboarding();
  const [isKycIframeOpen, setIsKycIframeOpen] = useState(0);
  const statusKyc = useAppSelector((state) => state.user.userData.kyc?.status);
  const navigate = useNavigate();

  const redirectToWelcome = () => navigate("/kyc/welcome");

  const handleCloseModalAndRedirect = () => {
    redirectToWelcome();
    handleModalClose();
  };

  const { iframeSrc, handleInitKyc } = useKYC({
    handleModalClose: handleCloseModalAndRedirect,
  });

  const handleOpenKycIframe = () => {
    setIsKycIframeOpen(1);
    handleInitKyc();
  };

  const fadeInOutVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  useEffect(() => {
    if (statusKyc === "started") {
      handleCloseModalAndRedirect();
    }
  }, []);

  return isKycIframeOpen === 0 ? (
    <S.KycModalWrapper
      key={activeStepIndex}
      initial={activeStepIndex === 0 ? "visible" : "hidden"}
      animate="visible"
      exit={activeStepIndex === 0 ? "visible" : "exit"}
      variants={fadeInOutVariants}
      transition={{ duration: 0.3 }}
    >
      <S.InitKycButton onClick={handleOpenKycIframe}>
        Verify Identity
      </S.InitKycButton>
      <S.PoweredText>Powered by</S.PoweredText>
      <S.FractalLogo src={logoFractal} alt="Fractal logo" />
      <S.KycModalContent>
        We use Fractal as a third-party KYC services provider. Your personal
        data will be processed by Fractal. We will only have access to the
        results of your KYC verification. To learn more about processing of your
        personal data please consult our <span>Privacy Policy</span>, as well as
        the <span>Fractal’s Privacy Policy.</span>
      </S.KycModalContent>
    </S.KycModalWrapper>
  ) : (
    <S.KycModalWrapper
      isFullHeight
      key={isKycIframeOpen}
      initial={activeStepIndex === 0 ? "visible" : "hidden"}
      animate="visible"
      exit={activeStepIndex === 0 ? "visible" : "exit"}
      variants={fadeInOutVariants}
      transition={{ duration: 0.3 }}
    >
      <Spinner />
      {!iframeSrc ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            minHeight: "80%",
            minWidth: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FFF",
          }}
        >
          <div
            style={{
              zIndex: 1000,
              height: "100%",
              width: "100%",
              background: "#FFF",
            }}
          >
            <Spinner />
          </div>
        </div>
      ) : (
        <iframe
          width="100%"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            border: "none",
            overflow: "hidden",
            zIndex: 1001,
          }}
          src={iframeSrc}
          scrolling="no"
          title="kyc-iframe"
          allow="camera *; fullscreen *"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        />
      )}
    </S.KycModalWrapper>
  );
};

import { useAccount } from "wagmi";
import { Chain } from "wagmi/chains";
import { Typography } from "../../../../../components";
import { configWagmi } from "../../../../../context/WagmiProvider";
import { useAppSelector } from "../../../../../redux";
import { isAdminRole } from "../../../../../utils";

export const AdminNetworkInfo = () => {
  const { userToRoles } = useAppSelector((store) => store.user.userData);

  const { chainId } = useAccount();

  if (!chainId) return null;

  if (!isAdminRole(userToRoles, chainId)) {
    return null;
  }

  if (!configWagmi) return null;

  return (
    <Typography.HeaderMedium style={{ marginRight: "auto" }} fontWeight={700}>
      {configWagmi.chains?.find((chain: Chain) => chain.id === chainId)?.name}
    </Typography.HeaderMedium>
  );
};

import { StatusParagraph, Typography } from "../../components";
import { Logo } from "../../template/Layout/components/Logo/Logo";
import * as S from "./Onboarding.styled";
import { useOnboarding } from "./useOnboarding";

export const Onboarding = () => {
  const { onboardSteps } = useOnboarding();
  return (
    <S.OnboardingStatusContent>
      <S.OnboardingStatusTitle>
        <Typography.Ingra28
          isWhite
          fontWeight={300}
          style={{ textTransform: "uppercase" }}
          opacity={1}
        >
          connect to
        </Typography.Ingra28>
        <Logo isBigVariant />
      </S.OnboardingStatusTitle>
      <S.OnboardingStatusesWrapper>
        {onboardSteps.map((step) => (
          <StatusParagraph
            key={step.id}
            isDone={step.isDone}
            text={step.title.join(" ")}
          />
        ))}
      </S.OnboardingStatusesWrapper>
    </S.OnboardingStatusContent>
  );
};

import styled from "styled-components";

export const LabelParagraph = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  padding: 4px;
  font-family: Arial;
  font-size: ${({ theme }) => theme.fontStyles.walletStatus.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.walletStatus.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.walletStatus.lineHeight};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.farm.boostText};
  background-color: ${({ theme }) => theme.colors.farm.boostBgc};
`;

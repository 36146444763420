import styled from "styled-components";
import { device } from "../../../../style";

export const Wrapper = styled.div<{
  isBigVarian?: boolean;
  isHugeVariant?: boolean;
}>`
  min-width: ${({ isHugeVariant }) => (isHugeVariant ? "303px" : "32px")};
  height: ${({ isBigVarian, isHugeVariant }) =>
    isBigVarian ? "50px" : isHugeVariant ? "68px" : "32px"};
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 0 40px;

  @media ${device.mobileL} {
    min-width: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian ? "224px" : isHugeVariant ? "303px" : "109px"};
    height: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian ? "50px" : isHugeVariant ? "68px" : "30px"};
  }

  @media ${device.laptop} {
    margin-bottom: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian || isHugeVariant ? "0" : "109px"};
    margin-left: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian || isHugeVariant ? "0" : "32px"};
  }

  img {
    min-width: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian ? "224px" : isHugeVariant ? "303px" : "109px"};
    height: ${({ isBigVarian, isHugeVariant }) =>
      isBigVarian ? "50px" : isHugeVariant ? "68px" : "32px"};
    position: absolute;
    left: 0;

    @media ${device.mobileL} {
      min-width: unset;
      width: ${({ isBigVarian, isHugeVariant }) =>
        isBigVarian ? "224px" : isHugeVariant ? "303px" : "109px"};
      height: ${({ isBigVarian, isHugeVariant }) =>
        isBigVarian ? "50px" : isHugeVariant ? "68px" : "32px"};
    }
  }
`;

import { AnimatePresence } from "framer-motion";
import { matchPath, NavLink } from "react-router-dom";
import { useAccount } from "wagmi";
import {
  DashboardIcon,
  EarnIcon,
  FixedYieldPoolIcon,
  GrowIcon,
  LiquidStakeIcon,
  NavIconVariant,
  SwapIcon,
  VestingIcon,
} from "../../../../../assets/nav-icon";
import { config } from "../../../../../config";
import { useAppSelector } from "../../../../../redux";
import { routes } from "../../../../../utils/routes";
import { DropdownLinks } from "./DropdownLinks";
import * as S from "./NavLinks.styled";

export type NavProps = {
  toggleMobileNav?: () => void;
};

export const UserLinks = ({ toggleMobileNav }: NavProps) => {
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const { chainId: globalChainId } = useAppSelector((state) => state.ui);
  const { chainId } = useAccount();
  const isBaseNetwork =
    chainId === config.NETWORK_ID || globalChainId === config.NETWORK_ID;

  return (
    <>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.userPanel.dashboard}>
          <DashboardIcon
            variant={
              matchPath(window.location.pathname, routes.userPanel.dashboard)
                ? NavIconVariant.ACTIVE
                : NavIconVariant.DEFAULT
            }
          />
          Dashboard
        </NavLink>
      </S.NavItem>
      <S.NavItem as="li">
        <DropdownLinks
          forceOpen
          label={
            <>
              <EarnIcon />
              Earn
            </>
          }
          labelActive={
            <>
              <EarnIcon variant={NavIconVariant.ACTIVE} />
              Earn
            </>
          }
          id="earn"
          links={[
            {
              id: routes.userPanel.earn.liquidStake,
              to: routes.userPanel.earn.liquidStake,
              label: (
                <>
                  <LiquidStakeIcon
                    variant={
                      matchPath(
                        window.location.pathname,
                        routes.userPanel.earn.liquidStake
                      )
                        ? NavIconVariant.ACTIVE
                        : NavIconVariant.ACTIVE
                    }
                  />
                  Liquid Stake
                </>
              ),
              onClick: toggleMobileNav,
            },
            {
              id: routes.userPanel.earn.fixedPools,
              to: routes.userPanel.earn.fixedPools,
              label: (
                <>
                  <FixedYieldPoolIcon
                    variant={
                      matchPath(
                        window.location.pathname,
                        routes.userPanel.earn.fixedPools
                      )
                        ? NavIconVariant.ACTIVE
                        : NavIconVariant.ACTIVE
                    }
                  />
                  Fixed Yield Pools
                </>
              ),
              onClick: toggleMobileNav,
            },
          ]}
        />
      </S.NavItem>
      <AnimatePresence>
        {isBaseNetwork && (
          <S.NavItem as="li" disabled={!isLogged}>
            <NavLink onClick={toggleMobileNav} to={routes.userPanel.grow}>
              <GrowIcon
                variant={
                  matchPath(window.location.pathname, routes.userPanel.grow)
                    ? NavIconVariant.ACTIVE
                    : NavIconVariant.DEFAULT
                }
              />
              Grow
            </NavLink>
          </S.NavItem>
        )}
      </AnimatePresence>
      {isBaseNetwork && (
        <S.NavItem as="li">
          <NavLink onClick={toggleMobileNav} to={routes.userPanel.swap}>
            <SwapIcon
              variant={
                matchPath(window.location.pathname, routes.userPanel.swap)
                  ? NavIconVariant.ACTIVE
                  : NavIconVariant.DEFAULT
              }
            />
            Swap
          </NavLink>
        </S.NavItem>
      )}
      <AnimatePresence>
        {isBaseNetwork && (
          <S.NavItem as="li">
            <NavLink onClick={toggleMobileNav} to={routes.userPanel.vesting}>
              <VestingIcon
                variant={
                  matchPath(window.location.pathname, routes.userPanel.vesting)
                    ? NavIconVariant.ACTIVE
                    : NavIconVariant.DEFAULT
                }
              />
              Vesting
            </NavLink>
          </S.NavItem>
        )}
      </AnimatePresence>
    </>
  );
};

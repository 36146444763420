import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createClient } from "viem";
import { createConfig, http, WagmiProvider } from "wagmi";
import { Chain, mainnet, polygon, polygonAmoy, sepolia } from "wagmi/chains";
import { metaMask, safe, walletConnect } from "wagmi/connectors";
import { config } from "../config";

const selectedChains: readonly [Chain, ...Chain[]] =
  config.NODE_ENV === "dev" ? [polygonAmoy, sepolia] : [polygon, mainnet];

export const configWagmi = createConfig({
  chains: selectedChains,
  connectors: [
    metaMask({
      storage: {
        enabled: false,
      },
    }),
    walletConnect({
      projectId: config.WALLET_CONNECT_PROJECT_ID,
      showQrModal: false,
    }),
    safe({ allowedDomains: [/app.safe.global$/], debug: false }),
  ],
  client({ chain }: { chain: Chain }) {
    return createClient({ chain, transport: http() });
  },
  multiInjectedProviderDiscovery: false,
});

const queryClient = new QueryClient();

interface IWalletProvider {
  children: React.ReactNode;
}

export const WalletProvider = ({ children }: IWalletProvider) => (
  <WagmiProvider config={configWagmi}>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </WagmiProvider>
);

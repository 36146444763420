import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import {
  EarnState,
  EarnActionType,
  EarnInitState,
  EarnReducer,
} from "./earnReducer";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const EarnContext = createContext<EarnState>(null!);

export const EarnDispatchContext = createContext<Dispatch<EarnActionType>>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  null!
);

const useEarnContextState = () => {
  const context = useContext(EarnContext);

  if (context === undefined) {
    throw new Error("useEarnContextState was used outside of its Provider");
  }

  return context;
};

const useEarnDispatchContext = () => {
  const context = useContext(EarnDispatchContext);

  if (context === undefined) {
    throw new Error("useEarnDispatchContext was used outside of its Provider");
  }

  return context;
};

const EarnContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(EarnReducer, EarnInitState);

  return (
    <EarnContext.Provider value={state}>
      <EarnDispatchContext.Provider value={dispatch}>
        {children}
      </EarnDispatchContext.Provider>
    </EarnContext.Provider>
  );
};

export { useEarnContextState, useEarnDispatchContext, EarnContextProvider };

import styled from "styled-components";

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.div<{ error?: boolean; withBorder?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: ${({ error }) => (error ? "2px solid" : "1px solid")};
  border-color: ${({ theme, error }) =>
    error ? theme.colors.error : theme.colors.primaryLight};
  background: white;

  &::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }
`;

export const Label = styled.label`
  position: relative;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  ${Input} {
    &:checked ~ ${Checkmark} {
      background: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      &::after {
        display: block;
      }
    }
  }
`;

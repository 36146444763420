import * as sentry from "@sentry/react";
import { AxiosError } from "axios";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { api } from "../../api";
import { config } from "../../config";
import { useCookie3 } from "../../context/Cookie3Provider";
import { actions, useAppSelector } from "../../redux";
import {
  openKycPreventModal,
  openTfaModal,
} from "../../redux/features/uiSlice";
import {
  refreshUserData,
  setCurrentTfaAction,
  unathenticateTfa,
} from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { stakingService } from "../../services";
import { toast } from "../../toasts";
import { handleBlockchainPromiseInToast } from "../../toasts/handleBlockchainPromiseInToast";
import { useEthersSigner } from "../../utils";
import { useTfa } from "../../hooks";

type StakeFormData = {
  amountToStake: string;
};

export const useStakeHandler = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  const { chainId } = useAccount();

  const [myAmount, setMyAmount] = useState("");

  const [isStakeInProgress, setIsStakeInProgress] = useState(false);

  const { handleSubmit, control, watch, setValue, reset } =
    useForm<StakeFormData>();

  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isStakeAuthenticated } = tfaAuthenticatedForAction;

  const { isConnected, address } = useAccount();
  const signer = useEthersSigner();
  const location = useLocation();
  const { resetAfterTfa } = useTfa();
  useEffect(() => {
    if (location.state && location.state?.amount)
      setValue("amountToStake", location.state.amount);
  }, []);

  const inputValue = watch("amountToStake");

  const cookie3 = useCookie3();

  const handleStake = async (amount: string, withTfa = false) => {
    setIsStakeInProgress(true);
    try {
      if (signer && address) {
        const amountToStakeInWei = ethers.utils.parseUnits(
          amount,
          config.SOIL_TOKEN_DECIMALS
        );

        if (!chainId) return;

        const {
          data: { fee, signature, deadline },
        } = await api.staking.getSignatureStake(amountToStakeInWei);

        const stakeTx = stakingService.stakeSoil(
          amountToStakeInWei,
          fee,
          signature,
          deadline,
          signer,
          address
        );

        const title = `Growing ${amount} $SOIL (including fees)`;

        await handleBlockchainPromiseInToast(
          stakeTx,
          {
            pending: {
              title,
              message: "Your request is in progress...",
            },
            error: {
              title,
              message:
                "Your request has been finished unsuccessful. Try again later.",
            },
            success: {
              title,
              message:
                "The transaction has been finished successful. You can check details on the block explorer by click the button below.",
            },
          },
          chainId
        );
        dispatch(actions.wallet.getBalances({ address, chainId }));
      }
    } catch (e: unknown) {
      sentry.captureException(e);
      if (
        e instanceof AxiosError &&
        (e?.response?.data?.message === "KYC not found." ||
          e?.response?.data?.message === "KYC not passed.")
      ) {
        dispatch(openKycPreventModal());
      } else {
        toast.errorHandler(e, "Grew action failed!");
      }
      if (cookie3) {
        cookie3.trackEvent({
          category: "Stake",
          action: "Stake Failed",
          value: Number(amount),
        });
      }
      setIsStakeInProgress(false);
      dispatch(unathenticateTfa());
    } finally {
      if (withTfa) {
        await resetAfterTfa();
      }

      setIsStakeInProgress(false);
      if (cookie3) {
        cookie3.trackEvent({
          category: "Stake",
          action: "Stake Success",
          value: Number(amount),
        });
      }
      dispatch(actions.staking.getStakingData());
      reset({
        amountToStake: "",
      });

      if (onSuccess) {
        onSuccess();
      }
      dispatch(refreshUserData());
    }
  };

  const handleStakeSubmit = (amount: string) => {
    if (tfaEnabled) {
      setMyAmount(amount);
      dispatch(setCurrentTfaAction("stake"));
      dispatch(openTfaModal());
    } else {
      handleStake(amount);
    }
  };

  useEffect(() => {
    if (isStakeAuthenticated) {
      handleStake(myAmount, true);
    }
  }, [isStakeAuthenticated]);

  const submitStake = async ({ amountToStake }: StakeFormData) => {
    handleStakeSubmit(amountToStake);
  };

  return {
    handleSubmit: handleSubmit(submitStake),
    control,
    isConnected,
    isStakeInProgress,
    inputValue,
  };
};

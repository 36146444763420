import { NavIconProps, NavIconVariant } from ".";

export const LiquidStakeIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_654)">
            <path
              d="M7.90413 0.383026L7.51562 0.0371094L7.12712 0.383609C6.92879 0.560943 2.26562 4.76211 2.26562 8.18336C2.26562 11.4699 4.93496 13.4334 7.51562 13.4334C10.0963 13.4334 12.7656 11.4699 12.7656 8.18336C12.7662 4.75278 8.10246 0.560359 7.90413 0.383026ZM8.09954 11.1H7.51621C6.10221 11.1 4.59954 10.078 4.59954 8.18336V7.60003H5.76621V8.18336C5.76621 9.56761 6.91071 9.93336 7.51621 9.93336H8.09954V11.1Z"
              fill="#08A98A"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_654">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0.515625 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_654)">
            <path
              d="M7.90413 0.383026L7.51562 0.0371094L7.12712 0.383609C6.92879 0.560943 2.26562 4.76211 2.26562 8.18336C2.26562 11.4699 4.93496 13.4334 7.51562 13.4334C10.0963 13.4334 12.7656 11.4699 12.7656 8.18336C12.7662 4.75278 8.10246 0.560359 7.90413 0.383026ZM8.09954 11.1H7.51621C6.10221 11.1 4.59954 10.078 4.59954 8.18336V7.60003H5.76621V8.18336C5.76621 9.56761 6.91071 9.93336 7.51621 9.93336H8.09954V11.1Z"
              fill="#959595"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_654">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(0.515625 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export enum NavIconVariant {
  DEFAULT,
  ACTIVE,
  HOVER,
}
export type NavIconProps = { variant?: NavIconVariant };

export { DashboardIcon } from "./dashboard";
export { EarnIcon } from "./earn";
export { FixedYieldPoolIcon } from "./fixed-yield-pools";
export { GrowIcon } from "./grow";
export { LiquidStakeIcon } from "./liquid-stake";
export { SwapIcon } from "./swap";
export { VestingIcon } from "./vesting";

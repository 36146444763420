import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAccount } from "wagmi";
// import { parseUnits } from "ethers/lib/utils";
import { api } from "../../../../api";
import { config } from "../../../../config";
import { usePoolsV2ContractAddress } from "../../../../hooks/usePoolsV2ContractAddress";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { openTfaModal } from "../../../../redux/features/uiSlice";
import { setCurrentTfaAction } from "../../../../redux/features/userSlice";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { useEthersSigner } from "../../../../utils";

type WithdrawFormType = {
  amount: string;
  unlockRewardsAfter?: boolean;
};

export const usePoolWithdrawHandler = (
  poolLevel: PoolLevel,
  onSuccess: () => void
) => {
  const signer = useEthersSigner();
  const isV2Contract = poolLevel.pool.version === "v2";

  const { control, handleSubmit, watch } = useForm<WithdrawFormType>();

  const [isWithdrawInProgress, setWithdrawInProgress] = useState(false);
  const [tfaAmount, setTfaAmount] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { address, chainId } = useAccount();
  const poolsV2ContractAddress = usePoolsV2ContractAddress();

  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isWithdrawPoolAuthenticated } = tfaAuthenticatedForAction;

  const onWithdrawSubmit = async (amount: string) => {
    setWithdrawInProgress(true);

    try {
      if (!signer || !address) {
        throw new Error("Wallet is not connected");
      }

      if (!chainId) return;

      if (
        (isV2Contract && !poolLevel.usdcReward) ||
        (!isV2Contract && (!poolLevel.usdcReward || !poolLevel.soilReward))
      )
        return;

      const rewards = BigNumber.from(
        poolLevel.poolLevelBalances[1].balance
      ).gte(BigNumber.from(poolLevel.pool.softCap))
        ? poolLevel.usdcReward
        : "0";

      const soilRewards = BigNumber.from(
        poolLevel.poolLevelBalances[0].balance
      ).gte(BigNumber.from(poolLevel.pool.softCap))
        ? poolLevel.soilReward
        : "0";

      let txSignature;
      let txDeadline;

      let poolId;
      let level;
      let amountToBlockchain;
      let rewardsToBlockchain;
      let soilRewardsToBlockchain;
      let userAddress;
      let withdrawTx;

      if (isV2Contract) {
        const {
          data: { signature, deadline, rawData },
        } = await api.pools.getWithdrawSignature(
          poolLevel.pool.id.toString(),
          poolLevel.level.toString(),
          amount,
          "0"
        );

        const {
          userAddress: _userAddress,
          poolId: _poolId,
          level: _level,
          amount: _amount,
          rewards: _rewards,
          soilRewards: _soilRewards,
        } = rawData;

        poolId = _poolId;
        level = _level;
        amountToBlockchain = _amount;
        rewardsToBlockchain = _rewards;
        soilRewardsToBlockchain = _soilRewards;
        userAddress = _userAddress;
        txSignature = signature;
        txDeadline = deadline;

        withdrawTx = poolsContractService.withdrawV2(
          poolId,
          level,
          amountToBlockchain,
          rewardsToBlockchain,
          soilRewardsToBlockchain,
          txDeadline,
          txSignature,
          signer,
          poolsV2ContractAddress,
          userAddress
        );
      } else {
        const {
          data: { signature, deadline },
        } = await api.pools.getClaimRewardsSignature({
          poolId: poolLevel.pool.id,
          level: poolLevel.level,
          rewards: rewards || "0",
          soilRewards: soilRewards || "0",
          withWithdraw: true,
          unlockId: 0,
        });

        txSignature = signature;
        txDeadline = deadline;

        const parsedAmount = ethers.utils.parseUnits(
          amount,
          config.USDC_TOKEN_DECIMALS
        );

        withdrawTx = poolsContractService.withdraw(
          poolLevel.pool.poolId,
          poolLevel.level,
          parsedAmount,
          rewards || BigNumber.from(0),
          soilRewards || BigNumber.from(0),
          txDeadline,
          txSignature,
          signer
        );
      }

      const title = `Withdrawing ${amount} ${config.STABLE_TOKEN} from ${poolLevel.name}`;

      await handleBlockchainPromiseInToast(
        withdrawTx,
        {
          pending: {
            title,
            message: "Your request is in progress...",
          },
          error: {
            title,
            message:
              "Your request has been finished unsuccessful. Try again later",
          },
          success: {
            title,
            message:
              "The transaction has been finished successful. You can check details on the block explorer by click the button below.",
          },
        },
        chainId
      );

      onSuccess();
    } catch (e: unknown) {
      toast.errorHandler(e, "Withdrawal failed!");
      setWithdrawInProgress(false);
    }
  };

  const withdrawSubmit = async ({ amount }: WithdrawFormType) => {
    if (tfaEnabled) {
      setTfaAmount(amount);
      dispatch(setCurrentTfaAction("poolWithdraw"));
      dispatch(openTfaModal());
    } else {
      onWithdrawSubmit(amount);
    }
  };

  useEffect(() => {
    if (isWithdrawPoolAuthenticated && tfaAmount) {
      onWithdrawSubmit(tfaAmount);
    }
  }, [isWithdrawPoolAuthenticated, tfaAmount]);

  const inputValue = watch("amount");
  const checkBoxValue = watch("unlockRewardsAfter");
  return {
    control,
    handleSubmit: handleSubmit(withdrawSubmit),
    inputValue,
    isWithdrawInProgress,
    checkBoxValue,
  };
};

import { apiCallWithAuthToken } from "./config";

export interface Chain {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  chainId: string;
  chainSelectorCCIP: string;
  routerAddress: string;
  isSupported: boolean;
  contracts: {
    id: string;
    name: string;
    address: string;
    version: string;
    lastKnownBlock: string;
    createdAt: string;
    updatedAt: string;
  }[];
  tokens: {
    id: string;
    token: string;
    address: string;
    name: string;
    symbol: string;
    decimals: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

type SupportedChainsResponse = Chain[];

export const getSupportedChains = async () =>
  apiCallWithAuthToken.get<SupportedChainsResponse>("/chains");

import * as sentry from "@sentry/react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { Chain } from "wagmi/chains";
import { initLogin } from "../api/auth";
import { configWagmi } from "../context/WagmiProvider";
import { staking } from "../redux/features/stakingSlice";
import {
  closeSignMessageModal,
  invalidatePoolData,
  openSignMessageModal,
  setSignMessageError,
} from "../redux/features/uiSlice";
import {
  getUserData,
  loginUser,
  loginUserTfa,
  logoutUser,
} from "../redux/features/userSlice";
import { wallet } from "../redux/features/walletSlice";
import { useAppDispatch } from "../redux/hooks";
import { toast } from "../toasts";
import { isAdminRole } from "../utils";
import { defaultHomeRoute, routes } from "../utils/routes";
import { useProtocolSettingsAddress } from "./useProtocolSettingsAddress";

export const useAuth = () => {
  // const [initLoginIsPending, setInitLoginIsPending] = useState(false);
  const initLoginIsPending = useRef(false);
  const { connector, address, chainId } = useAccount();
  const dispatch = useAppDispatch();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const protocolSettingsAddress = useProtocolSettingsAddress();
  const { signMessageAsync, isError, isPending } = useSignMessage();

  const tfaLogin = async (code: string) => {
    if (!chainId) return;
    try {
      await dispatch(loginUserTfa(code)).unwrap();
      const getUserDataPayload = {
        chainId,
        protocolSettingsAddress,
      };
      await dispatch(getUserData(getUserDataPayload)).unwrap();
      // await dispatch(getReflink());
      // await dispatch(getReferralStats());
      toast.success("Login successful", "You have been logged in successfully");
    } catch (error: unknown) {
      sentry.captureException(error);
      toast.error("Failed to login", "Login has failed, try again later");
    }
  };

  const backendAuthorization = async (
    walletAddress: string,
    signature: string
  ) => {
    if (!signature || !address || !chainId) return;
    try {
      dispatch(closeSignMessageModal());
      const data = {
        address: walletAddress,
        signedMessage: signature,
        chainId: chainId.toString(),
      };

      const payload = await dispatch(loginUser(data)).unwrap();
      dispatch(invalidatePoolData());
      const { tfaEnabled } = payload;

      if (!tfaEnabled) {
        const getUserDataPayload = {
          chainId,
          protocolSettingsAddress,
        };
        const userData = await dispatch(
          getUserData(getUserDataPayload)
        ).unwrap();

        const isAdmin = isAdminRole(userData.userToRoles, chainId);
        toast.success(
          "Login successful",
          "You have been logged in successfully"
        );

        if (isAdmin) {
          navigate(routes.adminPanel.dashboard);
        }
        localStorage.removeItem("closedByUser");
      }
    } catch (error) {
      rejectLogin();
      sentry.captureException(error);
      toast.error("Failed to login", "Login has failed, try again later");
      dispatch(closeSignMessageModal());
    }
  };

  const logout = async () => {
    try {
      disconnect();
      localStorage.removeItem("wagmi.store");
      localStorage.removeItem("wagmi.cache");
      await dispatch(logoutUser()).unwrap();
      dispatch(staking.resetStakingData());
      dispatch(wallet.resetWalletBalance());
      toast.success(
        "Logout successful",
        "You have been successfully logged out"
      );
      navigate(defaultHomeRoute);
    } catch {
      toast.success(
        "Logout successful",
        "You have been successfully logged out"
      );
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate(defaultHomeRoute);
    }
  };

  const rejectLogin = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      dispatch(staking.resetStakingData());
      dispatch(wallet.resetWalletBalance());
      toast.error("Failed to login", "Login has failed, try again later");
    } catch (error) {
      logout();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  };

  const login = async () => {
    if (initLoginIsPending.current) return;
    initLoginIsPending.current = true;
    if (!connector || !address || !configWagmi?.chains) return;

    if (
      configWagmi.chains?.some(
        (configChain: Chain) => configChain.id === chainId
      )
    ) {
      try {
        if (isPending) return;
        const response = await initLogin(address);
        const { message } = response.data;
        if (isPending) return;
        dispatch(openSignMessageModal());
        const signature = await signMessageAsync({ message });
        backendAuthorization(address, signature);
      } catch (error) {
        rejectLogin();
        sentry.captureException(error);
        toast.error("Failed to login", "Login has failed, try again later");
        dispatch(closeSignMessageModal());
      } finally {
        initLoginIsPending.current = false;
      }
    }
    initLoginIsPending.current = false;
  };

  useEffect(() => {
    if (isError) {
      rejectLogin();
      dispatch(setSignMessageError());
      toast.error("Failed to login", "Login has failed, try again later");
    }
  }, [isError]);

  return {
    login,
    tfaLogin,
    logout,
    initLoginIsPending: initLoginIsPending.current,
  };
};

import { NavIconProps, NavIconVariant } from ".";

export const DashboardIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.69744 12.8347H1.42472C0.922898 12.8347 0.515625 12.4274 0.515625 11.9256V1.01651C0.515625 0.514695 0.922898 0.107422 1.42472 0.107422H8.69744C9.19926 0.107422 9.60653 0.514695 9.60653 1.01651V11.9256C9.60653 12.4274 9.19926 12.8347 8.69744 12.8347Z"
            fill="#08A98A"
          />
          <path
            d="M8.69744 21.9256H1.42472C0.922898 21.9256 0.515625 21.5183 0.515625 21.0165V15.5619C0.515625 15.0601 0.922898 14.6528 1.42472 14.6528H8.69744C9.19926 14.6528 9.60653 15.0601 9.60653 15.5619V21.0165C9.60653 21.5183 9.19926 21.9256 8.69744 21.9256Z"
            fill="#08A98A"
          />
          <path
            d="M19.6076 7.38015H12.3349C11.8331 7.38015 11.4258 6.97288 11.4258 6.47106V1.01651C11.4258 0.514695 11.8331 0.107422 12.3349 0.107422H19.6076C20.1094 0.107422 20.5167 0.514695 20.5167 1.01651V6.47106C20.5167 6.97288 20.1094 7.38015 19.6076 7.38015Z"
            fill="#08A98A"
          />
          <path
            d="M19.6076 21.9255H12.3349C11.8331 21.9255 11.4258 21.5182 11.4258 21.0164V10.1073C11.4258 9.60551 11.8331 9.19824 12.3349 9.19824H19.6076C20.1094 9.19824 20.5167 9.60551 20.5167 10.1073V21.0164C20.5167 21.5182 20.1094 21.9255 19.6076 21.9255Z"
            fill="#08A98A"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.69744 12.3347H1.42472C1.19904 12.3347 1.01562 12.1513 1.01562 11.9256V1.01651C1.01562 0.790837 1.19904 0.607422 1.42472 0.607422H8.69744C8.92312 0.607422 9.10653 0.790837 9.10653 1.01651V11.9256C9.10653 12.1513 8.92312 12.3347 8.69744 12.3347Z"
            stroke="#959595"
          />
          <path
            d="M8.69744 21.4256H1.42472C1.19904 21.4256 1.01562 21.2421 1.01562 21.0165V15.5619C1.01562 15.3362 1.19904 15.1528 1.42472 15.1528H8.69744C8.92312 15.1528 9.10653 15.3362 9.10653 15.5619V21.0165C9.10653 21.2421 8.92312 21.4256 8.69744 21.4256Z"
            stroke="#959595"
          />
          <path
            d="M19.6076 6.88015H12.3349C12.1092 6.88015 11.9258 6.69673 11.9258 6.47106V1.01651C11.9258 0.790837 12.1092 0.607422 12.3349 0.607422H19.6076C19.8333 0.607422 20.0167 0.790837 20.0167 1.01651V6.47106C20.0167 6.69673 19.8333 6.88015 19.6076 6.88015Z"
            stroke="#959595"
          />
          <path
            d="M19.6076 21.4255H12.3349C12.1092 21.4255 11.9258 21.2421 11.9258 21.0164V10.1073C11.9258 9.88166 12.1092 9.69824 12.3349 9.69824H19.6076C19.8333 9.69824 20.0167 9.88166 20.0167 10.1073V21.0164C20.0167 21.2421 19.8333 21.4255 19.6076 21.4255Z"
            stroke="#959595"
          />
        </svg>
      );
  }
};

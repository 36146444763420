import { BigNumber as BG } from "bignumber.js";
import { BigNumber } from "ethers";
import { config } from "../config";

const truncateString = (value: string, numDecimalPlaces: number) => {
  const index = value.indexOf(".");
  if (index === -1) {
    return value;
  }
  return value.substring(0, index + numDecimalPlaces + 1);
};

export const formatBigNumber = (
  number: BigNumber | string | null,
  decimals = 0,
  decimalPlaces = 2
) => {
  if (number === "" || number === null) {
    return "-";
  }

  // Zwróć uwagę, aby ustawić decimals na poprawną wartość
  const divisor = new BG(10).pow(decimals);
  const formattedValue = new BG(number.toString())
    .dividedBy(divisor)
    .toFixed(decimalPlaces)
    .replace(/\.?0+$/, "");

  return formattedValue;
};

export const formatNumber = (number: string | null, decimalPlaces = 2) => {
  if (number === "" || number === null) {
    return "-";
  }

  return truncateString(number, decimalPlaces);
};

export const numberToText = (
  num: string,
  decimals: number,
  decimalPlaces = 2
) =>
  Number(formatBigNumber(num, decimals, decimalPlaces))
    .toLocaleString("en-US")
    .replaceAll(",", " ");

export const rewardsInToken = (
  rewards: { token: string; balance: string }[],
  token: "USDC" | "SOIL",
  multiplier = "1"
) => {
  const reward = rewards.find((r) => r.token === token);
  const r = reward ? reward.balance : "0";
  return numberToText(
    (Number(r) * Number(multiplier)).toString(),
    token === "USDC" ? config.USDC_TOKEN_DECIMALS : config.SOIL_TOKEN_DECIMALS,
    6
  );
};

import { ethers } from "ethers";
import { config } from "../../config";
import { AppRpcProvider } from "../../utils";
import {
  poolsContractAbi,
  soilTokenAbi,
  stakingAbi,
  uniswapPoolAbi,
  uniswapQuoterAbi,
  uniswapSwapRouterAbi,
} from "./abi";
import {
  PoolsContract as PoolsContractType,
  SoilToken as SoilTokenType,
  Staking as StakingType,
  UniswapPool as UniswapPoolType,
  UniswapQuoter as UniswapQuoterType,
  UniswapSwapRouter as UniswapSwapRouterType,
} from "./types";
import { PoolsContractV2__factory as PoolsContractFactoryV2 } from "./types/factory/PoolsContractV2__factory";
import { ProtocolSettings__factory as ProtocolSettingsFactory } from "./types/factory/ProtocolSettings__factory";

export const Staking = new ethers.Contract(
  config.STAKING_ADDRESS,
  stakingAbi,
  AppRpcProvider
) as StakingType;

export const SoilToken = new ethers.Contract(
  config.SOIL_TOKEN_ADDRESS,
  soilTokenAbi,
  AppRpcProvider
) as SoilTokenType;

export const UniswapSoilUsdcPool = new ethers.Contract(
  config.USDC_SOIL_POOL_ADDRESS,
  uniswapPoolAbi,
  AppRpcProvider
) as UniswapPoolType;

export const UniswapQuoter = new ethers.Contract(
  config.UNISWAP_QUOTER_ADDRESS,
  uniswapQuoterAbi,
  AppRpcProvider
) as UniswapQuoterType;

export const UniswapSwapRouter = new ethers.Contract(
  config.UNISWAP_SWAP_ROUTER_ADDRESS,
  uniswapSwapRouterAbi,
  AppRpcProvider
) as unknown as UniswapSwapRouterType;

export const ProtocolSettingsContract = (
  contractAddress: string,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner
) => ProtocolSettingsFactory.connect(contractAddress, provider);

export const PoolsContract = new ethers.Contract(
  config.POOLS_CONTRACT_ADDRESS_POLYGON_V1,
  poolsContractAbi,
  AppRpcProvider
) as PoolsContractType;

export const PoolsContractV2 = (
  poolsContractAddress: string,
  provider: ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner
) => PoolsContractFactoryV2.connect(poolsContractAddress, provider);

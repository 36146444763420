import { toast } from "react-toastify";
import ToastBody from "./ToastBody";

type TransactionHash = string;

type PromiseState = "pending" | "error" | "success";

type ToastMessages = {
  title: string;
  message: string;
};

type ToastOptions = Record<PromiseState, ToastMessages>;

export const handleBlockchainPromiseInToast = (
  promise: Promise<TransactionHash>,
  options: ToastOptions,
  chainId: number
) =>
  toast.promise(
    promise,
    {
      pending: {
        render() {
          return (
            <ToastBody
              title={options.pending.title}
              message={options.pending.message}
            />
          );
        },
      },
      error: {
        render() {
          return (
            <ToastBody
              title={options.error.title}
              message={options.error.message}
            />
          );
        },
      },
      success: {
        render(data) {
          return (
            <ToastBody
              title={options.success.title}
              message={options.success.message}
              chainData={{ transactionHash: data?.data, chainId }}
            />
          );
        },
      },
    },
    {
      draggablePercent: 60,
      autoClose: 5000,
      hideProgressBar: false,
    }
  );

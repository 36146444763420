import { ReactNode } from "react";
import { PopperOptions, usePopperTooltip } from "react-popper-tooltip";
import { tooltipIcon, triangleDown } from "../../assets";
import * as S from "./CustomTooltip.styled";
import "react-popper-tooltip/dist/styles.css";

export type TooltipProps = {
  message: string;
  big?: boolean;
  isForChart?: boolean;
  placement?: "top" | "bottom";
  inline?: boolean;
  children?: ReactNode;
  legendColor?: string;
  className?: string;
  messageColor?: string;
};

export const CustomTooltip = (props: TooltipProps) => {
  const {
    message,
    big,
    isForChart,
    inline,
    children,
    legendColor,
    placement = "top",
    className,
    messageColor,
  } = props;

  const popperOptions: PopperOptions = {
    placement,
  };
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({}, popperOptions);

  return (
    <S.Wrapper
      big={big}
      inline={inline}
      isForChart={isForChart}
      data-testid="wrapper"
      style={!isForChart ? { maxWidth: "100px", height: "100%" } : {}}
      className={className}
    >
      <span ref={setTriggerRef}>
        {children || <img src={tooltipIcon} alt="tooltipIcon" />}
      </span>
      {visible && (
        <S.TooltipWrapper
          isForChart={isForChart}
          ref={setTooltipRef}
          {...getTooltipProps()}
        >
          <S.ArrowMark
            {...getArrowProps()}
            src={triangleDown}
            alt="arrow down"
            placement={placement}
          />
          <S.MessageWrapper messageColor={messageColor}>
            {legendColor && <S.LegendColorBox color={legendColor} />}
            {message}
          </S.MessageWrapper>
        </S.TooltipWrapper>
      )}
    </S.Wrapper>
  );
};

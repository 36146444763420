import styled from "styled-components";
import { SmallSpinner, Typography } from "../../../../../../../../components";
import { device } from "../../../../../../../../style";

export const Wrapper = styled.div<{ isPending: boolean; isAvailable: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 16px;
  border: 1px solid
    ${({ theme, isPending }) =>
      isPending ? theme.colors.button.primary.border : theme.colors.white033};

  & > * {
    opacity: ${({ isPending, isAvailable }) =>
      isPending || !isAvailable ? 0.5 : 1};
  }
`;

export const RewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const ClaimConfirm = styled.p`
  display: inline-flex;
  gap: 8px;
  margin-bottom: 8px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.walletStatus.backgroundOn};
  color: ${({ theme }) => theme.colors.success};
  border-radius: 4px;
`;

export const FeeInfo = styled(Typography.BodySmall)`
  margin-top: 4px;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.greyMid};
`;

export const Spinner = styled(SmallSpinner)`
  position: absolute;
  opacity: 1;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
`;

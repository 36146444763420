import { Token } from "@uniswap/sdk-core";
import { FeeAmount, Pool, Route, encodeRouteToPath } from "@uniswap/v3-sdk";
import { ethers } from "ethers";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import { UniswapSoilUsdcPool } from "./contracts";
import {
  uniswapPoolAbi,
  uniswapQuoterAbi,
  uniswapSwapRouterAbi,
} from "./contracts/abi";
import { UniswapFactoryABI } from "./contracts/abi/uniswapFactory.abi";
import { UniswapPool } from "./contracts/types";
import { tokenService } from "./token.service";

interface State {
  liquidity: ethers.BigNumber;
  sqrtPriceX96: ethers.BigNumber;
  tick: number;
  observationIndex: number;
  observationCardinality: number;
  observationCardinalityNext: number;
  feeProtocol: number;
  unlocked: boolean;
}

const getPoolState = async (poolContract: UniswapPool) => {
  const poolContractWithBatchProvider = poolContract.connect(AppRpcProvider);
  const [liquidity, slot] = await Promise.all([
    poolContractWithBatchProvider.liquidity(),
    poolContractWithBatchProvider.slot0(),
  ]);

  const PoolState: State = {
    liquidity,
    sqrtPriceX96: slot[0],
    tick: slot[1],
    observationIndex: slot[2],
    observationCardinality: slot[3],
    observationCardinalityNext: slot[4],
    feeProtocol: slot[5],
    unlocked: slot[6],
  };

  return PoolState;
};

interface Immutables {
  factory: string;
  token0: string;
  token1: string;
  fee: number;
  tickSpacing: number;
  maxLiquidityPerTick: ethers.BigNumber;
}

const getPoolImmutables = async (poolContract: UniswapPool) => {
  const poolContractWithBatchProvider = poolContract.connect(AppRpcProvider);
  const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] =
    await Promise.all([
      poolContractWithBatchProvider.factory(),
      poolContractWithBatchProvider.token0(),
      poolContractWithBatchProvider.token1(),
      poolContractWithBatchProvider.fee(),
      poolContractWithBatchProvider.tickSpacing(),
      poolContractWithBatchProvider.maxLiquidityPerTick(),
    ]);

  const immutables: Immutables = {
    factory,
    token0,
    token1,
    fee,
    tickSpacing,
    maxLiquidityPerTick,
  };

  return immutables;
};

const getSoilUsdcPoolData = async (USDC_TOKEN_ADDRESS: string) => {
  const [state, immutables] = await Promise.all([
    getPoolState(UniswapSoilUsdcPool),
    getPoolImmutables(UniswapSoilUsdcPool),
  ]);

  const SoilToken = new Token(
    config.NETWORK_ID,
    config.SOIL_TOKEN_ADDRESS,
    config.SOIL_TOKEN_DECIMALS,
    "SOIL",
    "Soil"
  );
  const UsdcToken = new Token(
    config.NETWORK_ID,
    USDC_TOKEN_ADDRESS,
    config.USDC_TOKEN_DECIMALS,
    "USDC",
    "USDC token"
  );

  let TokenA: Token;
  let TokenB: Token;

  if (
    immutables.token0.toLowerCase() === config.SOIL_TOKEN_ADDRESS.toLowerCase()
  ) {
    TokenA = SoilToken;
    TokenB = UsdcToken;
  } else {
    TokenA = UsdcToken;
    TokenB = SoilToken;
  }

  const pool = new Pool(
    TokenA,
    TokenB,
    immutables.fee,
    state.sqrtPriceX96.toString(),
    state.liquidity.toString(),
    state.tick
  );

  return pool;
};

const swap = async (
  usdcTokenAdress: string,
  inputToken: Token,
  outputToken: Token,
  amountIn: ethers.BigNumber,
  amountOut: ethers.BigNumber,
  slippage: number,
  signer: ethers.providers.JsonRpcSigner
) => {
  const recipient = await signer.getAddress();
  const deadline =
    Math.floor(Date.now() / 1_000) + config.SWAP_CONFIG.SWAP_DEADLINE;

  const path =
    inputToken.address.toLowerCase() === config.SOIL_TOKEN_ADDRESS.toLowerCase()
      ? await getSoilToUsdcPath(usdcTokenAdress, config.NETWORK_ID)
      : await getUsdcToSoilPath(usdcTokenAdress, config.NETWORK_ID);

  const uniswapRouter = new ethers.Contract(
    config.UNISWAP_SWAP_ROUTER_ADDRESS,
    uniswapSwapRouterAbi,
    signer
  );

  let swapTx;

  if (
    inputToken.address.toLowerCase() === path[0].token0.address.toLowerCase()
  ) {
    const amountOutMinimum = amountOut.sub(amountOut.mul(slippage).div(10_000));
    swapTx = await uniswapRouter.exactInput(
      {
        path: encodeRouteToPath(
          new Route(path, inputToken, outputToken),
          false
        ),
        recipient,
        deadline,
        amountIn,
        amountOutMinimum,
      },
      { gasLimit: "500000" }
    );
  } else {
    const amountInMaximum = amountIn.mul(10_000 + slippage).div(10_000);
    swapTx = await uniswapRouter.exactOutput(
      {
        path: encodeRouteToPath(new Route(path, inputToken, outputToken), true),
        recipient,
        deadline,
        amountOut,
        amountInMaximum,
      }
      // { gasLimit: "500000" }
    );
  }

  await swapTx.wait(config.CONFIRMATION_BLOCKS);

  return swapTx.hash;
};

const getPoolReserves = async (
  poolAddress: string,
  token0Address: string,
  token1Address: string
) => {
  const token0Reserve = await tokenService.tokenBalance(
    token0Address,
    poolAddress
  );
  const token1Reserve = await tokenService.tokenBalance(
    token1Address,
    poolAddress
  );

  return [token0Reserve, token1Reserve] as const;
};

const getPoolAddress = async (
  token0: string,
  token1: string,
  fee: FeeAmount
) => {
  const FactoryContract = new ethers.Contract(
    "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    UniswapFactoryABI,
    AppRpcProvider
  );
  const poolAddress = FactoryContract.getPool(token0, token1, fee);
  return poolAddress;
};

const getPool = async (token0: string, token1: string, fee: FeeAmount) => {
  const FactoryContract = new ethers.Contract(
    "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    UniswapFactoryABI,
    AppRpcProvider
  );
  const poolAddress = FactoryContract.getPool(token0, token1, fee);
  const PoolContract = new ethers.Contract(
    poolAddress,
    uniswapPoolAbi,
    AppRpcProvider
  );
  const [liquidity, slot0] = await Promise.all([
    PoolContract.liquidity(),
    PoolContract.slot0(),
  ]);

  return new Pool(
    getToken(token0),
    getToken(token1),
    fee,
    slot0.sqrtPriceX96,
    liquidity,
    slot0.tick
  );
};

const getSoilToUsdcPath = async (
  USDC_TOKEN_ADDRESS: string,
  chainId: number
) => {
  let path: Pool[];
  switch (chainId) {
    case 137: {
      // Polygon Mainnet
      path = await Promise.all([
        getPool(
          config.SOIL_TOKEN_ADDRESS,
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          FeeAmount.MEDIUM
        ),
        getPool(
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
          FeeAmount.LOWEST
        ),
      ]);
      break;
    }
    case 80002: {
      // Polygon Mumbai Testnet
      path = await Promise.all([
        getPool(
          USDC_TOKEN_ADDRESS,
          config.SOIL_TOKEN_ADDRESS,
          FeeAmount.LOWEST
        ),
      ]);
      break;
    }
    default:
      throw new Error(`Wrong chain id: ${chainId}`);
  }
  return path;
};

const getUsdcToSoilPath = async (usdcTokenAdress: string, chainId: number) =>
  getSoilToUsdcPath(usdcTokenAdress, chainId).then((r) => r.reverse());

const getAmountOut = async (path: string, amountIn: ethers.BigNumber) => {
  const quoteContract = new ethers.Contract(
    "0x61fFE014bA17989E743c5F6cB21bF9697530B21e", // quoter address
    uniswapQuoterAbi,
    AppRpcProvider
  );
  const res = await quoteContract.callStatic.quoteExactInput(path, amountIn);

  return res.amountOut.toString();
};

const getToken = (address: string) => {
  switch (address.toLowerCase()) {
    case "0x43C73b90E0C2A355784dCf0Da12f477729b31e77".toLowerCase(): // Soil - prod
      return new Token(
        137,
        "0x43C73b90E0C2A355784dCf0Da12f477729b31e77",
        18,
        "SOIL",
        "Soil"
      );
    case "0xc2132D05D31c914a87C6611C10748AEb04B58e8F".toLowerCase(): // USDT Polygon Mainnet
      return new Token(
        137,
        "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        6,
        "USDT",
        "USDT token"
      );
    case "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359".toLowerCase(): // USDC Polygon Mainnet
      return new Token(
        137,
        "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
        6,
        "USDC",
        "USDC token"
      );
    case "0xa494B29D23c1272cf60CaA8233Ccf2Af5544f407".toLowerCase(): // Soil - prod
      return new Token(
        80001,
        "0xa494B29D23c1272cf60CaA8233Ccf2Af5544f407",
        18,
        "SOIL",
        "Soil"
      );
    case "0x1B1EBDc1a4400eaa563F14FA2f967eD6E82D62FA".toLowerCase(): // USDC polygon testnet
      return new Token(
        80001,
        "0x1B1EBDc1a4400eaa563F14FA2f967eD6E82D62FA",
        6,
        "USDC",
        "USDC token"
      );
    default:
      throw new Error("Unknown token address");
  }
};

const getTokensRate = async (
  amountIn: ethers.BigNumber,
  tokenIn: string,
  tokenOut: string,
  path: Pool[],
  exactOutput = true
): Promise<string> => {
  try {
    const amountOut = await getAmountOut(
      encodeRouteToPath(
        new Route(path, getToken(tokenIn), getToken(tokenOut)),
        exactOutput
      ),
      amountIn
    );

    return amountOut.toString();
  } catch (error) {
    throw new Error("Cannot get tokens rate.");
  }
};

const estimateOutputAmount = async (
  usdcTokenAddress: string,
  tokenIn: string,
  tokenOut: string,
  amountIn: ethers.BigNumber
) => {
  const path =
    tokenIn.toLowerCase() === config.SOIL_TOKEN_ADDRESS.toLowerCase()
      ? await getSoilToUsdcPath(usdcTokenAddress, config.NETWORK_ID)
      : await getUsdcToSoilPath(usdcTokenAddress, config.NETWORK_ID);
  const amountOut = await getTokensRate(
    amountIn,
    tokenIn,
    tokenOut,
    path,
    true
  );
  return amountOut;
};

const estimateInputAmount = async (
  usdcTokenAdress: string,
  tokenIn: string,
  tokenOut: string,
  amountOut: ethers.BigNumber
) => {
  const path =
    tokenIn.toLowerCase() === config.SOIL_TOKEN_ADDRESS.toLowerCase()
      ? await getSoilToUsdcPath(usdcTokenAdress, config.NETWORK_ID)
      : await getUsdcToSoilPath(usdcTokenAdress, config.NETWORK_ID);
  const amountIn = await getTokensRate(
    amountOut,
    tokenIn,
    tokenOut,
    path,
    false
  );
  return amountIn;
};

export const uniswapService = {
  getSoilUsdcPoolData,
  estimateOutputAmount,
  estimateInputAmount,
  getPoolAddress,
  swap,
  getPoolReserves,
  getSoilToUsdcPath,
  getUsdcToSoilPath,
};

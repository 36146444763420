import styled from "styled-components";
import { config } from "../../config";
import { device } from "../../style";

const TermsOfServiceContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  gap: 12px;
  z-index: 999999;

  @media ${device.laptop} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    left: 20px;
    gap: 4px;
    bottom: 40px;
  }
`;

const Link = styled.a`
  font-size: 10px;
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.body.letterSpacing};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TermsOfService = () => (
  <TermsOfServiceContainer>
    <Link href={config.TERMS_SERVICE} target="_blank" rel="noopener noreferrer">
      Terms of service
    </Link>
    <Link
      href={config.PRIVACY_POLICY}
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy policy
    </Link>
  </TermsOfServiceContainer>
);

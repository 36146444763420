import { Navigate, Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import { useAppSelector } from "../../redux/hooks";
import { isAdminRole } from "../../utils";
import { defaultHomeRoute, routes } from "../../utils/routes";

export const PrivateRoute = () => {
  const isLogged = useAppSelector((state) => state?.user?.isLogged);
  const roles = useAppSelector((state) => state?.user?.userData?.userToRoles);
  const { chainId } = useAccount();
  if (!chainId) return null;

  const isAdmin = isAdminRole(roles, chainId);
  return isLogged && !isAdmin ? (
    <Outlet />
  ) : isAdmin ? (
    <Navigate
      to={routes.adminPanel.dashboard}
      state={{ error: "You must login to proceed" }}
    />
  ) : (
    <Navigate
      to={defaultHomeRoute}
      state={{ error: "You must login to proceed" }}
    />
  );
};

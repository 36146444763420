import { Typography, Button } from "../components";
import * as S from "./ToastBody.styled";

interface ChainData {
  transactionHash?: string;
  chainId: number;
}

type ToastBodyProps = {
  title: string;
  message: string;
  chainData?: ChainData;
};

const getExplorerLinkByChain = (chainData: ChainData) => {
  switch (chainData.chainId) {
    case 1:
      return `https://etherscan.io/tx/${chainData.transactionHash}`;
    case 137:
      return `https://polygonscan.com/tx/${chainData.transactionHash}`;
    case 80002:
      return `https://amoy.polygonscan.com/tx/${chainData.transactionHash}`;
    case 11155111:
      return `https://sepolia.etherscan.io/tx/${chainData.transactionHash}`;
    default:
      return "";
  }
};

const ToastBody = ({ title, message, chainData }: ToastBodyProps) => (
  <S.ToastBodyWrapper>
    <Typography.ToastTitle fontWeight={400}>{title}</Typography.ToastTitle>
    <Typography.ToastMessage isWhite fontWeight={400} opacity={0.9}>
      {message}
    </Typography.ToastMessage>
    {chainData?.transactionHash && (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a
          href={getExplorerLinkByChain(chainData)}
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          <Button.Primary
            type="button"
            style={{ width: "150px", marginTop: "10px" }}
          >
            check details
          </Button.Primary>
        </a>
      </div>
    )}
  </S.ToastBodyWrapper>
);

export default ToastBody;

import { motion } from "framer-motion";
import styled from "styled-components";
import { Button } from "../Button/Button.styled";
import { device } from "../../style";

export const KycModalWrapper = styled(motion.div)<{ isFullHeight?: boolean }>`
  display: flex;
  flex: ${({ isFullHeight }) => (isFullHeight ? "1" : "unset")};
  height: ${({ isFullHeight }) => (isFullHeight ? "80%" : "unset")};
  position: ${({ isFullHeight }) => (isFullHeight ? "absolute" : "unset")};
  left: 0;
  min-width: 100%;
  top: 0;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;

  @media ${device.laptopM} {
    height: ${({ isFullHeight }) => (isFullHeight ? "85%" : "unset")};
  }
`;

export const InitKycButton = styled(Button.Primary)`
  margin: 40px 0;
  height: 56px;
  border-radius: 16px;
`;

export const PoweredText = styled.p`
  font-family: Ingra;
  font-size: 7px;
  font-weight: 300;
  line-height: 9.52px;
  letter-spacing: 0.15em;
  text-align: left;
  text-transform: uppercase;
`;

export const FractalLogo = styled.img`
  width: 156.73px;
  height: 42.81px;
  top: 642.15px;
  left: 861.45px;
  gap: 0px;
  opacity: 0px;
`;

export const KycModalContent = styled.p`
  font-family: Ingra;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.04px;
  text-align: left;
  color: #000;

  span {
    color: #08a98a;
    font-weight: 500;
  }
`;

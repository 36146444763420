import { Navigate, Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import { useAppSelector } from "../../redux/hooks";
import { isAdminRole } from "../../utils";
import { defaultHomeRoute } from "../../utils/routes";

export const AdminRoute = () => {
  const roles = useAppSelector((state) => state?.user?.userData?.userToRoles);
  const { chainId } = useAccount();
  if (!chainId) return null;
  const isAdmin = isAdminRole(roles, chainId);

  return isAdmin ? (
    <Outlet />
  ) : (
    <Navigate
      to={defaultHomeRoute}
      state={{ error: "You must be an admin to access this page" }}
    />
  );
};

import { ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAccount } from "wagmi";
import {
  CurrencyValue,
  InfoBox,
  Modal,
  PenIcon,
  Typography,
} from "../../components";
import { config } from "../../config";
import { useModal, useUsdcTokenAddress } from "../../hooks";
import { usePoolsV2ContractAddress } from "../../hooks/usePoolsV2ContractAddress";
import { poolsContractService } from "../../services";
import { device } from "../../style";
import { toast } from "../../toasts";
import RpcProviderManager from "../../utils/jsonRpcProvider";
import { EditSoilLimitForm } from "./components/EditSoilLimitForm";
import { EditUsdcLimitForm } from "./components/EditUsdcLimitForm";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 24px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

type isPending = {
  soilWithdrawLimit: boolean;
  usdcWithdrawLimit: boolean;
  soilWithdrawLimitV2: boolean;
  usdcWithdrawLimitV2: boolean;
};

export const PoolWithdrawLimits = () => {
  const [soilWithdrawLimit, setSoilWithdrawLimit] = useState<string>();
  const [usdcWithdrawLimit, setUsdcWithdrawLimit] = useState<string>();
  const [soilWithdrawLimitV2, setSoilWithdrawLimitV2] = useState<string>();
  const [usdcWithdrawLimitV2, setUsdcWithdrawLimitV2] = useState<string>();
  const [isPending, setIsPending] = useState<isPending>({
    soilWithdrawLimit: false,
    usdcWithdrawLimit: false,
    soilWithdrawLimitV2: false,
    usdcWithdrawLimitV2: false,
  });
  const usdcTokenAddress = useUsdcTokenAddress();
  const { chainId } = useAccount();
  const provider = RpcProviderManager.getInstance().getProvider(chainId);
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const isBaseNetwork = chainId === config.NETWORK_ID;

  const handleGetSoilLimit = async () => {
    setIsPending((prevState) => ({
      ...prevState,
      soilWithdrawLimit: true,
    }));
    try {
      const soilLimit = await poolsContractService.limitOfSoilRewards();
      setSoilWithdrawLimit(
        ethers.utils.formatUnits(soilLimit, config.SOIL_TOKEN_DECIMALS)
      );
      setIsPending((prevState) => ({
        ...prevState,
        soilWithdrawLimit: false,
      }));
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch soil rewards limit");
      setIsPending((prevState) => ({
        ...prevState,
        soilWithdrawLimit: false,
      }));
    }
  };

  const handleGetSoilLimitV2 = async () => {
    setIsPending((prevState) => ({
      ...prevState,
      soilWithdrawLimitV2: true,
    }));
    try {
      const soilLimit = await poolsContractService.limitOfSoilRewardsV2(
        poolsV2ContractAddress,
        provider
      );
      setSoilWithdrawLimitV2(
        ethers.utils.formatUnits(soilLimit, config.SOIL_TOKEN_DECIMALS)
      );
      setIsPending((prevState) => ({
        ...prevState,
        soilWithdrawLimitV2: false,
      }));
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch soil rewards limit");
      setIsPending((prevState) => ({
        ...prevState,
        soilWithdrawLimitV2: false,
      }));
    }
  };

  const handleGetUsdcLimit = async () => {
    setIsPending((prevState) => ({
      ...prevState,
      usdcWithdrawLimit: true,
    }));
    try {
      const usdcLimit = await poolsContractService.limitOfUsdcRewards();
      setUsdcWithdrawLimit(
        ethers.utils.formatUnits(usdcLimit, config.USDC_TOKEN_DECIMALS)
      );
      setIsPending((prevState) => ({
        ...prevState,
        usdcWithdrawLimit: false,
      }));
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch usdc rewards limit");
      setIsPending((prevState) => ({
        ...prevState,
        usdcWithdrawLimit: false,
      }));
    }
  };

  const handleGetUsdcLimitV2 = async () => {
    setIsPending((prevState) => ({
      ...prevState,
      usdcWithdrawLimitV2: true,
    }));
    try {
      const usdcLimit = await poolsContractService.limitOfUsdcRewardsV2(
        poolsV2ContractAddress,
        provider,
        usdcTokenAddress
      );
      setUsdcWithdrawLimitV2(
        ethers.utils.formatUnits(usdcLimit, config.USDC_TOKEN_DECIMALS)
      );
      setIsPending((prevState) => ({
        ...prevState,
        usdcWithdrawLimitV2: false,
      }));
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch usdc rewards limit");
      setIsPending((prevState) => ({
        ...prevState,
        usdcWithdrawLimitV2: false,
      }));
    }
  };

  useEffect(() => {
    handleGetSoilLimit();
    handleGetUsdcLimit();
    handleGetSoilLimitV2();
    handleGetUsdcLimitV2();
  }, [chainId]);

  const {
    isModalOpen: isUsdcLimitModalOpen,
    handleModalClose: usdcModalClose,
    handleModalOpen: usdcModalOpen,
  } = useModal();
  const {
    isModalOpen: isSoilLimitModalOpen,
    handleModalClose: soilModalClose,
    handleModalOpen: soilModalOpen,
  } = useModal();
  const {
    isModalOpen: isUsdcLimitModalOpenV2,
    handleModalClose: usdcModalCloseV2,
    handleModalOpen: usdcModalOpenV2,
  } = useModal();
  const {
    isModalOpen: isSoilLimitModalOpenV2,
    handleModalClose: soilModalCloseV2,
    handleModalOpen: soilModalOpenV2,
  } = useModal();

  return (
    <>
      <Modal
        title={["Edit SOIL", "rewards withdraw limit (V1)"]}
        isOpen={isSoilLimitModalOpen}
        onClose={soilModalClose}
        withOutsideClick
      >
        <EditSoilLimitForm
          closeModal={soilModalClose}
          value={soilWithdrawLimit || "0"}
          update={handleGetSoilLimit}
        />
      </Modal>
      <Modal
        title={["Edit USDC", "rewards withdraw limit (V1)"]}
        isOpen={isUsdcLimitModalOpen}
        onClose={usdcModalClose}
        withOutsideClick
      >
        <EditUsdcLimitForm
          closeModal={usdcModalClose}
          value={usdcWithdrawLimit || "0"}
          update={handleGetUsdcLimit}
        />
      </Modal>
      <Modal
        title={["Edit USDC", "rewards withdraw limit (V2)"]}
        isOpen={isUsdcLimitModalOpenV2}
        onClose={usdcModalCloseV2}
        withOutsideClick
      >
        <EditUsdcLimitForm
          closeModal={usdcModalCloseV2}
          value={usdcWithdrawLimitV2 || "0"}
          update={handleGetUsdcLimitV2}
          isV2
        />
      </Modal>
      <Modal
        title={["Edit SOIL", "rewards withdraw limit (V2)"]}
        isOpen={isSoilLimitModalOpenV2}
        onClose={soilModalCloseV2}
        withOutsideClick
      >
        <EditSoilLimitForm
          closeModal={soilModalCloseV2}
          value={soilWithdrawLimitV2 || "0"}
          update={handleGetSoilLimitV2}
          isV2
        />
      </Modal>
      <MainWrapper>
        {isBaseNetwork && (
          <ItemsWrapper>
            <Typography.Header>Pool V1 limits</Typography.Header>
            <BoxesWrapper>
              <InfoBox.Admin title="SOIL rewards withdraw limit:">
                <Row>
                  <CurrencyValue
                    value={
                      isPending.soilWithdrawLimit
                        ? null
                        : soilWithdrawLimit || null
                    }
                    currency="$SOIL"
                    decimals={2}
                    isBig
                  />
                  <PenIcon noWrap click={soilModalOpen} />
                </Row>
              </InfoBox.Admin>
              <InfoBox.Admin title="USDC rewards withdraw limit:">
                <Row>
                  <CurrencyValue
                    value={
                      isPending.usdcWithdrawLimit
                        ? null
                        : usdcWithdrawLimit || null
                    }
                    currency="USDC"
                    decimals={2}
                    isBig
                  />
                  <PenIcon noWrap click={usdcModalOpen} />
                </Row>
              </InfoBox.Admin>
            </BoxesWrapper>
          </ItemsWrapper>
        )}
        <ItemsWrapper>
          <Typography.Header>Pool V2 limits</Typography.Header>
          <BoxesWrapper>
            {isBaseNetwork && (
              <InfoBox.Admin title="SOIL rewards withdraw limit:">
                <Row>
                  <CurrencyValue
                    value={
                      isPending.soilWithdrawLimitV2
                        ? null
                        : soilWithdrawLimitV2 || null
                    }
                    currency="$SOIL"
                    decimals={2}
                    isBig
                  />
                  <PenIcon noWrap click={soilModalOpenV2} />
                </Row>
              </InfoBox.Admin>
            )}
            <InfoBox.Admin title="USDC rewards withdraw limit:">
              <Row>
                <CurrencyValue
                  value={
                    isPending.usdcWithdrawLimitV2
                      ? null
                      : usdcWithdrawLimitV2 || null
                  }
                  currency="USDC"
                  decimals={2}
                  isBig
                />
                <PenIcon noWrap click={usdcModalOpenV2} />
              </Row>
            </InfoBox.Admin>
          </BoxesWrapper>
        </ItemsWrapper>
      </MainWrapper>
    </>
  );
};

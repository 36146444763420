import { NavIconProps, NavIconVariant } from ".";

export const FixedYieldPoolIcon: React.FC<NavIconProps> = ({ variant }) => {
  switch (variant) {
    case NavIconVariant.ACTIVE:
      return (
        <svg
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0976562 7.09953C0.0976562 3.5557 2.9705 0.682861 6.51432 0.682861C10.0581 0.682861 12.931 3.5557 12.931 7.09953C12.931 10.6433 10.0581 13.5162 6.51432 13.5162C2.9705 13.5162 0.0976562 10.6433 0.0976562 7.09953ZM2.72266 4.76619C2.72266 3.96078 3.37558 3.30786 4.18099 3.30786C4.9864 3.30786 5.63932 3.96078 5.63932 4.76619C5.63932 5.57161 4.9864 6.22453 4.18099 6.22453C3.37558 6.22453 2.72266 5.57161 2.72266 4.76619ZM8.84766 7.97453C8.04225 7.97453 7.38932 8.62745 7.38932 9.43286C7.38932 10.2383 8.04225 10.8912 8.84766 10.8912C9.65306 10.8912 10.306 10.2383 10.306 9.43286C10.306 8.62745 9.65306 7.97453 8.84766 7.97453ZM9.5518 4.88701L9.96427 4.47453L9.13932 3.64957L8.72685 4.06205L6.10185 6.68705L3.47684 9.31205L3.06437 9.72453L3.88932 10.5495L4.3018 10.137L6.9268 7.512L9.5518 4.88701Z"
            fill="#08A98A"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0976562 7.09953C0.0976562 3.5557 2.9705 0.682861 6.51432 0.682861C10.0581 0.682861 12.931 3.5557 12.931 7.09953C12.931 10.6433 10.0581 13.5162 6.51432 13.5162C2.9705 13.5162 0.0976562 10.6433 0.0976562 7.09953ZM2.72266 4.76619C2.72266 3.96078 3.37558 3.30786 4.18099 3.30786C4.9864 3.30786 5.63932 3.96078 5.63932 4.76619C5.63932 5.57161 4.9864 6.22453 4.18099 6.22453C3.37558 6.22453 2.72266 5.57161 2.72266 4.76619ZM8.84766 7.97453C8.04225 7.97453 7.38932 8.62745 7.38932 9.43286C7.38932 10.2383 8.04225 10.8912 8.84766 10.8912C9.65306 10.8912 10.306 10.2383 10.306 9.43286C10.306 8.62745 9.65306 7.97453 8.84766 7.97453ZM9.5518 4.88701L9.96427 4.47453L9.13932 3.64957L8.72685 4.06205L6.10185 6.68705L3.47684 9.31205L3.06437 9.72453L3.88932 10.5495L4.3018 10.137L6.9268 7.512L9.5518 4.88701Z"
            fill="#959595"
          />
        </svg>
      );
  }
};
